import i18next from 'i18next';
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Heading,
	Switch,
	Text,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiSave } from 'react-icons/fi';
import { useState } from 'react';

import FilterList from '../../core/FilterList';
import Reaction from '../../reaction/Reaction';
import useErrorToast from '../../../hooks/useErrorToast';
import { DetailedObserviumMatch, SimplePattern, SimpleTarget } from '../../../api/client';
import { FilterSpec } from '../../core/Filter';

type ObserviumMatchProps = {
	match: DetailedObserviumMatch;
	save: () => void;
	openReactionEditionModal: (match: DetailedObserviumMatch) => void;
	targets: SimpleTarget[];
	patterns: SimplePattern[];
	refetch: () => void;
	removeMatch: (id: number) => void;
};

function ObserviumMatch({
	match,
	targets,
	patterns,
	refetch,
	openReactionEditionModal,
	removeMatch,
	save,
}: ObserviumMatchProps): JSX.Element {
	const [dirty, setDirty] = useState(false);
	const [filters, setFilters] = useState(match.filters);
	const toastError = useErrorToast();

	const addFilter = (filter: FilterSpec) => {
		setFilters([...filters, filter]);
		setDirty(true);
	};

	const removeFilter = (index: number) => {
		setFilters(filters.filter((_, i) => i !== index));
		setDirty(true);
	};

	const saveAndMarkClean = async (...args: unknown[]) => {
		if (!match.triggerOnFire && !match.triggerOnReminder && !match.triggerOnRecover) {
			toastError('Configuration incomplete', 'You must select at least one trigger.');
			return;
		}

		// @ts-expect-error
		await save(...args);
		setDirty(false);
	};

	const setTriggerOnFire = (event: React.ChangeEvent<HTMLInputElement>) => {
		match.triggerOnFire = event.target.checked;
		setDirty(true);
	};

	const setTriggerOnReminder = (event: React.ChangeEvent<HTMLInputElement>) => {
		match.triggerOnReminder = event.target.checked;
		setDirty(true);
	};

	const setTriggerOnRecover = (event: React.ChangeEvent<HTMLInputElement>) => {
		match.triggerOnRecover = event.target.checked;
		setDirty(true);
	};

	return (
		<AccordionItem key={match.id}>
			<h2>
				<AccordionButton>
					{match.name}
					<AccordionIcon />
				</AccordionButton>
			</h2>

			<AccordionPanel>
				<Reaction reaction={match.reaction} targets={targets} patterns={patterns} refetch={refetch} />

				<Box mt='6' />

				<Heading size='sm' mb='2'>
					Alert State
				</Heading>
				<Flex>
					<Flex alignItems='center'>
						<Switch id='triggerOnFire' defaultChecked={match.triggerOnFire} onChange={setTriggerOnFire} />
						<Text ml='2'>{i18next.t('OBSERVIUM_TRIGGER_ON_FIRE')}</Text>
					</Flex>
					<Flex alignItems='center' mx='8'>
						<Switch id='triggerOnReminder' defaultChecked={match.triggerOnReminder} onChange={setTriggerOnReminder} />
						<Text ml='2'>{i18next.t('OBSERVIUM_TRIGGER_ON_REMINDER')}</Text>
					</Flex>
					<Flex alignItems='center'>
						<Switch id='triggerOnRecover' defaultChecked={match.triggerOnRecover} onChange={setTriggerOnRecover} />
						<Text ml='2'>{i18next.t('OBSERVIUM_TRIGGER_ON_RECOVER')}</Text>
					</Flex>
				</Flex>
				<Box mt='6' />

				<Heading size='sm' mb='2'>
					{i18next.t('FILTER_LIST_TITLE')}
				</Heading>
				<FilterList
					filters={filters}
					addFilter={addFilter}
					removeFilter={removeFilter}
					keyOptions={observiumFilterKeyOptions}
				/>

				<Flex mt='2'>
					<Button
						isDisabled={!dirty}
						colorScheme='blue'
						leftIcon={<FiSave />}
						mt='4'
						mr='2'
						onClick={() =>
							saveAndMarkClean(
								match.id,
								match.name,
								match.triggerOnFire,
								match.triggerOnReminder,
								match.triggerOnRecover,
								filters,
							)
						}
					>
						{dirty ? 'Save' : 'Saved'}
					</Button>
					<Button
						variant='outline'
						colorScheme='blue'
						leftIcon={<FiEdit3 />}
						mt='4'
						mr='2'
						onClick={() => openReactionEditionModal(match)}
					>
						{i18next.t('EDIT')}
					</Button>
					<Button
						variant='outline'
						colorScheme='red'
						leftIcon={<FiDelete />}
						mt='4'
						onClick={() => removeMatch(match.id)}
					>
						{i18next.t('DELETE')}
					</Button>
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
}

const observiumFilterKeyOptions = [
	'ALERT_MESSAGE',
	'CONDITIONS',
	'METRICS',
	'SYSLOG_RULE',
	'SYSLOG_MESSAGE',
	'SYSLOG_PROGRAM',
	'ENTITY_NAME',
	'ENTITY_ID',
	'ENTITY_TYPE',
	'ENTITY_DESCRIPTION',
	'DEVICE_HOSTNAME',
	'DEVICE_ID',
	'DEVICE_HARDWARE',
	'DEVICE_OS',
	'DEVICE_LOCATION',
	'TITLE',
];

export default ObserviumMatch;
