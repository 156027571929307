import i18next from 'i18next';
import { Button, Flex, Input, Text } from '@chakra-ui/react';
import { FiDelete, FiPlus, FiSave } from 'react-icons/fi';
import { useState } from 'react';

type KeyValue = {
	label: string;
	value: string;
};

type KeyValueInputProps = {
	defaultValue: KeyValue[];
	handleSave: (conditions: KeyValue[]) => Promise<unknown>;
};

function KeyValueInput({ defaultValue, handleSave }: KeyValueInputProps): JSX.Element {
	const [conditions, setConditions] = useState<KeyValue[]>(defaultValue);
	const [dirty, setDirty] = useState(false);
	const [saving, setSaving] = useState(false);

	const addCondition = () => {
		setConditions([
			...conditions,
			{
				label: '',
				value: '',
			},
		]);
		setDirty(true);
	};

	const removeCondition = (index: number) => {
		conditions.splice(index, 1);
		setConditions([...conditions]);
		setDirty(true);
	};

	const handleInputChange = (index: number, isValue: boolean, value: string) => {
		if (isValue) {
			conditions[index].value = value;
		} else {
			conditions[index].label = value;
		}

		setConditions([...conditions]);
		setDirty(true);
	};

	const save = async () => {
		setSaving(true);
		await handleSave(conditions);
		setSaving(false);
		setDirty(false);
	};

	return (
		<>
			{conditions.map((condition, index) => (
				<Flex alignItems='center' mb='2' key={index}>
					<Input
						placeholder={i18next.t('KVINPUT_LABEL')}
						value={condition.label}
						onChange={(event) => handleInputChange(index, false, event.target.value)}
						_placeholder={{
							color: 'whiteAlpha.600',
						}}
					/>
					<Text mx='2'>=</Text>
					<Input
						placeholder={i18next.t('KVINPUT_VALUE')}
						value={condition.value}
						onChange={(event) => handleInputChange(index, true, event.target.value)}
						_placeholder={{
							color: 'whiteAlpha.600',
						}}
					/>
					<Flex>
						<Button colorScheme='red' ml='2' onClick={() => removeCondition(index)}>
							<FiDelete />
						</Button>
					</Flex>
				</Flex>
			))}

			<Button leftIcon={<FiPlus />} onClick={addCondition}>
				{i18next.t('KVINPUT_ADD_CONDITION')}
			</Button>
			{dirty && (
				<Button colorScheme='blue' leftIcon={<FiSave />} ml='2' onClick={save} isLoading={saving}>
					Save
				</Button>
			)}
		</>
	);
}

export default KeyValueInput;
