export type Color = {
	r: number;
	g: number;
	b: number;
};

function compareColors(lhs: Color, rhs: Color): boolean {
	return lhs.r === rhs.r && lhs.g === rhs.g && lhs.b === rhs.b;
}

function multiplyColor(color: Color, factor: number): Color {
	return {
		r: color.r * factor,
		g: color.g * factor,
		b: color.b * factor,
	};
}

function convertColorToCSS(color: Color): string {
	return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

function checkColorIsTransparent(color: Color): boolean {
	return color.r === 0 && color.g === 0 && color.b === 0;
}

export { compareColors, multiplyColor, convertColorToCSS, checkColorIsTransparent };
