import i18next from 'i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from '@chakra-ui/react';

export type PagerDutySpec = {
	name: string;
	signingSecret: string;
};

function PagerDutyForm(): JSX.Element {
	return (
		<>
			<Field name='name'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.name && form.touched.name)}>
						<FormLabel htmlFor='name'>{i18next.t('NAME')}</FormLabel>
						<Input id='name' placeholder={i18next.t('PAGERDUTY_NAME_PLACEHOLDER')} autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name='signingSecret'>
				{({ field, form }: FieldProps) => (
					<FormControl isInvalid={Boolean(form.errors.signingSecret && form.touched.signingSecret)} mt='3'>
						<FormLabel htmlFor='signingSecret'>{i18next.t('PAGERDUTY_SIGNING_SECRET')}</FormLabel>
						<Input
							id='signingSecret'
							placeholder={i18next.t('PAGERDUTY_SIGNING_SECRET_PLACEHOLDER')}
							autoComplete='none'
							{...field}
						/>
						<FormErrorMessage>{form.errors.signingSecret as string}</FormErrorMessage>
						<FormHelperText>
							Signing Secret is shown after creating the Webhook on PagerDuty. You can leave this field empty and add it
							later.
						</FormHelperText>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default PagerDutyForm;
