import { useToast } from '@chakra-ui/react';

import { errorToastProps } from '../api/error';

type ErrorToast = (title: string, error: unknown) => void;

function useErrorToast(): ErrorToast {
	const toast = useToast();
	return (title, error) =>
		toast({
			title,
			description: (error as object).toString(),
			...errorToastProps,
		});
}

export default useErrorToast;
