import { Code, Heading } from '@chakra-ui/react';

import { ApiError } from '../../api/error';

type ErrorProps = {
	error?: unknown;
	fullscreen?: boolean;
};

function Error({ error, fullscreen }: ErrorProps): JSX.Element | null {
	if (error instanceof ApiError && error.containsCode('not_authenticated')) {
		return null;
	}

	return (
		<>
			{fullscreen && (
				<Heading size='lg' mb='4'>
					Something went wrong
				</Heading>
			)}
			<Code bg='whiteAlpha.200' overflow='scroll' w='100%' p='2' borderRadius='lg'>
				{/* @ts-expect-error */}
				{'message' in error ? `Error: ${error.message}` : ''}
				<br />
				Details: <pre>{JSON.stringify(error, null, 2)}</pre>
			</Code>
		</>
	);
}

export default Error;
