import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Code,
	Flex,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tag,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiGithub } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

import Error from '../components/core/Error';
import GithubInstructions from '../components/integrations/github/GithubInstructions';
import GithubReactionForm from '../components/integrations/github/GithubReactionForm';
import Loading from '../components/core/Loading';
import Reaction from '../components/reaction/Reaction';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import GithubForm, { GithubSpec } from '../components/integrations/github/GithubForm';
import { DetailedGithub, DetailedReaction } from '../api/client';
import { getIngestURL } from '../utility/host';

type GithubReactionSpec = {
	patternId: number;
	minimumInterval: number;
};

function Github(): JSX.Element {
	const navigate = useNavigate();
	const { organization } = useSession();
	const editionModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const reactionEditionModal = useDisclosure();
	const [editingReaction, setEditingReaction] = useState<DetailedReaction | null>(null);
	const [editingReactionKind, setEditingReactionKind] = useState('');
	const { id } = useParams();
	const {
		queries: [github, targets, patterns],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrieveGithub, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
		useQuery(api.listPatterns, organization.id),
	);

	const editGithub = useCallbackWithLoading(async ({ name }: GithubSpec) => {
		try {
			await api.patchGithub(Number(id), organization.id, { name });
		} finally {
			refetch();
			editionModal.onClose();
		}
	});

	const deleteGithub = useCallbackWithLoading(async () => {
		try {
			await api.destroyGithub(Number(id), organization.id);
		} finally {
			deleteModal.onClose();
			navigate('/integrations');
		}
	});

	const enableEvent = useCallbackWithLoading(async ({ patternId, minimumInterval }: GithubReactionSpec) => {
		try {
			const reaction = await api.createReaction(organization.id, {
				patternId,
				// @ts-ignore
				minimumInterval,
			});

			await api.patchGithub(Number(id), organization.id, {
				[`${editingReactionKind}Reaction`]: { id: reaction.id },
			});
		} finally {
			refetch();
			setEditingReactionKind('');
			reactionEditionModal.onClose();
		}
	});

	const editEvent = useCallbackWithLoading(async ({ minimumInterval }: GithubReactionSpec) => {
		if (!editingReaction) {
			return;
		}

		try {
			await api.patchReaction(editingReaction.id, organization.id, {
				// @ts-ignore
				minimumInterval,
			});
		} finally {
			refetch();
			setEditingReactionKind('');
			setEditingReaction(null);
			reactionEditionModal.onClose();
		}
	});

	const disableEvent = useCallbackWithLoading(async (kind: string) => {
		const reactionKey = `${kind}Reaction`;

		try {
			// @ts-expect-error
			await api.destroyReaction(github.data![reactionKey].id, organization.id);
		} finally {
			refetch();
		}
	});

	const editReaction = (kind: string, reaction: DetailedReaction | null) => {
		setEditingReactionKind(kind);
		setEditingReaction(reaction);
		reactionEditionModal.onOpen();
	};

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <Error fullscreen error={error} />;
	}

	const { name, token } = github.data!;
	// @ts-expect-error
	const reactions = getAllGithubReactions(github.data!);
	const url = `${getIngestURL()}/github/${id}`;
	const editionInProgress = editGithub.loading || enableEvent.loading || editEvent.loading || disableEvent.loading;

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='2'>
				<Flex alignItems='center'>
					<Heading size='lg'>
						<Icon as={FiGithub} mr='2' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<div>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={editionModal.onOpen}>
						Rename
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						Delete
					</Button>
				</div>
			</Flex>

			<Heading size='md' my='4'>
				Events
			</Heading>

			<Accordion allowMultiple defaultIndex={[]}>
				{Object.entries(reactions).map(([kind, reaction]) => (
					<AccordionItem>
						<h2>
							<AccordionButton>
								<Flex textAlign='left' alignItems='center' justifyContent='center'>
									Event: <Code ml='1'>{kind}</Code>
									{reaction && (
										<Tag bg='green.500' ml='2' size='sm'>
											Active
										</Tag>
									)}
								</Flex>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>
							{reaction ? (
								<>
									<Reaction
										reaction={reaction}
										targets={targets.data!.results!}
										patterns={patterns.data!.results!}
										refetch={refetch}
									/>
									<Flex>
										<Button colorScheme='blue' mt='4' onClick={() => editReaction(kind, reaction)}>
											<Icon as={FiEdit3} mr='1' />
											Edit Event
										</Button>
										<Button colorScheme='red' mt='4' ml='2' onClick={() => disableEvent(kind)}>
											<Icon as={FiDelete} mr='1' />
											Disable Event
										</Button>
									</Flex>
								</>
							) : (
								<Flex alignItems='center' justifyContent='center'>
									<Text color='gray'>Event disabled.</Text>
									<Button colorScheme='blue' ml='4' onClick={() => editReaction(kind, null)}>
										Enable
									</Button>
								</Flex>
							)}
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>

			<Heading size='md' mt='6' mb='4'>
				Configuration
			</Heading>

			<Accordion allowMultiple defaultIndex={[]} mt='4'>
				<AccordionItem>
					<h2>
						<AccordionButton>
							How to setup Github integration
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel>
						<GithubInstructions url={url} token={token} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Modal isOpen={editionModal.isOpen} onClose={editionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Github integration</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editGithub}
						initialValues={{
							name,
							minimumInterval: 0,
						}}
					>
						<Form>
							<ModalBody>
								<GithubForm />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={editionModal.onClose}>
									Cancel
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editionInProgress}>
									Update
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Github integration
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? The integration will stop working and this action cannot be undone.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								Cancel
							</Button>
							<Button colorScheme='red' ml={3} onClick={deleteGithub} isLoading={deleteGithub.loading}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Modal isOpen={reactionEditionModal.isOpen} onClose={reactionEditionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{editingReaction ? 'Edit' : 'Create'} Github Reaction</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editingReaction ? editEvent : enableEvent}
						// @ts-expect-error
						initialValues={
							editingReaction || {
								patternId: 0,
								minimumInterval: 60,
							}
						}
					>
						<Form>
							<ModalBody>
								<GithubReactionForm patterns={editingReaction ? undefined : patterns.data!.results!} />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={reactionEditionModal.onClose}>
									Cancel
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editionInProgress}>
									{editingReaction ? 'Update' : 'Create'}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>
		</>
	);
}

function getAllGithubReactions(github?: DetailedGithub): { [kind: string]: DetailedReaction | undefined } {
	return {
		push: github?.pushReaction,
		tagPush: github?.tagPushReaction,
		issueOpened: github?.issueOpenedReaction,
		issueCommented: github?.issueCommentedReaction,
		pullRequestOpened: github?.pullRequestOpenedReaction,
		pullRequestCommented: github?.pullRequestCommentedReaction,
		checkSuiteCompleted: github?.checkSuiteCompletedReaction,
		checkSuiteFailed: github?.checkSuiteFailedReaction,
	};
}

export default Github;
