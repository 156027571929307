import i18next from 'i18next';
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { FiBookmark, FiEdit2, FiPlus } from 'react-icons/fi';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useEffect } from 'react';

import Empty from '../components/core/Empty';
import Loading from '../components/core/Loading';
import OrganizationCreateModal from '../components/organization/OrganizationCreateModal';
import Prompt from '../components/layout/Prompt';
import UserList from '../components/organization/UserList';
import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import { writeLocalStorage } from '../utility/storage';

function Organization(): JSX.Element {
	const creationModal = useDisclosure();
	const invitationModal = useDisclosure();
	const renameModal = useDisclosure();
	const { organization, user } = useSession();
	const { data, loading, error, refetch } = useQuery(api.retrieveOrganization, organization.id);
	const toast = useToast();

	// @ts-expect-error
	const orgUser = data?.users?.find((u) => u.id === user?.id);

	const inviteMember = useCallbackWithLoading(async ({ email }: { email: string }) => {
		await api.inviteOrganization(organization.id, {
			// @ts-expect-error
			body: { email },
			headers: {
				'Accept-Language': i18next.language,
			},
		});

		invitationModal.onClose();
		toast({
			title: i18next.t('ORGANIZATION_INVITE_SENT_TITLE'),
			description: i18next.t('ORGANIZATION_INVITE_SENT_TIP'),
			duration: 5000,
			isClosable: true,
			status: 'success',
			position: 'top',
		});
	});

	const fireMember = useCallbackWithLoading(async (email: string) => {
		await api.fireOrganization(organization.id, {
			// @ts-expect-error
			body: { email },
		});

		if (email === user?.email) {
			localStorage.removeItem('organization-id');
			localStorage.removeItem('organization-name');
			window.location.replace('/');
		} else {
			refetch();
		}
	});

	const promoteMember = useCallbackWithLoading(async (email: string) => {
		await api.promoteOrganization(organization.id, {
			// @ts-expect-error
			body: { email },
		});

		refetch();
	});

	const downgradeMember = useCallbackWithLoading(async (email: string) => {
		await api.downgradeOrganization(organization.id, {
			// @ts-expect-error
			body: { email },
		});

		refetch();
	});

	const rename = useCallbackWithLoading(async (name: string) => {
		try {
			await api.patchOrganization(organization.id, { name });
			writeLocalStorage('organization-name', name);
		} finally {
			refetch();
			renameModal.onClose();
		}
	});

	useEffect(() => {
		if (error) {
			error.ignore('not_found');
		}
	}, [error]);

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			{data && (
				<>
					<Flex mb='4' justifyContent='space-between'>
						<Flex alignItems='baseline'>
							<Heading mr='2'>{data.name}</Heading>
							<Text color='gray.500'>
								{data.users.length} {i18next.t('ORGANIZATION_MEMBERS')}
							</Text>
						</Flex>
						<Flex>
							{orgUser?.isAdmin && (
								<>
									<Button variant='outline' colorScheme='blue' leftIcon={<FiEdit2 />} onClick={renameModal.onOpen}>
										{i18next.t('RENAME')}
									</Button>
									<Button colorScheme='blue' leftIcon={<FiPlus />} ml='2' onClick={invitationModal.onOpen}>
										{i18next.t('ORGANIZATION_INVITE_MEMBER')}
									</Button>
								</>
							)}
							{/* <Button colorScheme='red' leftIcon={<FiTrash2 />} ml='2' onClick={creationModal.onOpen}>
								Destroy organization
							</Button> */}
						</Flex>
					</Flex>

					<UserList
						// @ts-expect-error
						users={data.users}
						spacing='2'
						onRemove={fireMember}
						onPromote={promoteMember}
						onDowngrade={downgradeMember}
						loading={fireMember.loading || promoteMember.loading || downgradeMember.loading}
					/>
				</>
			)}

			{error && (
				<Empty
					title={i18next.t('ORGANIZATION_LIST_EMPTY_TITLE')}
					action={i18next.t('ORGANIZATION_CREATE')}
					// @ts-expect-error
					icon={FiBookmark}
					onCreate={creationModal.onOpen}
				>
					{i18next.t('ORGANIZATION_EMPTY_TIP')}
				</Empty>
			)}

			<Modal isOpen={invitationModal.isOpen} onClose={invitationModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{i18next.t('ORGANIZATION_INVITE_MEMBER')}</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={inviteMember}
						initialValues={{
							email: '',
						}}
					>
						<Form>
							<ModalBody>
								<Field name='email'>
									{({ field, form }: FieldProps) => (
										<FormControl isRequired isInvalid={Boolean(form.errors.email && form.touched.email)} mt='2'>
											<FormLabel htmlFor='email'>{i18next.t('EMAIL')}</FormLabel>
											<Input id='email' placeholder='john.doe@example.com' autoComplete='none' {...field} />
											<FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={invitationModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={inviteMember.loading}>
									{i18next.t('ORGANIZATION_INVITE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<OrganizationCreateModal disclosure={creationModal} />

			<Prompt
				title={i18next.t('ORGANIZATION_RENAME_TITLE')}
				inputLabel={i18next.t('NAME')}
				okButtonText={i18next.t('RENAME')}
				placeholder={i18next.t('ORGANIZATION_RENAME_PLACEHOLDER')}
				loading={rename.loading}
				handleSubmit={rename}
				disclosure={renameModal}
				initialValue={organization.name}
			/>
		</>
	);
}

export default Organization;
