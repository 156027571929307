import { Text } from '@chakra-ui/react';

import webhookConfigExample from './webhookConfigExample.png';
import signingConfigExample from './signingConfigExample.png';
import CopiableCode from '../../core/CopiableCode';

type PagerDutyInstructionsProps = {
	url: string;
};

function PagerDutyInstructions({ url }: PagerDutyInstructionsProps): JSX.Element {
	return (
		<>
			<Text mt='2'>
				To send alert to Blinky from PagerDuty, you need to create a <b>Generic Webhook (v3)</b>, from Main Menu →
				Integrations → Generic Webhooks (v3). Then click the <b>New Webhook</b> button.
			</Text>
			<Text mt='2'>
				Use the following <b>Webhook URL</b> :
				<CopiableCode bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mt='2' mb='4' display='flex'>
					{url}
				</CopiableCode>
			</Text>
			<Text mt='2' mb='2'>
				The <b>Scope Type</b> and <b>Scope</b> can be used to filter alerts sent to Blinky. The <b>Account</b> scope
				type will send all of the alerts. Blinky can filter them for you as well (see matches below).
			</Text>
			<Text mb='2'>
				(Optional) You can use the <b>Event Subscription</b> section to filter by event. Blinky has a similar feature so
				you can get the same result using the event filtering in matches below.
			</Text>
			<img src={webhookConfigExample} alt='Webhook URL config example' />

			<Text mt='6' mb='2'>
				(Recommended) After creating the Webhook, PagerDuty will give you a <b>signing secret</b> to verify requests.
				Click <b>Edit</b> in the top-right and paste the secret so Blinky will verify payload signatures.
			</Text>
			<img src={signingConfigExample} alt='Signing config example' />
		</>
	);
}

export default PagerDutyInstructions;
