import i18next from 'i18next';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tag,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { FiBox, FiEdit3, FiSlash, FiTerminal, FiWifi } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useContext, useRef } from 'react';

import BeaconForm from '../components/beacon/BeaconForm';
import ErrorComponent from '../components/core/Error';
import FirmwareVersionContext from '../components/core/FirmwareVersionContext';
import Loading from '../components/core/Loading';
import OrderList from '../components/order/OrderList';
import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import { DetailedBeacon } from '../api/client';
import { checkUpToDate } from '../components/beacon/Beacon';

function Beacon(): JSX.Element {
	const { user, organization } = useSession();
	const navigate = useNavigate();
	const editionModal = useDisclosure({
		onClose: () => api.sendConfigurationBeacon(Number(id), organization.id),
	});
	const deassociateModal = useDisclosure();
	const deassociateModalWeakRef = useRef<HTMLButtonElement>(null);
	const { id } = useParams();
	const availableVersion = useContext(FirmwareVersionContext);
	const { data, loading, error, refetch } = useQuery(api.retrieveBeacon, Number(id), organization.id);

	const editBeacon = useCallbackWithLoading(
		async ({ name, statusLedBrightness, ceilingInstallation }: Partial<DetailedBeacon>) => {
			await api.patchBeacon(Number(id), organization.id, {
				name,
				statusLedBrightness,
				ceilingInstallation,
			});

			editionModal.onClose();
			refetch();
		},
	);

	const deassociateBeacon = useCallbackWithLoading(async () => {
		await api.destroyBeacon(Number(id), organization.id);
		deassociateModal.onClose();
		navigate(`/beacons`);
	});

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <ErrorComponent fullscreen error={error} />;
	}

	const {
		name,
		status,
		hardware,
		version,
		networkId,
		orders,
		pendingOrdersCount,
		reactionsCount,
		statusLedBrightness,
		ceilingInstallation,
	} = data!;
	const isUpToDate = !availableVersion || checkUpToDate(version, availableVersion);

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='2'>
				<Flex alignItems='baseline' overflow='hidden' whiteSpace='nowrap'>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
					<Heading size='sm' ml='2' color='gray.600'>
						#{networkId.toUpperCase()}
					</Heading>
				</Flex>
				<div>
					{!isUpToDate && (
						<Button
							variant='outline'
							leftIcon={<FiBox />}
							colorScheme='yellow'
							ml='2'
							onClick={() => navigate(`/beacons/upgrade/${networkId}/instructions`)}
						>
							{i18next.t('BEACON_UPGRADE_TO')}{' '}
							{availableVersion.match(/[0-9]/u) ? `v${availableVersion}` : availableVersion}
						</Button>
					)}
					{user.isStaff && (
						<Link to={`/beacons/console/${networkId}`}>
							<Button variant='outline' leftIcon={<FiTerminal />} colorScheme='blue' ml='2'>
								{i18next.t('BEACON_SERIAL_CONSOLE')}
							</Button>
						</Link>
					)}
					<Link to={`/beacons/configure/${networkId}/instructions`}>
						<Button variant='outline' leftIcon={<FiWifi />} colorScheme='blue' ml='2'>
							{i18next.t('BEACON_CONFIGURE_WIFI')}
						</Button>
					</Link>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={editionModal.onOpen}>
						{i18next.t('EDIT')}
					</Button>
					{data!.owner && (
						<Button leftIcon={<FiSlash />} colorScheme='red' ml='2' onClick={deassociateModal.onOpen}>
							{i18next.t('BEACON_DEASSOCIATE')}
						</Button>
					)}
				</div>
			</Flex>

			<Heading size='md' mt='6'>
				{i18next.t('STATUS')}
			</Heading>
			<Box mt='2'>
				{status === 'up' ? (
					<Tag bg='green.500'>{i18next.t('ONLINE')}</Tag>
				) : (
					<Tag bg='red.500'>{i18next.t('OFFLINE')}</Tag>
				)}
			</Box>
			<Heading size='md' mt='6'>
				{i18next.t('VERSION')}
			</Heading>
			<Tag bg='blue.200' mt='2'>
				{version} ({hardware})
			</Tag>

			<Heading size='md' mt='6'>
				{i18next.t('BEACON_CONNECTED_REACTIONS')}
			</Heading>
			<Text mt='1'>
				{reactionsCount} ({pendingOrdersCount} {i18next.t('BEACON_PENDING_ORDERS')})
			</Text>

			<Heading size='md' mt='6' mb='2'>
				{i18next.t('BEACON_LATEST_ORDERS')}
			</Heading>
			{/* @ts-expect-error */}
			<OrderList orders={orders} spacing='1' refetch={refetch} />

			<Modal isOpen={editionModal.isOpen} onClose={editionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{i18next.t('BEACON_EDIT_TITLE')}</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editBeacon}
						initialValues={{
							name,
							statusLedBrightness,
							ceilingInstallation,
						}}
					>
						<Form>
							<ModalBody>
								<BeaconForm organizationId={organization.id} beaconId={Number(id)} />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={editionModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editBeacon.loading}>
									{i18next.t('UPDATE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<AlertDialog
				isOpen={deassociateModal.isOpen}
				leastDestructiveRef={deassociateModalWeakRef}
				onClose={deassociateModal.onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('BEACON_DEASSOCIATE_TITLE')}
						</AlertDialogHeader>

						<AlertDialogBody>{i18next.t('BEACON_DEASSOCIATE_TIP')}</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deassociateModalWeakRef} onClick={deassociateModal.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' ml={3} onClick={deassociateBeacon} isLoading={deassociateBeacon.loading}>
								{i18next.t('BEACON_UNLINK_AND_DEASSOCIATE')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default Beacon;
