import i18next from 'i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

export type ObserviumSpec = {
	name: string;
	patternId?: string;
};

function ObserviumForm(): JSX.Element {
	return (
		<>
			<Field name='name'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.name && form.touched.name)}>
						<FormLabel htmlFor='name'>{i18next.t('NAME')}</FormLabel>
						<Input id='name' placeholder={i18next.t('OBSERVIUM_NAME_PLACEHOLDER')} autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default ObserviumForm;
