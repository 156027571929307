import i18next from 'i18next';
import { AiOutlineAlert } from 'react-icons/ai';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import { SimpleBeacon } from '../../api/client';

type BeaconListProps = {
	beacons: SimpleBeacon[];
	onSelect: (id: number) => void;
};

function BeaconList({ beacons, onSelect }: BeaconListProps): JSX.Element {
	return (
		<Box borderRadius='xl' overflow='hidden'>
			{beacons.length ? (
				beacons.map((beacon, index) => (
					<Button
						width='100%'
						justifyContent='space-between'
						borderRadius='0'
						key={index}
						onClick={() => onSelect(beacon.id)}
					>
						<Flex>
							<AiOutlineAlert />
							<Text ml='2' fontSize='15px' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='15vw'>
								{beacon.name}
							</Text>
						</Flex>
						<FiChevronRight />
					</Button>
				))
			) : (
				<Flex justifyContent='center' color='gray.500'>
					{i18next.t('BEACON_LIST_EMPTY')}
				</Flex>
			)}
		</Box>
	);
}

export default BeaconList;
