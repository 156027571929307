import i18next from 'i18next';
import {
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useErrorToast from '../hooks/useErrorToast';
import useSession from '../hooks/useSession';

type ResetPasswordForm = typeof initialFormValues;

const initialFormValues = {
	email: '',
};

function SendEmailConfirmation(): JSX.Element {
	const toast = useToast();
	const toastError = useErrorToast();
	const { user } = useSession();
	const [error, setError] = useState(false);
	const boxed = user.id === 0;

	const resetPassword = useCallbackWithLoading(async ({ email }: ResetPasswordForm) => {
		await api.apiV1AuthRegistrationResendEmailCreate({ email });

		try {
			toast({
				title: 'E-mail sent',
				description: `Please follow the link you just received by e-mail to continue`,
				duration: 5000,
				isClosable: true,
				position: 'top',
				status: 'success',
			});
		} catch (error) {
			setError(true);
			toastError('Confirmation Send failed', error);
		}
	});

	const page = (
		<>
			<Heading size='lg' mb='5'>
				{i18next.t('SEND_EMAIL_CONFIRMATION_TITLE')}
			</Heading>
			<Formik onSubmit={resetPassword} initialValues={initialFormValues}>
				<Form>
					<Field name='email'>
						{({ field, form }: FieldProps) => (
							<FormControl isRequired isInvalid={error}>
								<FormLabel htmlFor='email'>E-mail</FormLabel>
								<Input id='email' placeholder='monkeynator@enix.io' autoComplete='none' {...field} />
								<FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
							</FormControl>
						)}
					</Field>

					<Flex alignItems='center' justifyContent='flex-end' pt='4'>
						<Flex mr='4'>
							<Link to='/'>{i18next.t('CANCEL')}</Link>
						</Flex>
						<Button colorScheme='blue' type='submit' isLoading={resetPassword.loading}>
							{i18next.t('ACCEPT')}
						</Button>
					</Flex>
				</Form>
			</Formik>
		</>
	);

	if (boxed) {
		return (
			<>
				<Flex height='100%' justifyContent='center' alignItems='center'>
					<Box width='500px' borderWidth='1px' borderRadius='lg' p='4'>
						{page}
					</Box>
				</Flex>
			</>
		);
	}

	return <Container size='xl'>{page}</Container>;
}

export default SendEmailConfirmation;
