import { Code, Icon, Tag } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';

export type FilterSpec = {
	key: string;
	op: 'eq' | 'neq' | 'regex' | 'nregex';
	value: string;
};

export type FilterProps = {
	filter: FilterSpec;
	onRemove: () => void;
};

function Filter({ filter: { key, op, value }, onRemove }: FilterProps): JSX.Element {
	return (
		<Tag m='1'>
			<Code backgroundColor='transparent'>{key}</Code>
			<Code mx='1'>{displayOperator(op)}</Code>
			<Code backgroundColor='transparent'>{value}</Code>
			<Icon as={FiX} ml='2' onClick={onRemove} cursor='pointer' />
		</Tag>
	);
}

function displayOperator(operator: string): string {
	switch (operator) {
		case 'eq':
			return '==';
		case 'neq':
			return '!=';
		case 'regex':
			return '=~';
		case 'nregex':
			return '!~';
		default:
			return operator;
	}
}

export default Filter;
