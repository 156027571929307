import { Box } from '@chakra-ui/react';

import CircularArc from './CircularArc';
import { Color, checkColorIsTransparent, convertColorToCSS } from '../../utility/color';

type BeaconPreviewProps = {
	colors: Color[];
	bg?: string;
	offColor?: string;
};

const width = 25;

function BeaconPreview({ colors, bg = 'gray.900', offColor = 'gray.500' }: BeaconPreviewProps): JSX.Element {
	const getColor = (index: number) =>
		checkColorIsTransparent(colors[index]) ? offColor : convertColorToCSS(colors[index]);

	return (
		<>
			<Box w='100%' h='100%' borderRadius='50%' overflow='hidden' position='relative'>
				<CircularArc rotation={0} skew={0} bg={getColor(5)} />
				<CircularArc rotation={0} skew={30} bg={getColor(4)} />
				<CircularArc rotation={0} skew={60} bg={getColor(3)} />
				<CircularArc rotation={90} skew={0} bg={getColor(8)} />
				<CircularArc rotation={90} skew={30} bg={getColor(7)} />
				<CircularArc rotation={90} skew={60} bg={getColor(6)} />
				<CircularArc rotation={180} skew={0} bg={getColor(11)} />
				<CircularArc rotation={180} skew={30} bg={getColor(10)} />
				<CircularArc rotation={180} skew={60} bg={getColor(9)} />
				<CircularArc rotation={270} skew={0} bg={getColor(2)} />
				<CircularArc rotation={270} skew={30} bg={getColor(1)} />
				<CircularArc rotation={270} skew={60} bg={getColor(0)} />
			</Box>
			<Box
				position='relative'
				top={`-${100 - width}%`}
				left={`${width}%`}
				w={`${100 - width * 2}%`}
				h={`${100 - width * 2}%`}
				borderRadius='50%'
				overflow='hidden'
				bg={bg}
			/>
		</>
	);
}

export default BeaconPreview;
