import RemoteOrderPatternPlayer from './RemoteOrderPatternPlayer';
import { Color } from '../../utility/color';

type ControllableRemoteOrderPatternPlayerProps = {
	play: boolean;
	organizationId: number;
	beaconId: number;
	orderId: number;
	renderFrame: (colors: Color[]) => JSX.Element;
};

const emptyFrame = new Array(12).fill({}).map(() => ({
	r: 0,
	g: 0,
	b: 0,
}));

function ControllableRemoteOrderPatternPlayer({
	play,
	organizationId,
	beaconId,
	orderId,
	renderFrame,
}: ControllableRemoteOrderPatternPlayerProps): JSX.Element {
	if (!play) {
		return renderFrame(emptyFrame);
	}

	return (
		<RemoteOrderPatternPlayer
			organizationId={organizationId}
			beaconId={beaconId}
			orderId={orderId}
			renderFrame={renderFrame}
		/>
	);
}

export default ControllableRemoteOrderPatternPlayer;
