import axios from 'axios';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Code,
	Flex,
	Heading,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import { useEffect, useState } from 'react';

import macosExampleAuthorizeAccessory from '../components/serial/macosExampleAuthorizeAccessory.png';
import windowsDeviceManagerExampleWithDriver from '../components/serial/windowsDeviceManagerExampleWithDriver.png';
import windowsDeviceManagerExampleWithoutDriver from '../components/serial/windowsDeviceManagerExampleWithoutDriver.png';
import { getHost } from '../utility/host';

function SerialInstructions(): JSX.Element {
	const navigate = useNavigate();
	const [mqttUrl, setMqttUrl] = useState('Loading...');
	const isSerialSupported = Boolean(window.navigator.serial);

	const getNextUrl = () => {
		const urlParts = window.location.href.split('/');
		urlParts.pop();
		return urlParts.join('/');
	};

	useEffect(() => {
		const fetchMqttUrl = async () => {
			const { data } = await axios.get(`${getHost()}/api/v1/broker`);
			setMqttUrl(data.url);
		};

		fetchMqttUrl();
	}, []);

	return (
		<Box>
			<Heading size='md'>Connecting Blinky with USB</Heading>
			<Text mt='2'>
				Before connecting to your Blinky, please check the following requirements based on your operating system,
				browser and network environment.
			</Text>
			<Heading size='sm' mt='8'>
				Operating system
			</Heading>
			<Accordion allowMultiple defaultIndex={[getOSAccordionIndex()]} mt='2'>
				<AccordionItem>
					<AccordionButton>
						Linux
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pl='8' pb='4'>
						<ul>
							<li>
								Your user must have permissions to read and write the serial device. On most distributions, you can
								achieve this by adding your user to the "dialout" group.
							</li>
							<li>
								To do so, execute the following command: <Code>sudo gpasswd --add $USER dialout</Code>, and then restart
								your session.
							</li>
						</ul>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionButton>
						Windows
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pl='8' pb='4'>
						<ul>
							<li>
								You may need a driver for the USB device. You can download it here:{' '}
								<Button
									colorScheme='blue'
									variant='link'
									onClick={() =>
										window.open('https://www.silabs.com/documents/public/software/CP210x_Universal_Windows_Driver.zip')
									}
								>
									CP210x Universal Windows Driver.
								</Button>
							</li>
							<li>
								To check if the driver is already installed and the device is ready to be used, follow these steps:
								<ol style={{ marginLeft: 24 }}>
									<li>
										Open the Device Manager:
										<ul style={{ marginLeft: 24 }}>
											<li>
												In the search box on the taskbar, type "Device Manager," then select it from the search results.
											</li>
										</ul>
									</li>
									<li>
										Look for a "CP2104 USB to UART Bridge Controller" device. If it appears as shown in the left picture
										under the "Ports" section, the driver is already installed and the device is ready for use.
									</li>
									<li>
										If it appears as shown in the right picture with a little yellow warning sign, you'll need to
										install the driver:
										<ol type='a' style={{ marginLeft: 24 }}>
											<li>Download the driver archive and decompress it.</li>
											<li>Right-click on the device in the Device Manager and select "Update driver" from the menu.</li>
											<li>
												Choose "Browse my computer for drivers" and select the directory where you decompressed the
												driver archive.
											</li>
											<li>Complete the installation process.</li>
											<li>Re-plug the device to check if it is now recognized by the system.</li>
										</ol>
									</li>
								</ol>
							</li>
						</ul>

						<Flex mt='8' justifyContent='space-around'>
							<Flex direction='column' alignItems='center'>
								<img src={windowsDeviceManagerExampleWithDriver} alt='With driver' />
								<Text mt='2'>With the driver</Text>
							</Flex>
							<Flex direction='column' alignItems='center'>
								<img src={windowsDeviceManagerExampleWithoutDriver} alt='Without driver' />
								<Text mt='2'>Without the driver</Text>
							</Flex>
						</Flex>
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem>
					<AccordionButton>
						macOS
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pl='8' pb='4'>
						<ul>
							<li>
								When connecting the Blinky to your computer, be sure to authorize the device to connect in the appearing
								popup:
							</li>
						</ul>

						<Box mt='4'>
							<img src={macosExampleAuthorizeAccessory} alt='macOS authorize accessory example' />
						</Box>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Heading size='sm' mt='8'>
				Browser compatiblity
			</Heading>
			<Text mt='2'>
				The Blinky application uses the Web Serial API to communicate with the beacon. This API is implemented in the
				following browsers:
			</Text>
			<ul style={{ marginLeft: 30 }}>
				<li>Chrome (since v89)</li>
				<li>Edge (since v89)</li>
				<li>Opera (since v76)</li>
				<li>And potentially in all Chrome-based browsers</li>
			</ul>

			<Heading size='sm' mt='8'>
				Network requirements
			</Heading>
			<Text mt='2'>The Blinky requires the following outbound internet connection to work:</Text>
			<table style={{ marginTop: 12 }}>
				<thead>
					<tr>
						<th>Source</th>
						<th>Destination</th>
						<th>Protocol</th>
						<th>Port</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Blinky</td>
						<td>{mqttUrl}</td>
						<td>TCP</td>
						<td>443</td>
					</tr>
				</tbody>
			</table>

			<Text mt='8'>
				Also, note that we currently do not support proxy settings, captive portals and enterprise Wi-Fi networks
				security schemes (like WPA2-Enterprise).
			</Text>

			<Flex mt='2'>
				<Tooltip isOpen={isSerialSupported ? false : undefined} label='Web serial is not supported on this browser'>
					<Link to={getNextUrl()}>
						<Button colorScheme='blue' mr='2' isDisabled={!isSerialSupported}>
							Continue
						</Button>
					</Link>
				</Tooltip>
				<Button variant='outline' onClick={() => navigate(-1)}>
					Cancel
				</Button>
			</Flex>
		</Box>
	);
}

function getOSAccordionIndex(): number {
	switch (guessOperatingSystem()) {
		case 'Windows':
			return 1;
		case 'Mac OS':
			return 2;
		default:
			return 0;
	}
}

function guessOperatingSystem() {
	return new UAParser(window.navigator.userAgent).getOS().name;
}

export default SerialInstructions;
