import './index.css';

import * as Sentry from '@sentry/react';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import App from './components/App';
import en from './i18n/en.json';
import fr from './i18n/fr.json';

const configScript = document.createElement('script');
configScript.setAttribute('src', '/config.js');
configScript.setAttribute('type', 'text/javascript');
configScript.addEventListener('load', () => {
	Sentry.init({
		// @ts-expect-error
		dsn: window.config.SENTRY_DSN,
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			new Sentry.Replay(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/\*\.getblinky\.io\/api/u],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

	i18next.use(LanguageDetector).init({
		debug: true,
		fallbackLng: 'en',
		resources: {
			en: {
				translation: en,
			},
			fr: {
				translation: fr,
			},
		},
	});

	createRoot(document.getElementById('root')!).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
});

document.body.appendChild(configScript);
