import i18next from 'i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

import PatternSelect from '../../pattern/PatternSelect';
import { SimplePattern } from '../../../api/client';

export type GitlabReactionFormProps = {
	patterns?: SimplePattern[];
};

function GitlabReactionForm({ patterns }: GitlabReactionFormProps): JSX.Element {
	return (
		<>
			<Field name='minimumInterval'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.minimumInterval && form.touched.minimumInterval)}>
						<FormLabel htmlFor='minimumInterval'>{i18next.t('INTEGRATIONS_MINIMUM_INTERVAL')}</FormLabel>
						<Input id='minimumInterval' placeholder='60' autoComplete='none' defaultValue={60000} {...field} />
						<FormErrorMessage>{form.errors.minimumInterval as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			{patterns && <PatternSelect required name='patternId' label='Pattern' patterns={patterns} />}
		</>
	);
}

export default GitlabReactionForm;
