import i18next from 'i18next';
import {
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Text,
	useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

import api from '../api';
import useErrorToast from '../hooks/useErrorToast';
import useSession from '../hooks/useSession';

type UpdatePasswordForm = typeof initialFormValues;

const initialFormValues = {
	oldPassword: '',
	newPassword: '',
	newPasswordConfirmation: '',
};

function UpdatePassword(): JSX.Element {
	const toast = useToast();
	const toastError = useErrorToast();
	const navigate = useNavigate();
	const { user } = useSession();
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { uid, code } = useParams();
	const boxed = typeof code === 'string';

	const updatePassword = async ({ oldPassword, newPassword, newPasswordConfirmation }: UpdatePasswordForm) => {
		setLoading(true);

		try {
			if (newPassword !== newPasswordConfirmation) {
				throw new Error(i18next.t('UPDATE_PASSWORD_DONT_MATCH'));
			}

			if (code) {
				await api.apiV1AuthPasswordResetConfirmCreate({
					uid: uid!,
					token: code,
					newPassword1: newPassword,
					newPassword2: newPasswordConfirmation,
				});
			} else {
				await api.apiV1AuthPasswordChangeCreate({
					newPassword1: newPassword,
					newPassword2: newPasswordConfirmation,
				});
			}

			toast({
				title: i18next.t('UPDATE_PASSWORD_SUCCESS_TITLE'),
				description: `${i18next.t('UPDATE_PASSWORD_SUCCESS_TIP')} "${user.username}"`,
				duration: 5000,
				isClosable: true,
				position: 'top',
				status: 'success',
			});

			navigate('/');
		} catch (error) {
			setError(true);

			if ((error as Error).message.includes('403')) {
				toastError(i18next.t('UPDATE_PASSWORD_FAILED_TITLE'), i18next.t('UPDATE_PASSWORD_FAILED_OLD_PASSWORD_INVALID'));
			} else {
				toastError(i18next.t('UPDATE_PASSWORD_FAILED_TITLE'), error);
			}
		}

		setLoading(false);
	};

	const page = (
		<>
			<Heading size='lg' mb='5'>
				{i18next.t('UPDATE_PASSWORD_TITLE')}
			</Heading>
			{!code && (
				<Text size='sm' mb='4' color='gray.500'>
					{i18next.t('UPDATE_PASSWORD_TIP')} "{user.username}" ({user.email}).
				</Text>
			)}
			<Formik onSubmit={updatePassword} initialValues={initialFormValues}>
				<Form>
					{!code && (
						<Field name='oldPassword'>
							{({ field, form }: FieldProps) => (
								<FormControl isRequired isInvalid={error}>
									<FormLabel htmlFor='oldPassword'>{i18next.t('UPDATE_PASSWORD_CURRENT_PASSWORD')}</FormLabel>
									<Input id='oldPassword' type='password' placeholder='*****' autoComplete='none' {...field} />
									<FormErrorMessage>{form.errors.oldPassword as string}</FormErrorMessage>
								</FormControl>
							)}
						</Field>
					)}

					<Box mt='3' />
					<Field name='newPassword'>
						{({ field, form }: FieldProps) => (
							<FormControl isRequired isInvalid={error}>
								<FormLabel htmlFor='newPassword'>{i18next.t('UPDATE_PASSWORD_NEW_PASSWORD')}</FormLabel>
								<Input id='newPassword' type='password' placeholder='*****' autoComplete='none' {...field} />
								<FormErrorMessage>{form.errors.newPassword as string}</FormErrorMessage>
							</FormControl>
						)}
					</Field>

					<Box mt='3' />
					<Field name='newPasswordConfirmation'>
						{({ field, form }: FieldProps) => (
							<FormControl isRequired isInvalid={error}>
								<FormLabel htmlFor='newPasswordConfirmation'>{i18next.t('UPDATE_PASSWORD_NEW_PASSWORD_2')}</FormLabel>
								<Input
									id='newPasswordConfirmation'
									type='password'
									placeholder='*****'
									autoComplete='none'
									{...field}
								/>
								<FormErrorMessage>{form.errors.newPasswordConfirmation as string}</FormErrorMessage>
							</FormControl>
						)}
					</Field>

					<Flex alignItems='center' justifyContent='flex-end' pt='4'>
						<Flex mr='4'>
							<Link to='/'>{i18next.t('CANCEL')}</Link>
						</Flex>
						<Button colorScheme='blue' type='submit' isLoading={loading}>
							{i18next.t('UPDATE_PASSWORD_TITLE')}
						</Button>
					</Flex>
				</Form>
			</Formik>
		</>
	);

	if (boxed) {
		return (
			<>
				<Flex height='100%' justifyContent='center' alignItems='center'>
					<Box width='500px' borderWidth='1px' borderRadius='lg' p='4'>
						{page}
					</Box>
				</Flex>
			</>
		);
	}

	return <Container size='xl'>{page}</Container>;
}

export default UpdatePassword;
