import axios from 'axios';
import i18next from 'i18next';
import { Box, Button, Heading, Icon, Text } from '@chakra-ui/react';
import { FiBookmark, FiCheck, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import api from '../api';
import useErrorToast from '../hooks/useErrorToast';
import { getHost } from '../utility/host';

function OrganizationJoin(): JSX.Element {
	const navigate = useNavigate();
	const toastError = useErrorToast();

	const join = async () => {
		const { access, refresh: newRefresh } = await api.apiV1AuthTokenRefreshCreate(
			{
				refresh: localStorage.getItem('refresh')!,
			},
			// @ts-expect-error
			{ skipAuthRefresh: true },
		);

		sessionStorage.setItem('access', access);
		localStorage.setItem('refresh', newRefresh);

		try {
			await axios.get(`${getHost()}/api/v1/invitations/accept-invite/${localStorage.getItem('invitation')!}`, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('access')}`,
					Origin: window.location.origin,
				},
			});

			finish();
		} catch (error) {
			if (JSON.parse(JSON.stringify(error)).status === 410) {
				toastError('Invitation invalid', 'This invitation is invalid or expired.');
			}

			localStorage.removeItem('invitation');
			localStorage.removeItem('invitation-orgname');
			navigate('/');
		}
	};

	const decline = () => finish();

	const finish = () => {
		localStorage.removeItem('invitation');
		localStorage.removeItem('invitation-orgname');
		window.location.replace('/');
	};

	return (
		<Box textAlign='center' py={10} px={6}>
			<Icon boxSize='50px' as={FiBookmark} />
			<Heading as='h2' size='xl' mt={6} mb={2}>
				{i18next.t('ORGANIZATION_JOIN_TITLE')}
			</Heading>
			<Text color='gray.500'>
				{i18next.t('ORGANIZATION_JOIN_TIP')}
				{localStorage.getItem('invitation-orgname')}
			</Text>
			<Button colorScheme='blue' leftIcon={<FiCheck />} mt='4' mr='2' onClick={join}>
				{i18next.t('ACCEPT')}
			</Button>
			<Button leftIcon={<FiX />} mt='4' onClick={decline}>
				{i18next.t('DECLINE')}
			</Button>
		</Box>
	);
}

export default OrganizationJoin;
