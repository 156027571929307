import { useEffect, useState } from 'react';

import useCallbackWithLoading from './useCallbackWithLoading';
import { BrowserApiError } from '../api/error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PromiseReturningFunction = (...args: any[]) => Promise<any>;

export type UseQueryReturn<Call extends PromiseReturningFunction> = {
	data: Awaited<ReturnType<Call>> | undefined;
	loading: boolean;
	refetch: () => void;
	error?: BrowserApiError;
};

function useQuery<Call extends PromiseReturningFunction>(
	call: Call,
	...parameters: Parameters<Call>
): UseQueryReturn<Call> {
	const [response, setResponse] = useState<Awaited<ReturnType<Call>>>();
	const [error, setError] = useState<BrowserApiError>();
	const [refetchNonce, setRefetchNonce] = useState(0);
	const [ranOnce, setRanOnce] = useState(false);

	const fetch = useCallbackWithLoading(async () => {
		try {
			setResponse(await call(...parameters));
		} catch (error) {
			if (error instanceof BrowserApiError) {
				setError(error);
			} else {
				console.error(error);
			}
		}
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		fetch();
		setRanOnce(true);
	}, [refetchNonce]);

	return {
		data: response,
		loading: fetch.loading || !ranOnce,
		error,
		refetch: () => setRefetchNonce((nonce) => nonce + 1),
	};
}

export default useQuery;
