import { Message } from "./SerialInterface";

enum ParserState {
	// 'O' => OK_K; 'E' => ERROR_R1
	HEADER_START,
	// => SPACE
	OK_K,
	// => ERROR_R2
	ERROR_R1,
	// => ERROR_O
	ERROR_R2,
	// => ERROR_R3
	ERROR_O,
	// => SPACE
	ERROR_R3,
	// => PAYLOAD_LENGTH
	SPACE,
	// \d => PAYLOAD_LENGTH; \r => LINE_FEED
	PAYLOAD_LENGTH,
	// => PAYLOAD
	LINE_FEED,
	// => eat <length>
	PAYLOAD,
}

class BeaconSerialTransformer implements Transformer<Uint8Array, Message> {
	payload: Uint8Array;

	state: ParserState;

	length: string;

	leftLength: number;

	ok: boolean | null;

	decoder: TextDecoder;

	constructor() {
		this.state = ParserState.HEADER_START;
		this.length = '';
		this.leftLength = 0;
		this.ok = null;
		this.payload = new Uint8Array();
		this.decoder = new TextDecoder('utf-8');
	}

	transform(chunk: Uint8Array, controller: TransformStreamDefaultController<Message>): void {

		if (window.showBeaconLogs) {
			console.info(`Chunk: ${chunk}`);
		}

		for (const byte of chunk) {
			const char = String.fromCharCode(byte);

			if (window.showSerialParserLogs) {
				console.info(`Parse: current="${char}" raw="${byte}" state=${ParserState[this.state]}`);
			}

			switch (this.state) {
				case ParserState.HEADER_START:
					this.length = '';
					if (char === 'O') {
						this.state = ParserState.OK_K;
					} else if (char === 'E') {
						this.state = ParserState.ERROR_R1;
					}
					break;
				case ParserState.OK_K:
					if (char === 'K') {
						this.ok = true;
						this.state = ParserState.SPACE;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.ERROR_R1:
					if (char === 'R') {
						this.state = ParserState.ERROR_R2;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.ERROR_R2:
					if (char === 'R') {
						this.state = ParserState.ERROR_O;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.ERROR_O:
					if (char === 'O') {
						this.state = ParserState.ERROR_R3;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.ERROR_R3:
					if (char === 'R') {
						this.ok = false;
						this.state = ParserState.SPACE;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.SPACE:
					if (char === ' ') {
						this.state = ParserState.PAYLOAD_LENGTH;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.PAYLOAD_LENGTH:
					if (char === '\r') {
						this.leftLength = parseInt(this.length, 10);
						this.payload = new Uint8Array(this.leftLength);
						this.state = ParserState.LINE_FEED;
					} else if (isNaN(parseInt(char, 10))) {
						this.state = ParserState.HEADER_START;
					} else {
						this.length += char;
					}
					break;
				case ParserState.LINE_FEED:
					if (char === '\n') {
						this.state = ParserState.PAYLOAD;
					} else {
						this.state = ParserState.HEADER_START;
					}
					break;
				case ParserState.PAYLOAD:
					if (this.leftLength > 0) {
						this.payload[this.payload.length - this.leftLength] = byte;
						this.leftLength -= 1;
					} else {
						this.state = ParserState.HEADER_START;
						const data = this.decoder.decode(this.payload);
						controller.enqueue({
							status: this.ok ? 'OK' : 'ERROR',
							data,
						});
					}
			}
		}
	}
}

export default BeaconSerialTransformer;
