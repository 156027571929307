import { BsLock, BsWifi, BsWifi1, BsWifi2 } from 'react-icons/bs';
import { Button, Flex, Icon } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import { WifiAccessPoint } from '../../components/serial/BeaconSerialInterface';

type WifiButtonProps = {
	wifi: WifiAccessPoint;
	onClick: () => void;
};

function WifiButton({ wifi: { ssid, attenuation, locked }, onClick }: WifiButtonProps): JSX.Element {
	return (
		<Button width='100%' flexGrow={1} justifyContent='space-between' borderRadius='0' onClick={onClick}>
			<Flex ml='2'>{ssid}</Flex>
			<Flex mr='2' alignItems='center'>
				{locked && <Icon as={BsLock} mx='1' fontSize='13' />}
				{attenuation < 0 && <Icon as={getWifiIconFromAttenuation(attenuation)} mx='1' />}
				<FiChevronRight />
			</Flex>
		</Button>
	);
}

function getWifiIconFromAttenuation(attenuation: number) {
	if (attenuation >= -46) {
		return BsWifi;
	}

	if (attenuation >= -60) {
		return BsWifi2;
	}

	return BsWifi1;
}

export default WifiButton;
