import i18next from 'i18next';
import { Button, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import { FiAlertTriangle, FiChevronRight, FiLayers, FiPlus } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import Empty from '../components/core/Empty';
import Error from '../components/core/Error';
import IntegrationIcon from '../components/integrations/IntegrationIcon';
import Loading from '../components/core/Loading';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import IntegrationForm, { IntegrationSpec } from '../components/integrations/IntegrationForm';
import { GithubSpec } from '../components/integrations/github/GithubForm';
import { Gitlab2Spec } from '../components/integrations/gitlab2/Gitlab2Form';
import { GitlabSpec } from '../components/integrations/gitlab/GitlabForm';
import { ObserviumSpec } from '../components/integrations/observium/ObserviumForm';
import { PagerDutySpec } from '../components/integrations/pagerduty/PagerDutyForm';
import { PrometheusSpec } from '../components/integrations/prometheus/PrometheusForm';
import { RobustaSpec } from '../components/integrations/robusta/RobustaForm';
import { WebhookSpec } from '../components/integrations/webhook/WebhookForm';

function Integrations(): JSX.Element {
	const navigate = useNavigate();
	const { organization } = useSession();
	const creationModal = useDisclosure();
	const {
		queries: [integrations, patterns],
		loading,
		error,
	} = useAggregatedQuery(useQuery(api.listIntegrations, organization.id), useQuery(api.listPatterns, organization.id));

	const handleCreate = useCallbackWithLoading(async (integration: IntegrationSpec) => {
		try {
			const { id } = await createIntegration(integration, organization.id);
			navigate(`/integrations/${integration.kind.toLowerCase()}/${id}`);
		} finally {
			creationModal.onClose();
		}
	});

	if (error) {
		return <Error fullscreen error={error} />;
	}
	if (loading) {
		return <Loading />;
	}

	return (
		<>
			{integrations.data?.results?.length ? (
				<>
					<Flex justifyContent='space-between' alignItems='flex-end' pb='2'>
						<Heading size='lg'>{i18next.t('INTEGRATIONS_TITLE')}</Heading>
						<Button colorScheme='blue' leftIcon={<FiPlus />} onClick={creationModal.onOpen}>
							{i18next.t('INTEGRATIONS_CREATE')}
						</Button>
					</Flex>

					{integrations.data.results.map((integration) => (
						<Link to={`/integrations/${integration.kind.toLowerCase()}/${integration.id}`}>
							<Button p='4' mt='4' borderRadius='xl' w='100%' key={integration.id}>
								<Flex justifyContent='space-between' alignItems='center' w='100%'>
									<Flex alignItems='center'>
										<IntegrationIcon kind={integration.kind} />
										<Heading size='xs' ml='2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
											{integration.name}
										</Heading>
										{/* @ts-expect-error */}
										{!integration.impl.targetBeaconsCount && (
											<Flex color='yellow.500' ml='2' alignItems='center'>
												<Icon as={FiAlertTriangle} mr='1' />
												{i18next.t('INTEGRATIONS_NO_LINKED_BEACONS')}
											</Flex>
										)}
									</Flex>
									<FiChevronRight />
								</Flex>
							</Button>
						</Link>
					))}
				</>
			) : (
				<Empty
					title={i18next.t('INTEGRATIONS_LIST_EMPTY')}
					action={i18next.t('INTEGRATIONS_CREATE')}
					// @ts-expect-error
					icon={FiLayers}
					onCreate={creationModal.onOpen}
				>
					{i18next.t('INTEGRATIONS_CREATE_TIP')}
				</Empty>
			)}

			<IntegrationForm
				disclosure={creationModal}
				patterns={patterns.data!.results!}
				onSubmit={handleCreate}
				loading={handleCreate.loading}
			/>
		</>
	);
}

async function createIntegration(integration: IntegrationSpec, organizationId: number) {
	switch (integration.kind) {
		case 'Webhook':
			const webhookSpec = integration as WebhookSpec;
			return api.createWebhook(organizationId, {
				name: webhookSpec.name,
				defaultReaction: {
					minimumInterval: webhookSpec.minimumInterval,
					patternId: Number(webhookSpec.patternId),
				},
			});

		case 'Gitlab':
			const gitlabSpec = integration as GitlabSpec;
			return api.createGitlab(organizationId, { name: gitlabSpec.name });

		case 'Github':
			const githubSpec = integration as GithubSpec;
			return api.createGithub(organizationId, { name: githubSpec.name });

		case 'Prometheus':
			const prometheusSpec = integration as PrometheusSpec;
			return api.createPrometheus(organizationId, {
				name: prometheusSpec.name,
			});

		case 'StatusCake':
			const statusCakeSpec = integration as PrometheusSpec;
			return api.createStatusCake(organizationId, {
				name: statusCakeSpec.name,
			});

		case 'Observium':
			const observiumSpec = integration as ObserviumSpec;
			return api.createObservium(organizationId, {
				name: observiumSpec.name,
			});

		case 'PagerDuty':
			const pagerDutySpec = integration as PagerDutySpec;
			return api.createPagerDuty(organizationId, {
				name: pagerDutySpec.name,
			});

		case 'Gitlab2':
			const gitlab2Spec = integration as Gitlab2Spec;
			return api.createGitlab2(organizationId, {
				name: gitlab2Spec.name,
			});

		case 'Robusta':
			const robustaSpec = integration as RobustaSpec;
			return api.createRobusta(organizationId, {
				name: robustaSpec.name,
			});
	}
}

export default Integrations;
