import i18next from 'i18next';
import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { FiZap } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import Card from '../core/Card';
import LinkedTargetList from '../group/LinkedTargetList';
import Loading from '../core/Loading';
import PatternList from '../pattern/PatternList';
import api from '../../api';
import useCallbackWithLoading from '../../hooks/useCallbackWithLoading';
import useSession from '../../hooks/useSession';
import { DetailedReaction, SimplePattern, SimpleTarget } from '../../api/client';

type ReactionProps = {
	reaction: DetailedReaction;
	targets: SimpleTarget[];
	patterns: SimplePattern[];
	refetch: () => void;
};

function Reaction({ reaction, targets, patterns, refetch }: ReactionProps): JSX.Element {
	const { organization } = useSession();
	const patternSelectModal = useDisclosure();

	const unlinkedTargets = useMemo(
		() => (targets || []).filter((target) => !reaction.targets?.find((linkedTarget) => target.id === linkedTarget.id)),
		[reaction, targets],
	);

	const unusedPatterns = useMemo(
		() => (patterns || []).filter((pattern) => reaction.pattern.id !== pattern.id),
		[reaction, patterns],
	);

	const linkTarget = useCallbackWithLoading(async (newId: number) => {
		try {
			await api.linkToReaction(reaction.id, organization.id, newId.toString());
		} finally {
			refetch();
		}
	});

	const unlinkTarget = useCallbackWithLoading(async (removeId: number) => {
		try {
			await api.unlinkFromReaction(reaction.id, organization.id, removeId.toString());
		} finally {
			refetch();
		}
	});

	const updatePattern = useCallbackWithLoading(async (patternId: number) => {
		try {
			await api.patchReaction(reaction.id, organization.id, {
				patternId,
			});
		} finally {
			refetch();
			patternSelectModal.onClose();
		}
	});

	if (linkTarget.loading || unlinkTarget.loading || updatePattern.loading) {
		return <Loading />;
	}

	return (
		<>
			<Flex>
				<Heading size='sm' mt='2' mb='2'>
					{i18next.t('PATTERN')}
					<Button variant='link' colorScheme='blue' onClick={patternSelectModal.onOpen} ml='2'>
						{i18next.t('PATTERN_CHOOSE')}
					</Button>
				</Heading>
			</Flex>
			<Box>
				<Card>
					<Flex justifyContent='space-between' alignItems='center' w='100%'>
						<Flex alignItems='center'>
							<Icon mr='2' fontSize='14' as={FiZap} />
							<Link to={`/patterns/${reaction.pattern.id}`}>
								<Button variant='link'>
									<Heading size='xs' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='70vw'>
										{reaction.pattern.name}
									</Heading>
								</Button>
							</Link>
						</Flex>
					</Flex>
				</Card>
			</Box>

			<LinkedTargetList
				linkedTargets={reaction.targets}
				allTargets={unlinkedTargets}
				linkTarget={linkTarget}
				unlinkTarget={unlinkTarget}
			/>

			<Modal isOpen={patternSelectModal.isOpen} onClose={patternSelectModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{i18next.t('PATTERN_SELECT_TITLE')}</ModalHeader>
					<ModalBody>
						<PatternList patterns={unusedPatterns} onSelect={updatePattern} spacing='2' />
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='blue' onClick={patternSelectModal.onClose}>
							{i18next.t('CANCEL')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

// async function createReaction(
// 	patternId: number,
// 	userId: number,
// 	minimumInterval: number | string,
// ): Promise<ReactionSchema> {
// 	const {
// 		data: {
// 			createReaction: { reaction },
// 		},
// 	} = await graphql.mutate({
// 		mutation: createReactionMutation,
// 		variables: {
// 			patternId: Number(patternId),
// 			userId,
// 			minimumInterval: Number(minimumInterval),
// 		},
// 	});

// 	return reaction;
// }

// async function deleteReaction(reaction: ReactionSchema): Promise<void> {
// 	const prepareMutation = buildPrepareDeleteReactionMutation(reaction);
// 	if (prepareMutation) {
// 		await graphql.mutate({
// 			mutation: prepareMutation,
// 		});
// 	}

// 	await graphql.mutate({
// 		mutation: deleteReactionMutation,
// 		variables: {
// 			id: Number(reaction.id),
// 		},
// 	});
// }

// function buildPrepareDeleteReactionMutation(reaction: ReactionSchema) {
// 	let unlinkMutations = '';

// 	for (const beacon of reaction.beacons) {
// 		unlinkMutations += `
// 			unlinkBeacon${beacon.id}: deleteBeaconReaction(input: { beaconId: ${beacon.id}, reactionId: ${reaction.id}}) {
// 				clientMutationId
// 			}
// 		`;
// 	}

// 	for (const group of reaction.groups) {
// 		unlinkMutations += `
// 			unlinkGroup${group.id}: deleteGroupReaction(input: { groupId: ${group.id}, reactionId: ${reaction.id}}) {
// 				clientMutationId
// 			}
// 		`;
// 	}

// 	if (!unlinkMutations) {
// 		return null;
// 	}

// 	return gql`
// 		mutation {
// 			${unlinkMutations}
// 		}
// 	`;
// }

export default Reaction;
