import './gitlab2.css';

import i18next from 'i18next';
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Heading,
	Tag,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiSave } from 'react-icons/fi';
import { useState } from 'react';

import CreatableSelect from '../../core/CreatableSelect';
import FilterList from '../../core/FilterList';
import Reaction from '../../reaction/Reaction';
import { DetailedGitlab2Match, SimplePattern, SimpleTarget } from '../../../api/client';
import { FilterSpec } from '../../core/Filter';

type Gitlab2MatchProps = {
	match: DetailedGitlab2Match;
	save: () => void;
	openReactionEditionModal: (match: DetailedGitlab2Match) => void;
	targets: SimpleTarget[];
	patterns: SimplePattern[];
	refetch: () => void;
	removeMatch: (id: number) => void;
};

function Gitlab2Match({
	match,
	targets,
	patterns,
	refetch,
	openReactionEditionModal,
	removeMatch,
	save,
}: Gitlab2MatchProps): JSX.Element {
	const [dirty, setDirty] = useState(false);
	const [filters, setFilters] = useState(match.filters);
	const [event, setEvent] = useState(gitlab2Events.find((event) => event.value === match.event)!);

	const addFilter = (filter: FilterSpec) => {
		setFilters([...filters, filter]);
		setDirty(true);
	};

	const removeFilter = (index: number) => {
		setFilters(filters.filter((_, i) => i !== index));
		setDirty(true);
	};

	const updateEvent = (event: unknown) => {
		// @ts-expect-error
		setEvent(event);
		setDirty(true);
	};

	const saveAndMarkClean = async (...args: unknown[]) => {
		// @ts-expect-error
		await save(...args);
		setDirty(false);
	};

	return (
		<AccordionItem key={match.id}>
			<h2>
				<AccordionButton>
					{match.name}
					<AccordionIcon />
				</AccordionButton>
			</h2>

			<AccordionPanel>
				<Reaction reaction={match.reaction} targets={targets} patterns={patterns} refetch={refetch} />

				<Box mt='6' />

				<Heading size='sm' mb='2'>
					Event
				</Heading>
				{/* @ts-expect-error */}
				<CreatableSelect value={event} onChange={updateEvent} options={gitlab2Events} menuMaxHeight='100px' />

				<Heading size='sm' mb='2' mt='4'>
					{i18next.t('FILTER_LIST_TITLE')}
				</Heading>
				<FilterList filters={filters} addFilter={addFilter} removeFilter={removeFilter} />

				<Flex mt='2'>
					<Button
						isDisabled={!dirty}
						colorScheme='blue'
						leftIcon={<FiSave />}
						mt='4'
						mr='2'
						onClick={() => saveAndMarkClean(match.id, match.name, event.value, filters)}
					>
						{dirty ? 'Save' : 'Saved'}
					</Button>
					<Button
						variant='outline'
						colorScheme='blue'
						leftIcon={<FiEdit3 />}
						mt='4'
						mr='2'
						onClick={() => openReactionEditionModal(match)}
					>
						{i18next.t('EDIT')}
					</Button>
					<Button
						variant='outline'
						colorScheme='red'
						leftIcon={<FiDelete />}
						mt='4'
						onClick={() => removeMatch(match.id)}
					>
						{i18next.t('DELETE')}
					</Button>
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
}

const projectTag = (
	<Tag ml='1' colorScheme='yellow'>
		Project
	</Tag>
);
const groupTag = (
	<Tag ml='1' colorScheme='green'>
		Group
	</Tag>
);
const systemTag = (
	<Tag ml='1' colorScheme='purple'>
		System
	</Tag>
);

const gitlab2Events = [
	{
		value: '',
		label: <>All events</>,
	},
	{
		value: 'Push Hook',
		label: (
			<>
				Push {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Tag Push Hook',
		label: (
			<>
				Tag {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Issue Hook',
		label: (
			<>
				Issue {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Note Hook',
		label: (
			<>
				Comment {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Merge Request Hook',
		label: (
			<>
				Merge Request {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Wiki Page Hook',
		label: (
			<>
				Wiki Page {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Pipeline Hook',
		label: (
			<>
				Pipeline {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Job Hook',
		label: (
			<>
				Job {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Deployment Hook',
		label: (
			<>
				Deployment {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Member Hook',
		label: <>Group Member {groupTag}</>,
	},
	{
		value: 'Subgroup Hook',
		label: <>Subgroup {groupTag}</>,
	},
	{
		value: 'Feature Flag Hook',
		label: (
			<>
				Feature Flag {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Release Hook',
		label: (
			<>
				Release {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'Emoji Hook',
		label: (
			<>
				Emoji {projectTag} {groupTag}
			</>
		),
	},
	{
		value: 'System Hook',
		label: <>System {systemTag}</>,
	},
];

export default Gitlab2Match;
