import { Input } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import useErrorToast from '../hooks/useErrorToast';
import { useBeaconSerialInterface } from '../hooks/useSerialInterface';

function BeaconConsole(): JSX.Element {
	const toastError = useErrorToast();
	const beaconSerialInterface = useBeaconSerialInterface();
	const [logs, setLogs] = useState(
		'Welcome to Blinky serial console!\nNote: you can navigate through commands history with arrow keys.\n\n',
	);
	const [commandsHistory, setCommandsHistory] = useState<Array<string>>([]);
	const [commandsHistoryIndex, setCommandsHistoryIndex] = useState(-1);
	const input = useRef<HTMLInputElement>(null);

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.key) {
			case 'Enter':
				const command = e.currentTarget.value;
				runCommand(command);

				if (command !== commandsHistory[0] && command.length > 0) {
					setCommandsHistory((h) => [command, ...h]);
				}

				e.currentTarget.value = '';

				setCommandsHistoryIndex(-1);
				break;
			case 'ArrowUp':
				setCommandsHistoryIndex((i) => Math.min(i + 1, commandsHistory.length - 1));
				break;
			case 'ArrowDown':
				setCommandsHistoryIndex((i) => Math.max(i - 1, -1));
				break;
		}
	};

	const runCommand = async (command: string) => {
		if (!beaconSerialInterface) {
			toastError('Beacon not available', 'Please wait or retry.');
			return;
		}

		setLogs((h) => `${h}> ${command}\n`);

		try {
			const response = await beaconSerialInterface.sendCommand(command);
			setLogs((h) => h + response + (response === '' ? '' : '\n'));
		} catch (e) {
			if (e instanceof Error) {
				const message = e.message;
				setLogs((h) => `${h}ERROR: ${message}\n`);
			} else {
				console.error(e);
			}
		}

		input.current?.scrollIntoView();
	};

	useEffect(() => {
		if (!beaconSerialInterface) {
			return () => {};
		}

		window.showBeaconLogs = true;
		runCommand('help');

		return () => beaconSerialInterface.disconnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beaconSerialInterface]);

	useEffect(() => {
		if (!input.current) {
			return;
		}
		if (commandsHistoryIndex === -1) {
			input.current.value = '';
		} else {
			input.current.value = commandsHistory[commandsHistoryIndex];
		}
	}, [commandsHistoryIndex, commandsHistory]);

	return (
		<>
			<pre
				style={{
					overflow: 'auto',
					fontSize: 'small',
				}}
			>
				{logs}
			</pre>
			{beaconSerialInterface ? <Input type='text' ref={input} onKeyDown={onKeyDown} /> : null}
		</>
	);
}

export default BeaconConsole;
