import i18next from 'i18next';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';

type PromptProps = {
	title: string;
	inputLabel: string;
	placeholder: string;
	okButtonText: string;
	loading: boolean;
	disclosure: ReturnType<typeof useDisclosure>;
	type?: string;
	initialValue?: string;
	handleSubmit: (value: string) => void;
};

function Prompt({
	title,
	inputLabel,
	placeholder,
	okButtonText,
	loading,
	disclosure,
	type,
	handleSubmit,
	initialValue,
}: PromptProps): JSX.Element {
	const [showPassword, setShowPassword] = useState(false);

	const input = (field: InputProps) =>
		type === 'password' ? (
			<InputGroup size='md'>
				<Input
					id='value'
					pr='4.5rem'
					type={showPassword ? 'text' : 'password'}
					placeholder={placeholder}
					autoComplete='none'
					{...field}
				/>
				<InputRightElement width='4.5rem'>
					<Button h='1.75rem' size='sm' onClick={() => setShowPassword((show) => !show)}>
						{showPassword ? 'Hide' : 'Show'}
					</Button>
				</InputRightElement>
			</InputGroup>
		) : (
			<Input id='value' placeholder={placeholder} autoComplete='none' type={type} {...field} />
		);

	return (
		<Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<Formik onSubmit={({ value }) => handleSubmit(value)} initialValues={{ value: initialValue || '' }}>
					<Form>
						<ModalBody>
							<Field name='value'>
								{({ field, form }: FieldProps) => (
									<FormControl isRequired isInvalid={Boolean(form.errors.value && form.touched.value)}>
										<FormLabel htmlFor='value'>{inputLabel}</FormLabel>
										{input(field)}
										<FormErrorMessage>{form.errors.value as string}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
						</ModalBody>
						<ModalFooter>
							<Button variant='ghost' mr={3} onClick={disclosure.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='blue' type='submit' isLoading={loading}>
								{okButtonText}
							</Button>
						</ModalFooter>
					</Form>
				</Formik>
			</ModalContent>
		</Modal>
	);
}

export default Prompt;
