import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

type UseCallbackWithLoadingReturn<Callback extends AnyFunction> = Callback & {
	loading: boolean;
};

function useCallbackWithLoading<Callback extends AnyFunction>(
	callback: Callback,
): UseCallbackWithLoadingReturn<Callback> {
	const [loading, setLoading] = useState(false);

	const fn = (...args: Parameters<Callback>) => {
		setLoading(true);
		const result = callback(...args);

		if (result instanceof Promise) {
			result.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}

		return result;
	};

	fn.loading = loading;
	return fn as UseCallbackWithLoadingReturn<Callback>;
}

export default useCallbackWithLoading;
