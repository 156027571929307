import { Button, Flex, Input } from '@chakra-ui/react';
import { FiDelete, FiPlus, FiSave } from 'react-icons/fi';
import { useState } from 'react';

type InputListProps = {
	defaultValue: string[];
	handleSave: (values: string[]) => Promise<unknown>;
};

function InputList({ defaultValue, handleSave }: InputListProps): JSX.Element {
	const [values, setValues] = useState<string[]>(defaultValue);
	const [dirty, setDirty] = useState(false);
	const [saving, setSaving] = useState(false);

	const addValue = () => {
		setValues([
			...values,
			'',
		]);
		setDirty(true);
	};

	const removeValue = (index: number) => {
		values.splice(index, 1);
		setValues([...values]);
		setDirty(true);
	};

	const handleInputChange = (index: number, value: string) => {
        values[index] = value;

		setValues([...values]);
		setDirty(true);
	};

	const save = async () => {
		setSaving(true);
		await handleSave(values);
		setSaving(false);
		setDirty(false);
	};

	return (
		<>
			{values.map((value, index) => (
				<Flex alignItems='center' mb='2' key={index}>
					<Input
						placeholder='Tag (Regular expressions are supported)'
						value={value}
						onChange={(event) => handleInputChange(index, event.target.value)}
						_placeholder={{
							color: 'whiteAlpha.600',
						}}
					/>
					<Flex>
						<Button colorScheme='red' ml='2' onClick={() => removeValue(index)}>
							<FiDelete />
						</Button>
					</Flex>
				</Flex>
			))}

			<Button leftIcon={<FiPlus />} onClick={addValue}>
				Add value
			</Button>
			{dirty && (
				<Button colorScheme='blue' leftIcon={<FiSave />} ml='2' onClick={save} isLoading={saving}>
					Save
				</Button>
			)}
		</>
	);
}

export default InputList;
