import i18next from 'i18next';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	Heading,
	Icon,
	useDisclosure,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiFolder } from 'react-icons/fi';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorComponent from '../components/core/Error';
import LinkedTargetList from '../components/group/LinkedTargetList';
import Loading from '../components/core/Loading';
import Prompt from '../components/layout/Prompt';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';

function Group(): JSX.Element {
	const { id } = useParams();
	const { organization } = useSession();
	const navigate = useNavigate();
	const renameModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const {
		queries: [group, targets],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrieveGroupTarget, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
	);

	const unlinkedTargets = useMemo(
		() =>
			(targets.data?.results || []).filter(
				(target) => !group.data?.targets?.find((linkedTarget) => target.id === linkedTarget.id),
			),
		[group, targets],
	);

	const editGroup = useCallbackWithLoading(async (name: string) => {
		try {
			await api.patchGroupTarget(Number(id), organization.id, { name });
		} finally {
			refetch();
			renameModal.onClose();
		}
	});

	const handleDelete = useCallbackWithLoading(async () => {
		try {
			await api.destroyGroupTarget(Number(id), organization.id);
			navigate('/groups');
		} finally {
			deleteModal.onClose();
		}
	});

	const linkTarget = useCallbackWithLoading(async (newId: number) => {
		try {
			await api.linkToGroupTarget(Number(id), organization.id, newId.toString());
		} finally {
			refetch();
		}
	});

	const unlinkTarget = useCallbackWithLoading(async (removeId: number) => {
		try {
			await api.unlinkFromGroupTarget(Number(id), organization.id, removeId.toString());
		} finally {
			refetch();
		}
	});

	useEffect(refetch, [id]);

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <ErrorComponent fullscreen error={error} />;
	}

	const { name } = group.data!;
	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' mb='4'>
				<Flex alignItems='center'>
					<Heading size='lg'>
						<Icon as={FiFolder} mr='2' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<Flex>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={renameModal.onOpen}>
						{i18next.t('RENAME')}
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						{i18next.t('DELETE')}
					</Button>
				</Flex>
			</Flex>

			<LinkedTargetList
				linkedTargets={group.data!.targets!}
				allTargets={unlinkedTargets}
				linkTarget={linkTarget}
				unlinkTarget={unlinkTarget}
			/>

			<Prompt
				title={i18next.t('GROUP_RENAME_TITLE')}
				inputLabel={i18next.t('GROUP_RENAME_INPUT_LABEL')}
				okButtonText={i18next.t('RENAME')}
				placeholder={i18next.t('GROUP_RENAME_INPUT_PLACEHOLDER')}
				loading={editGroup.loading}
				handleSubmit={editGroup}
				disclosure={renameModal}
				initialValue={name}
			/>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('GROUP_DELETE_TITLE')}
						</AlertDialogHeader>

						<AlertDialogBody>{i18next.t('GROUP_DELETE_TIP')}</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' ml={3} onClick={handleDelete} isLoading={handleDelete.loading}>
								{i18next.t('DELETE')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default Group;
