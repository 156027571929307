import i18next from 'i18next';
import { AiOutlineAlert } from 'react-icons/ai';
import { Box, BoxProps, CloseButton, Flex, Icon } from '@chakra-ui/react';
import { FiBookmark, FiFolder, FiLayers, FiZap } from 'react-icons/fi';

import LanguageSelect from '../core/LanguageSelect';
import Logo from '../core/Logo';
import SidebarItem from './SidebarItem';
import Version from '../core/Version';
import useSession from '../../hooks/useSession';

type SidebarProps = BoxProps & {
	onClose: () => void;
};

function Sidebar({ onClose, ...rest }: SidebarProps): JSX.Element {
	const { organization } = useSession();

	const orgButton = {
		title: i18next.t('SIDEBAR_MENU_ORGANIZATION'),
		link: '/organization',
		icon: <Icon mr='4' fontSize='16' _groupHover={{ color: 'white' }} as={FiBookmark} />,
	};

	const menu = [
		{
			title: i18next.t('SIDEBAR_MENU_BEACONS'),
			link: '/beacons',
			icon: <Icon ml='-1px' mr='14px' fontSize='18' as={AiOutlineAlert} />,
		},
		{
			title: i18next.t('SIDEBAR_MENU_INTEGRATIONS'),
			link: '/integrations',
			icon: <Icon mr='4' fontSize='16' _groupHover={{ color: 'white' }} as={FiLayers} />,
		},
		{
			title: i18next.t('SIDEBAR_MENU_GROUPS'),
			link: '/groups',
			icon: <Icon mr='4' fontSize='16' _groupHover={{ color: 'white' }} as={FiFolder} />,
		},
		{
			title: i18next.t('SIDEBAR_MENU_PATTERNS'),
			link: '/patterns',
			icon: <Icon mr='4' fontSize='16' _groupHover={{ color: 'white' }} as={FiZap} />,
		},
		orgButton,
	];

	const completeMenu = [...(organization.id ? menu : [orgButton])];
	return (
		<Box
			w={{
				base: 'full',
				md: 60,
			}}
			h='full'
			pos='fixed'
			transition='3s ease'
			bg='gray.900'
			borderRight='1px'
			borderRightColor='gray.700'
			zIndex={1000}
			{...rest}
		>
			<Flex direction='column' justifyContent='space-between' h='100%'>
				<Box>
					<Flex h='60' alignItems='center' justifyContent='space-between' mx='8'>
						<Logo />
						<CloseButton
							onClick={onClose}
							display={{
								base: 'flex',
								md: 'none',
							}}
						/>
					</Flex>
					{completeMenu.map((item) => (
						<SidebarItem key={item.title} {...item} />
					))}
				</Box>
				<Flex direction='column' justifyContent='flex-start' alignItems='flex-start'>
					<Version />
				</Flex>
			</Flex>
		</Box>
	);
}

export default Sidebar;
