import i18next from 'i18next';
import { AiOutlineAlert } from 'react-icons/ai';
import { Box, Button, Flex, Heading, Icon, Tag } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MouseEvent } from 'react';

import Card from '../core/Card';
import { DetailedGroupTarget } from '../../api/client';

type GroupProps = {
	group: DetailedGroupTarget;
	unlink?: (id: number) => void;
};

function Group({ group: { id, name, targets, owner }, unlink }: GroupProps): JSX.Element {
	const handleUnlink = (event: MouseEvent) => {
		event.stopPropagation();
		unlink?.(id);
	};

	return (
		<Card overflow='hidden'>
			<Flex justifyContent='space-between' alignItems='center' w='100%'>
				<Box>
					<Flex direction='column'>
						<Flex alignItems='center' mb='2'>
							<Icon mr='1' fontSize={19} as={AiOutlineAlert} />
							<Link to={`/groups/${id}`}>
								<Button variant='link'>
									<Heading size='sm' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
										{name}
										{targets && (
											<>
												({targets.length || 0} target{targets.length === 1 ? '' : 's'})
											</>
										)}
									</Heading>
								</Button>
							</Link>
						</Flex>
						<Flex alignItems='center'>
							<Tag bg='blue.200'>{i18next.t('GROUP')}</Tag>
						</Flex>
					</Flex>
				</Box>
				<Flex alignItems='center'>
					{unlink && (
						<Button variant='link' mr='4' onClick={handleUnlink}>
							{i18next.t('UNLINK')}
						</Button>
					)}
				</Flex>
			</Flex>
		</Card>
	);
}

export default Group;
