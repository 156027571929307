import i18next from 'i18next';
import {
	Box,
	Flex,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Tag,
	Text,
	VStack,
} from '@chakra-ui/react';
import { FiChevronDown, FiMenu } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import LanguageSelect from '../core/LanguageSelect';
import Logo from '../core/Logo';
import OrganizationSelect from '../organization/OrganizationSelect';
import useSession from '../../hooks/useSession';
import { deleteFromLocalStorage, deleteFromSessionStorage } from '../../utility/storage';

type HeaderProps = {
	onOpen: () => void;
};

function Header({ onOpen }: HeaderProps): JSX.Element {
	const navigate = useNavigate();
	const { user } = useSession();

	const logout = () => {
		deleteFromSessionStorage('access');
		deleteFromLocalStorage('refresh');
		deleteFromLocalStorage('organization-id');
		deleteFromLocalStorage('organization-name');
		navigate('/login');
	};

	return (
		<>
			<Flex
				height='20'
				alignItems='center'
				bg='gray.900'
				borderBottomWidth='1px'
				borderBottomColor='gray.700'
				justifyContent={{
					base: 'space-between',
				}}
				ml={{
					base: 0,
					md: 60,
				}}
				px={{
					base: 4,
					md: 4,
				}}
			>
				<IconButton
					variant='outline'
					aria-label='open menu'
					icon={<FiMenu />}
					display={{
						base: 'flex',
						md: 'none',
					}}
					onClick={onOpen}
				/>
				<Box
					display={{
						base: 'block',
						md: 'none',
					}}
				>
					<Logo />
				</Box>

				<Flex>
					<OrganizationSelect />
				</Flex>

				<Flex>
					{/* <LanguageSelect /> */}

					{user?.id !== 0 && (
						<Flex alignItems='center'>
							<Menu>
								<MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
									<HStack>
										<VStack
											alignItems='flex-end'
											spacing='1px'
											px='1'
											display={{
												base: 'none',
												md: 'flex',
											}}
										>
											<Text fontSize='sm'>
												{user.username}
												{user.isStaff && (
													<Tag size='sm' colorScheme='blue' ml='1'>
														Staff
													</Tag>
												)}
											</Text>
											<Text fontSize='xs' color='gray.600'>
												{user.email}
											</Text>
										</VStack>
										<Box
											display={{
												base: 'none',
												md: 'flex',
											}}
										>
											<FiChevronDown />
										</Box>
									</HStack>
								</MenuButton>
								<MenuList bg='gray.900' borderColor='gray.700'>
									<Link to='/login/update-password'>
										<MenuItem>{i18next.t('UPDATE_PASSWORD_TITLE')}</MenuItem>
									</Link>
									<MenuItem onClick={logout} color='red.500'>
										{i18next.t('HEADER_SIGN_OUT')}
									</MenuItem>
								</MenuList>
							</Menu>
						</Flex>
					)}
				</Flex>
			</Flex>
		</>
	);
}

export default Header;
