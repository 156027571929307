import { Message } from "./SerialInterface";

class MicropythonSerialTransformer implements Transformer<Uint8Array, Message> {
	container: Array<number>;

	decoder: TextDecoder;

	rawMode: Boolean = false;

	constructor() {
		this.container = [];
		this.decoder = new TextDecoder('utf-8');
	}

	transform(chunk: Uint8Array, controller: TransformStreamDefaultController<Message>): void {
		this.container = this.container.concat([].slice.call(chunk));
		if (window.showBeaconLogs) {
			console.warn('SERIAL:', this.decoder.decode(Uint8Array.from(chunk)));
		}

		if (this.container.slice(-1)[0] === '>'.charCodeAt(0)) {
			const response = this.decoder.decode(Uint8Array.from(this.container));
			if (this.rawMode) {
				const status = response.slice(0, 2);
				controller.enqueue({
					status: status === 'OK' ? 'OK' : 'ERROR',
					data: response.slice(2, -5),
				});
			} else {
				this.rawMode = true;
				controller.enqueue({ status: 'OK' });
			}
			if (window.showBeaconLogs) {
			}
			this.container = [];
		}
	}
}

export default MicropythonSerialTransformer;
