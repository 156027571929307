import { Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input, Switch, Tooltip } from '@chakra-ui/react';

import PatternSelect from '../../pattern/PatternSelect';
import { SimplePattern } from '../../../api/client';

export type StatusCakeReactionFormProps = {
	patterns?: SimplePattern[];
};

function StatusCakeReactionForm({ patterns }: StatusCakeReactionFormProps): JSX.Element {
	return (
		<>
			<Field name='name'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.name && form.touched.name)}>
						<FormLabel htmlFor='name'>Name</FormLabel>
						<Input id='name' placeholder='My StatusCake Reaction' autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name='minimumInterval'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.minimumInterval && form.touched.minimumInterval)}>
						<FormLabel htmlFor='minimumInterval' mt='3'>
							Deduplication interval of orders (seconds)
						</FormLabel>
						<Input id='minimumInterval' placeholder='60' autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.minimumInterval as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			{patterns && <PatternSelect required name='patternId' label='Pattern' patterns={patterns} />}

			<Field name='isFiredOnResolve'>
				{({ field: { onChange, ...field }, form }: FieldProps) => (
					<FormControl isInvalid={Boolean(form.errors.isFiredOnResolve && form.touched.isFiredOnResolve)}>
						<FormLabel htmlFor='isFiredOnResolve' mt='3'>
							Trigger on alert resolution instead of fire <Tooltip label='Only valid for "Uptime" and "SSL" alerts'>ⓘ</Tooltip>
						</FormLabel>
						<Switch
							id='isFiredOnResolve'
							defaultChecked={field.value}
							onChange={() => form.setFieldValue('isFiredOnResolve', !field.value)}
							{...field}
						/>
						<FormErrorMessage>{form.errors.isFiredOnResolve as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default StatusCakeReactionForm;
