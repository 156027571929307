function useAggregatedQuery<T extends unknown[]>(
	...queries: T
): {
	queries: {
		[key in keyof T]: T[key];
	};
	loading: boolean;
	refetch: () => void;
	error?: Error;
} {
	return {
		queries,
		// @ts-expect-error
		loading: queries.some((query) => query.loading),
		// @ts-expect-error
		refetch: () => queries.forEach((query) => query.refetch()),
		// @ts-expect-error
		error: queries.find((query) => query.error)?.error,
	};
}

export default useAggregatedQuery;
