import { Button, Text } from '@chakra-ui/react';
import CopiableCode from '../../core/CopiableCode';

import alertConfigExample from './alertConfigExample.png';
import webhookConfigExample from './webhookConfigExample.png';

type ObserviumInstructionsProps = {
	url: string;
};

function ObserviumInstructions({ url }: ObserviumInstructionsProps): JSX.Element {
	return (
		<>
			<Text mt='2'>
				To{' '}
				<Button
					variant='link'
					onClick={() => window.open('https://docs.observium.org/alerting_transports/')}
					colorScheme='blue'
				>
					send alert
				</Button>{' '}
				to Blinky from Observium, you need as Observium Administator to add a contact (Main Menu → Contacts, then "Add
				Contact" on top right).
			</Text>
			<Text mt='2'>
				Choose the <b>Webhook</b> transport and use the following <b>URL</b> as parameter:
				<CopiableCode bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mt='2' mb='4' display='flex'>
					{url}
				</CopiableCode>
			</Text>
			<img src={webhookConfigExample} alt='Webhook config example' />
			<Text mt='3' mb='2'>
				Don't forget to associate some{' '}
				<Button
					variant='link'
					onClick={() => window.open('https://docs.observium.org/alert_checker/')}
					colorScheme='blue'
				>
					Alert checkers
				</Button>{' '}
				(or{' '}
				<Button
					variant='link'
					onClick={() => window.open('https://docs.observium.org/alerting_syslog/')}
					colorScheme='blue'
				>
					Syslog rules
				</Button>
				) to the contact in order to trigger Blinky.
			</Text>
			<img src={alertConfigExample} alt='Alert config example' />
			<Text mt='3'>
				If you don't have any Alert checkers yet, you could add some from{' '}
				<Button
					variant='link'
					onClick={() => window.open('https://docs.observium.org/alerting_examples/')}
					colorScheme='blue'
				>
					Observium documentation
				</Button>
				.
			</Text>
		</>
	);
}

export default ObserviumInstructions;
