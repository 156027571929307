import { AxiosError } from 'axios';
import { Box, Code, Text } from '@chakra-ui/react';

import { toast } from '../config/theme';

type ApiErrorDetail = {
	code: string;
	detail: string;
	attr?: string;
};

const errorToastProps = {
	duration: 5000,
	isClosable: true,
	position: 'top',
	status: 'error',
} as const;

class ApiError extends Error {
	type: 'validation_error' | 'client_error' | 'server_error' | 'network_error' = 'network_error';

	errors: ApiErrorDetail[] = [];

	protected id = Math.random();

	constructor(public underlyingError: unknown) {
		super(ApiError.formatMessage(underlyingError));

		if (underlyingError instanceof ApiError) {
			this.id = underlyingError.id;
			this.type = underlyingError.type;
			this.errors = underlyingError.errors;
		} else if (ApiError.isServerError(underlyingError)) {
			// @ts-expect-error
			if (underlyingError.response!.data.type) {
				// @ts-expect-error
				this.type = underlyingError.response!.data.type;
				// @ts-expect-error
				this.errors = underlyingError.response!.data.errors;
			} else {
				this.type = 'server_error';
				this.errors.push({
					code: 'internal_server_error',
					detail: ApiError.formatMessage(underlyingError),
				});
			}
		} else if (underlyingError instanceof Error) {
			this.errors.push({
				code: underlyingError.name,
				detail: underlyingError.message,
			});
		}
	}

	getTitle(index: number): JSX.Element | string {
		switch (this.type) {
			case 'network_error':
				return 'Network Error';

			case 'server_error':
				return 'Internal Server Error';

			case 'client_error':
				return 'Invalid Request';

			case 'validation_error':
				return this.errors[index].attr === 'non_field_errors' ? (
					'Invalid request'
				) : (
					<BrowserApiErrorTitle {...this.errors[index]} />
				);
		}
	}

	containsCode(code: string): boolean {
		return this.errors.some((error) => error.code === code);
	}

	private static formatMessage(underlyingError: unknown): string {
		if (underlyingError instanceof ApiError) {
			return ApiError.formatMessage(underlyingError.underlyingError);
		}

		if (ApiError.isServerError(underlyingError)) {
			const { data } = underlyingError.response!;

			// @ts-expect-error
			if (!data.errors) {
				return 'The server response was not a valid JSON. Most likely an internal error occured.';
			}

			// @ts-expect-error
			return `${data.errors[0].attr}: ${data.errors[0].detail}`;
		}

		if (underlyingError instanceof Error) {
			return underlyingError.message;
		}

		throw new Error(`Developer error: Unknown error type "${underlyingError}"`);
	}

	private static isServerError(error: unknown): error is AxiosError {
		return ApiError.isAxiosError(error) && error.response !== undefined;
	}

	private static isAxiosError(error: unknown): error is AxiosError {
		// @ts-expect-error
		return 'response' in error;
	}
}

class BrowserApiError extends ApiError {
	toastAll(): void {
		this.errors.forEach((error, index) => this.toast(index));
	}

	ignore(code: string): void {
		this.errors.forEach((error, index) => {
			if (error.code === code) {
				toast.toast.update(`api-error-${this.id}-${index}`, {
					duration: 0,
				});
			}
		});
	}

	reword(code: string, callback: (err: ApiErrorDetail) => { title: string; message: string }): void {
		this.errors.forEach((error, index) => {
			if (error.code === code) {
				const { title, message } = callback(error);
				toast.toast.update(`api-error-${this.id}-${index}`, {
					title,
					description: message,
					...errorToastProps,
				});
			}
		});
	}

	private toast(index: number): void {
		const error = this.errors[index];
		toast.toast({
			title: this.getTitle(index),
			description: error.detail,
			id: `api-error-${this.id}-${index}`,
			...errorToastProps,
		});
	}
}

function BrowserApiErrorTitle({ detail, attr }: ApiErrorDetail): JSX.Element {
	return (
		<Box>
			{attr ? (
				<Text>
					Invalid field
					<Code color='black' ml='1'>
						{attr}
					</Code>
				</Text>
			) : (
				<Text>{detail}</Text>
			)}
		</Box>
	);
}

export { ApiError, BrowserApiError, errorToastProps };
