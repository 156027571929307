import i18next from 'i18next';
import { Box, Button, Divider, Flex, Heading, Icon, Input, Text } from '@chakra-ui/react';
import { CgColorBucket, CgErase } from 'react-icons/cg';
import {
	FiArrowDown,
	FiArrowLeft,
	FiArrowRight,
	FiArrowUp,
	FiChevronRight,
	FiCopy,
	FiDelete,
	FiPenTool,
	FiPlus,
} from 'react-icons/fi';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { RGBColor, SketchPicker } from 'react-color';
import { TiPipette } from 'react-icons/ti';

import Loading from '../core/Loading';
import PatternFrame from './PatternFrame';
import PatternSpec from './PatternSpec';
import Tooltip from '../core/Tooltip';
import { Color } from '../../utility/color';

type PatternEditorProps = {
	value?: PatternSpec;
	setValue: (value: PatternSpec) => void;
};

function PatternEditor({ value, setValue }: PatternEditorProps): JSX.Element {
	const [focusedDurationInputIndex, setFocusedDurationInputIndex] = useState(-1);
	const [color, setColor] = useState<RGBColor>({
		r: 0,
		g: 0,
		b: 0,
	});

	const tools = useMemo(
		() => ({
			pen: (leds: Color[], index: number) => {
				leds[index] = color;
				return leds;
			},
			bucket: (leds: Color[]) => leds.map(() => color),
			pipette: (leds: Color[], index: number) => {
				setColor(leds[index]);
				setTool('pen');
				return leds;
			},
		}),
		[color],
	);
	const [tool, setTool] = useState<keyof typeof tools>('pen');

	const addFrame = () =>
		setValue(
			new PatternSpec({
				...value,
				frames: [
					...value!.frames,
					{
						duration: 250,
						colors: new Array(12).fill({
							r: 0,
							g: 0,
							b: 0,
						}),
					},
				],
			}),
		);

	const shiftFrameRight = (index: number) => {
		const colors = value!.frames[index].colors;
		value!.frames[index].colors = [colors[colors.length - 1], ...colors.slice(0, colors.length - 1)];
		setValue(
			new PatternSpec({
				...value,
				frames: value!.frames,
			}),
		);
	};

	const shiftFrameLeft = (index: number) => {
		const colors = value!.frames[index].colors;
		value!.frames[index].colors = [...colors.slice(1), colors[0]];
		setValue(
			new PatternSpec({
				...value,
				frames: value!.frames,
			}),
		);
	};

	const moveFrameUp = (index: number) =>
		setValue(
			new PatternSpec({
				...value,
				frames: [
					...value!.frames.slice(0, index - 1),
					value!.frames[index],
					value!.frames[index - 1],
					...value!.frames.slice(index + 1),
				],
			}),
		);

	const moveFrameDown = (index: number) =>
		setValue(
			new PatternSpec({
				...value,
				frames: [
					...value!.frames.slice(0, index),
					value!.frames[index + 1],
					value!.frames[index],
					...value!.frames.slice(index + 2),
				],
			}),
		);

	const cloneFrame = (index: number) =>
		setValue(
			new PatternSpec({
				...value,
				frames: [
					...value!.frames.slice(0, index),
					{
						duration: value!.frames[index].duration,
						colors: [...value!.frames[index].colors],
					},
					...value!.frames.slice(index),
				],
			}),
		);

	const deleteFrame = (index: number) =>
		setValue(
			new PatternSpec({
				...value,
				frames: [...value!.frames.slice(0, index), ...value!.frames.slice(index + 1)],
			}),
		);

	const setFrameDuration = (index: number, rawDuration: string) => {
		const duration = Number(rawDuration);
		if (isNaN(duration)) {
			return;
		}

		setValue(
			new PatternSpec({
				...value,
				frames: [
					...value!.frames.slice(0, index),
					{
						...value!.frames[index],
						duration: Number(duration),
					},
					...value!.frames.slice(index + 1),
				],
			}),
		);
	};

	const setRepeat = (rawRepeat: string) => {
		const repeat = Number(rawRepeat);
		if (isNaN(repeat)) {
			return;
		}

		setValue(
			new PatternSpec({
				...value,
				repeat: Number(repeat),
			}),
		);
	};

	const applyTool = (frameIndex: number, ledIndex: number) => {
		const colors = tools[tool](value!.frames[frameIndex].colors, ledIndex);
		value!.frames[frameIndex].colors = colors;
		setValue(
			new PatternSpec({
				...value,
				frames: value!.frames,
			}),
		);
	};

	const setOffColor = () =>
		setColor({
			r: 0,
			g: 0,
			b: 0,
		});

	useEffect(() => {
		document.onkeydown = (event) => {
			if (event.shiftKey && event.key === 'Enter' && focusedDurationInputIndex > -1) {
				value!.frames.forEach((frame) => (frame.duration = value!.frames[focusedDurationInputIndex].duration));
				setValue(
					new PatternSpec({
						...value,
						frames: [...value!.frames],
					}),
				);
			}
		};

		return () => void (document.onkeydown = null);
	});

	if (!value) {
		return <Loading />;
	}

	return (
		<Flex justifyContent='flex-end'>
			<Flex direction='column' grow={1}>
				{value.frames.map((_, frameIndex) => (
					<Fragment key={frameIndex}>
						<Flex mt='4' alignItems='center' justifyContent='flex-end'>
							<PatternFrame
								selectable
								colors={value.frames[frameIndex].colors}
								onClick={(index) => applyTool(frameIndex, index)}
							/>
							<Divider orientation='vertical' mx='4' height='20px' />
							<Flex alignItems='center'>
								<Tooltip
									label={i18next.t('PATTERN_EDITOR_BATCH_APPLY_DURATION_TIP')}
									isOpen={focusedDurationInputIndex === frameIndex}
								>
									<Input
										mr='2'
										width='80px'
										textAlign='right'
										value={value.frames[frameIndex].duration || ''}
										onChange={(event) => setFrameDuration(frameIndex, event.target.value)}
										onFocus={(event) => setFocusedDurationInputIndex(frameIndex)}
										onBlur={() => setFocusedDurationInputIndex(-1)}
									/>
								</Tooltip>
								<Text color='gray'>ms</Text>
							</Flex>
							<Divider orientation='vertical' ml='4' mr='2' height='20px' />
							<Flex alignItems='center'>
								<Tooltip label={i18next.t('PATTERN_EDITOR_TOOLTIP_SHIFT_LEFT')}>
									<Button size='sm' variant='ghost' onClick={() => shiftFrameLeft(frameIndex)}>
										<Icon as={FiArrowLeft} />
									</Button>
								</Tooltip>
								<Tooltip label={i18next.t('PATTERN_EDITOR_TOOLTIP_SHIFT_RIGHT')}>
									<Button size='sm' variant='ghost' onClick={() => shiftFrameRight(frameIndex)}>
										<Icon as={FiArrowRight} />
									</Button>
								</Tooltip>
								<Tooltip label={i18next.t('PATTERN_EDITOR_TOOLTIP_SHIFT_UP')}>
									<Button
										size='sm'
										variant='ghost'
										onClick={() => moveFrameUp(frameIndex)}
										isDisabled={frameIndex === 0}
									>
										<Icon as={FiArrowUp} />
									</Button>
								</Tooltip>
								<Tooltip label={i18next.t('PATTERN_EDITOR_TOOLTIP_SHIFT_DOWN')}>
									<Button
										size='sm'
										variant='ghost'
										onClick={() => moveFrameDown(frameIndex)}
										isDisabled={frameIndex === value.frames.length - 1}
									>
										<Icon as={FiArrowDown} />
									</Button>
								</Tooltip>
								<Tooltip label={i18next.t('PATTERN_EDITOR_TOOLTIP_CLONE_FRAME')}>
									<Button size='sm' variant='ghost' onClick={() => cloneFrame(frameIndex)}>
										<Icon as={FiCopy} />
									</Button>
								</Tooltip>
								<Tooltip label={i18next.t('PATTERN_EDITOR_TOOLTIP_REMOVE_FRAME')}>
									<Button size='sm' variant='ghost' onClick={() => deleteFrame(frameIndex)}>
										<Icon as={FiDelete} />
									</Button>
								</Tooltip>
							</Flex>
						</Flex>
					</Fragment>
				))}

				<Button
					p='4'
					mt='6'
					border='dashed'
					borderColor='whiteAlpha.500'
					borderWidth='2px'
					borderRadius='xl'
					background='transparent'
					w='100%'
					h='auto'
					onClick={addFrame}
				>
					<Flex justifyContent='space-between' alignItems='center' w='100%'>
						<Flex alignItems='center'>
							<FiPlus />
							<Box ml='2'>{i18next.t('PATTERN_EDITOR_ADD_FRAME')}</Box>
						</Flex>
						<FiChevronRight />
					</Flex>
				</Button>
			</Flex>
			<Flex>
				<Divider orientation='vertical' my='4' mx='8' color='yellow' />
				<Flex direction='column' width='220px'>
					<Heading size='md' my='4'>
						{i18next.t('PATTERN_CONFIGURATION')}
					</Heading>
					<Tooltip label='Number of times to repeat the pattern'>
						<Box>
							<Text color='gray.500' mb='2'>
								{i18next.t('PATTERN_REPEAT_COUNT')}
							</Text>
							<Input
								textAlign='right'
								value={value!.repeat || ''}
								onChange={(event) => setRepeat(event.target.value)}
							/>
						</Box>
					</Tooltip>
					<Heading size='md' mt='8' mb='4'>
						{i18next.t('PATTERN_TOOLS')}
					</Heading>
					<Flex direction='column'>
						<Flex mb='4' justifyContent='space-between'>
							<Tooltip label={i18next.t('PATTERN_EDITOR_PEN_PLACEHOLDER')}>
								<Button mr='1' onClick={() => setTool('pen')} isActive={tool === 'pen'}>
									<FiPenTool />
								</Button>
							</Tooltip>
							<Tooltip label={i18next.t('PATTERN_EDITOR_BUCKET_PLACEHOLDER')}>
								<Button mx='1' onClick={() => setTool('bucket')} isActive={tool === 'bucket'}>
									<CgColorBucket />
								</Button>
							</Tooltip>
							<Tooltip label={i18next.t('PATTERN_EDITOR_PICKER_PLACEHOLDER')}>
								<Button mx='1' onClick={() => setTool('pipette')} isActive={tool === 'pipette'}>
									<TiPipette />
								</Button>
							</Tooltip>
							<Tooltip label={i18next.t('PATTERN_EDITOR_ERASER_PLACEHOLDER')}>
								<Button variant='outline' ml='1' onClick={setOffColor}>
									<CgErase />
								</Button>
							</Tooltip>
						</Flex>
						<Flex mt='2' justifyContent='center'>
							<SketchPicker
								color={color}
								onChangeComplete={(color) => setColor(color.rgb)}
								styles={{
									default: {
										picker: {
											background: 'rgb(23, 25, 35)',
											boxShadow: 'none',
											padding: 0,
										},
									},
								}}
							/>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default PatternEditor;
