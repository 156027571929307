import i18next from 'i18next';
import { Box, Button, Flex, Input, Select } from '@chakra-ui/react';
import { useState } from 'react';

import CreatableSelect from './CreatableSelect';
import useErrorToast from '../../hooks/useErrorToast';
import { FilterSpec } from './Filter';

type FilterEditorProps = {
	keyOptions?: string[];
	addFilter: (filter: FilterSpec) => void;
	onClose: () => void;
};

function FilterEditor({ addFilter, keyOptions, onClose }: FilterEditorProps): JSX.Element {
	const [key, setKey] = useState(
		keyOptions
			? {
					label: keyOptions[0],
					value: keyOptions[0],
			  }
			: {
					label: '',
					value: '',
			  },
	);
	const [op, setOp] = useState('eq' as 'eq' | 'neq' | 'regex' | 'nregex');
	const [value, setValue] = useState('');
	const toastError = useErrorToast();

	const add = () => {
		if (value.length < 1) {
			toastError('Invalid filter', 'Value cannot be empty.');
			return;
		}

		addFilter({
			key: key.value,
			op,
			value,
		});

		onClose();
	};

	return (
		<Box>
			<Flex>
				<CreatableSelect
					value={key}
					// @ts-expect-error
					onChange={setKey}
					options={keyOptions?.map((key) => ({
						label: key,
						value: key,
					}))}
					width={650}
				/>
				{/* @ts-expect-error */}
				<Select mx='2' maxW='140px' value={op} onChange={(event) => setOp(event.target.value)}>
					<option value='eq'>equals</option>
					<option value='neq'>not equals</option>
					<option value='regex'>regex</option>
					<option value='nregex'>not regex</option>
				</Select>
				<Input value={value} onChange={(event) => setValue(event.target.value)} />
			</Flex>

			<Flex mt='8' mb='4' justifyContent='flex-end'>
				<Button onClick={onClose}>{i18next.t('CANCEL')}</Button>
				<Button ml='2' colorScheme='blue' onClick={add}>
					{i18next.t('ADD')}
				</Button>
			</Flex>
		</Box>
	);
}

export default FilterEditor;
