import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { Api } from './client';
import { ApiError, BrowserApiError } from './error';
import {
	deleteFromLocalStorage,
	deleteFromSessionStorage,
	writeLocalStorage,
	writeSessionStorage,
} from '../utility/storage';

abstract class ApiClient extends Api<unknown> {
	constructor(...args: ConstructorParameters<typeof Api>) {
		super(...args);

		this.instance.interceptors.request.use((request) => {
			const access = this.getAccessToken();

			if (access) {
				// @ts-expect-error
				request.headers = {
					...request.headers,
					Authorization: `Bearer ${access}`,
				};
			}

			return request;
		});

		createAuthRefreshInterceptor(this.instance, async (failedRequest) => {
			const refresh = this.getRefreshToken();
			if (!refresh) {
				this.setAccessToken(null);
				this.presentLogin();
				throw failedRequest;
			}

			try {
				const { access, refresh: newRefresh } = await this.api.apiV1AuthTokenRefreshCreate(
					{ refresh },
					// @ts-expect-error
					{ skipAuthRefresh: true },
				);
				this.setAccessToken(access);
				this.setRefreshToken(newRefresh);
				failedRequest.response.config.headers.Authorization = `Bearer ${access}`;
			} catch (error) {
				this.setAccessToken(null);
				this.setRefreshToken(null);
				this.presentLogin();
				throw failedRequest;
			}
		});
	}

	async login(credentials: Parameters<typeof this.api.apiV1AuthLoginCreate>[0]) {
		const { accessToken, refreshToken } = await this.do(this.api.apiV1AuthLoginCreate, credentials, {
			// @ts-expect-error
			skipAuthRefresh: true,
		});

		this.setAccessToken(accessToken);
		this.setRefreshToken(refreshToken);
	}

	protected abstract getAccessToken(): string | null;

	protected abstract setAccessToken(token: string | null): void;

	protected abstract getRefreshToken(): string | null;

	protected abstract setRefreshToken(token: string | null): void;

	protected abstract presentLogin(): void;

	protected static createFromImpl<Client extends new (...args: ConstructorParameters<Client>) => ApiClient>(
		Impl: Client,
		...args: ConstructorParameters<Client>
	): ApiClient {
		return new Proxy(new Impl(...args), {
			get(target, property) {
				if (property in target.api) {
					// @ts-expect-error
					return (...args) => target.do(target.api[property], ...args);
				}

				// @ts-expect-error
				return target[property];
			},
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected async do<Call extends (...args: any[]) => Promise<any>>(
		call: Call,
		...args: Parameters<Call>
	): Promise<Awaited<ReturnType<Call>>> {
		try {
			return await call(...args);
		} catch (error) {
			throw new ApiError(error);
		}
	}
}

class BrowserApiClient extends ApiClient {
	static create(...args: ConstructorParameters<typeof ApiClient>): BrowserApiClient & typeof Api.prototype.api {
		// @ts-expect-error
		return ApiClient.createFromImpl(BrowserApiClient, ...args) as BrowserApiClient & typeof Api.prototype.api;
	}

	protected getAccessToken(): string | null {
		return sessionStorage.getItem('access');
	}

	protected setAccessToken(token: string | null): void {
		if (!token) {
			deleteFromSessionStorage('access');
			return;
		}

		writeSessionStorage('access', token);
	}

	protected getRefreshToken(): string | null {
		return localStorage.getItem('refresh');
	}

	protected setRefreshToken(token: string | null): void {
		if (!token) {
			deleteFromLocalStorage('refresh');
			return;
		}

		writeLocalStorage('refresh', token);
	}

	protected presentLogin(): void {
		window.location.replace('/login');
	}

	protected constructor(...args: ConstructorParameters<typeof ApiClient>) {
		super(...args);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected async do<Call extends (...args: any) => Promise<any>>(
		call: Call,
		...args: Parameters<Call>
	): Promise<Awaited<ReturnType<Call>>> {
		try {
			return await super.do(call, ...args);
		} catch (err) {
			const error = new BrowserApiError(err);
			error.toastAll();
			throw error;
		}
	}
}

const client = BrowserApiClient.create({
	baseURL: process.env.REACT_APP_API_URL || '',
});

export default client;
