import jwt_decode from 'jwt-decode';
import { useMemo } from 'react';

import { DetailedUser, SimpleOrganization } from '../api/client';
import { useLocalStorage } from './useStorage';

export type Session = {
	user: DetailedUser;
	organization: SimpleOrganization;
};

function useSession(): Session {
	const jwt = useLocalStorage('refresh');
	const organizationId = useLocalStorage('organization-id');
	const organizationName = useLocalStorage('organization-name');

	return useMemo(() => {
		if (!jwt) {
			return {
				user: {
					id: 0,
					username: 'Anonymous User',
				},
				organization: {
					id: Number(organizationId) || 0,
					name: organizationName || 'Anonymous Organization',
				},
			};
		}

		// @ts-expect-error
		const { user } = jwt_decode(jwt);
		return {
			user,
			organization: {
				id: Number(organizationId) || 0,
				name: organizationName,
			},
		};
	}, [jwt, organizationId, organizationName]) as Session;
}

export default useSession;
