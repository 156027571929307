import i18next from 'i18next';
import { AiOutlineAlert } from 'react-icons/ai';
import { Button, Flex, Grid, Heading } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import Beacon from '../components/beacon/Beacon';
import Empty from '../components/core/Empty';
import Error from '../components/core/Error';
import Loading from '../components/core/Loading';
import api from '../api';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';

function Beacons(): JSX.Element {
	const navigate = useNavigate();
	const { organization } = useSession();
	const { data, loading, error } = useQuery(api.listBeacons, organization.id);

	if (error) {
		return <Error fullscreen error={error} />;
	}
	if (loading || !data) {
		return <Loading />;
	}

	return (
		<>
			{data.results!.length === 0 ? (
				<Empty
					title={i18next.t('BEACONS_EMPTY_TITLE')}
					action={i18next.t('BEACONS_ASSOCIATE')}
					// @ts-ignore
					icon={AiOutlineAlert}
					onCreate={() => navigate('/beacons/configure/instructions')}
				>
					{i18next.t('BEACONS_ASSOCIATE_TIP')}
				</Empty>
			) : (
				<>
					<Flex justifyContent='space-between' alignItems='flex-end' pb='2'>
						<Heading size='lg'>{i18next.t('BEACONS_TITLE')}</Heading>
						<Link to='/beacons/configure/instructions'>
							<Button colorScheme='blue' leftIcon={<FiPlus />}>
								{i18next.t('BEACONS_ASSOCIATE')}
							</Button>
						</Link>
					</Flex>
					<Grid templateColumns='repeat(3, 1fr)' gap={6} mt='4'>
						{data.results!.map((beacon) => (
							<Beacon beacon={beacon} key={beacon.id} />
						))}
					</Grid>
				</>
			)}
		</>
	);
}

export default Beacons;
