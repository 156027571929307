import axios from 'axios';
import i18next from 'i18next';
import {
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../components/core/Loading';
import Prompt from '../components/layout/Prompt';
import WifiButton from '../components/serial/WifiButton';
import api from '../api';
import useErrorToast from '../hooks/useErrorToast';
import useSession from '../hooks/useSession';
import { BrowserApiError } from '../api/error';
import { WifiAccessPoint } from '../components/serial/BeaconSerialInterface';
import { getHost } from '../utility/host';
import { useBeaconSerialInterface } from '../hooks/useSerialInterface';

function WifiConfiguration(): JSX.Element {
	const toast = useToast();
	const { organization } = useSession();
	const navigate = useNavigate();
	const ssidPrompt = useDisclosure();
	const passwordPrompt = useDisclosure();
	const toastError = useErrorToast();
	const beaconSerialInterface = useBeaconSerialInterface();
	const [beaconNetworkId, setBeaconNetworkId] = useState<string>();
	const [beaconId, setBeaconId] = useState(0);
	const [loading, setLoading] = useState(true);
	const [wifis, setWifis] = useState<WifiAccessPoint[]>([]);
	const [firstSearchComplete, setFirstSearchComplete] = useState(false);
	const [selectedWifiSSID, setSelectedWifiSSID] = useState<string>();
	const [isSearching, setIsSearching] = useState(false);
	const isWifiConfiguredRef = useRef(false);
	const { expectedBeaconNetworkId } = useParams();

	const saveWifiConfiguration = async (ssid: string, password?: string) => {
		if (!beaconSerialInterface) {
			return;
		}

		setLoading(true);
		passwordPrompt.onClose();

		const { data } = await axios.get(`${getHost()}/api/v1/broker`);
		await beaconSerialInterface.setConfig('server_address', data.url);
		await beaconSerialInterface.setConfig('ssl_enabled', data.sslEnabled);
		await beaconSerialInterface.setConfig('wifi_ssid', ssid);

		if (password) {
			await beaconSerialInterface.setConfig('wifi_password', password);
		} else {
			await beaconSerialInterface.setConfig('wifi_password', '');
		}

		try {
			await beaconSerialInterface.commitConfig();
			isWifiConfiguredRef.current = true;

			if (expectedBeaconNetworkId) {
				toast({
					title: i18next.t('WIFI_CONFIGURATION_UPDATED_TITLE'),
					description: i18next.t('WIFI_CONFIGURATION_UPDATED_TIP'),
					position: 'top',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});

				navigate(`/beacons/${beaconId}`);
			} else {
				navigate(`/beacons/associate/${beaconNetworkId}`);
			}
		} catch (error) {
			toastError(i18next.t('WIFI_CONFIGURATION_INVALID_TITLE'), error);
		}

		setLoading(false);
	};

	const selectWifi = (ssid: string, locked?: boolean) => {
		ssidPrompt.onClose();
		setSelectedWifiSSID(ssid);

		if (locked) {
			passwordPrompt.onOpen();
		} else {
			saveWifiConfiguration(ssid);
		}
	};

	const refreshWifisList = useCallback(async () => {
		if (!beaconSerialInterface) {
			return;
		}

		setIsSearching(true);
		const wifis = await beaconSerialInterface.listWifiAccessPoints();
		setWifis(
			wifis
				.filter((wifi) => wifi.ssid !== '')
				.filter((wifi) => {
					const existing = wifis.find((otherWifi) => otherWifi !== wifi && otherWifi.ssid === wifi.ssid);
					return !existing || wifi.attenuation > existing.attenuation;
				})
				.sort((a, b) => (a.attenuation < b.attenuation ? 1 : -1)),
		);
		setFirstSearchComplete(true);
		setIsSearching(false);
	}, [beaconSerialInterface]);

	useEffect(() => {
		if (!beaconSerialInterface) {
			return;
		}

		const run = async () => {
			const currentBeaconId = await beaconSerialInterface.getBeaconId();

			try {
				const beacon = await api.getByNetworkIdBeacon(currentBeaconId, organization.id);

				setBeaconId(beacon.id);
			} catch (err) {
				const error = err as BrowserApiError;

				if (error.containsCode('already_exists')) {
					toastError(
						i18next.t('WIFI_CONFIGURATION_ALREADY_ASSOCIATED_TITLE'),
						i18next.t('WIFI_CONFIGURATION_ALREADY_ASSOCIATED_TIP', {
							currentBeaconId,
						}),
					);

					navigate('/beacons');
					return;
				}

				error.ignore('not_found');
			}

			await beaconSerialInterface.configure().catch(() => {
				// ignore if this command doesn't exists for retro-compatibility
			});

			refreshWifisList();
			setBeaconNetworkId(currentBeaconId);
			setLoading(false);
		};

		run();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beaconSerialInterface]);

	useEffect(() => {
		if (!beaconSerialInterface || loading) {
			return () => {};
		}

		const listWifiInterval = setInterval(refreshWifisList, 5000);
		return () => clearInterval(listWifiInterval);
	});

	useEffect(() => {
		if (!beaconSerialInterface) {
			return () => {};
		}

		const resetBeaconSerialInterface = () => {
			if (!isWifiConfiguredRef.current) {
				beaconSerialInterface.restart().catch(() => {
					// ignore if this command doesn't exists for retro-compatibility
				});
			}
		};

		window.addEventListener('beforeunload', resetBeaconSerialInterface);

		return () => {
			window.removeEventListener('beforeunload', resetBeaconSerialInterface);
			resetBeaconSerialInterface();
			beaconSerialInterface.disconnect();
		};
	}, [beaconSerialInterface]);

	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<Flex justifyContent='center'>
				<Flex direction='column' width='500px'>
					<Flex justifyContent='space-between' alignItems='center' mb='2' ml='2' mr='3'>
						<Heading size='md'>{i18next.t('WIFI_CONFIGURATION_SELECT')}</Heading>
						{isSearching && <Loading />}
					</Flex>
					<Flex direction='column' grow={1} borderRadius='lg' overflow='hidden'>
						{wifis.map((wifi, index) => (
							<WifiButton wifi={wifi} onClick={() => selectWifi(wifi.ssid, wifi.locked)} key={index} />
						))}
						{firstSearchComplete && <WifiButton wifi={otherWifi} onClick={ssidPrompt.onOpen} />}
					</Flex>
				</Flex>
			</Flex>

			<Modal isOpen={ssidPrompt.isOpen} onClose={ssidPrompt.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Connect by SSID</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={({ ssid, locked }) => selectWifi(ssid, locked)}
						initialValues={{
							ssid: '',
							locked: true,
						}}
					>
						<Form>
							<ModalBody>
								<Field name='ssid'>
									{({ field, form }: FieldProps) => (
										<FormControl isRequired isInvalid={Boolean(form.errors.ssid && form.touched.ssid)}>
											<FormLabel htmlFor='ssid'>{i18next.t('WIFI_CONFIGURATION_SSID')}</FormLabel>
											<Input id='ssid' placeholder='Box-XXX' autoComplete='none' {...field} />
											<FormErrorMessage>{form.errors.ssid as string}</FormErrorMessage>
										</FormControl>
									)}
								</Field>

								<Field name='locked'>
									{({ field, form }: FieldProps) => (
										<FormControl mt='4' isInvalid={Boolean(form.errors.locked && form.touched.locked)}>
											<FormLabel htmlFor='locked'>{i18next.t('WIFI_CONFIGURATION_REQUIRES_PASSWORD')}</FormLabel>
											<Checkbox defaultChecked id='locked' {...field} />
											<FormErrorMessage>{form.errors.locked as string}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={ssidPrompt.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={loading}>
									{i18next.t('NEXT')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<Prompt
				title={i18next.t('WIFI_CONFIGURATION_PASSWORD_REQUIRED')}
				inputLabel={`${i18next.t('WIFI_CONFIGURATION_PASSWORD_REQUIRED_TIP')} "${selectedWifiSSID}"`}
				okButtonText={i18next.t('CONNECT')}
				placeholder='*****'
				type='password'
				loading={loading}
				disclosure={passwordPrompt}
				handleSubmit={(password) => saveWifiConfiguration(selectedWifiSSID!, password)}
			/>
		</>
	);
}

const otherWifi = {
	ssid: i18next.t('WIFI_CONFIGURATION_SSID_OTHER'),
	attenuation: 0,
	locked: false,
};

export default WifiConfiguration;
