import i18next from 'i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

function OrganizationForm(): JSX.Element {
	return (
		<>
			<Field name='name'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.name && form.touched.name)}>
						<FormLabel htmlFor='name'>{i18next.t('NAME')}</FormLabel>
						<Input id='name' placeholder='My Organization' autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			{/* <Field name='slug'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.slug && form.touched.slug)} mt='2'>
						<FormLabel htmlFor='slug'>Slug</FormLabel>
						<Input id='slug' placeholder='my-organization' autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.slug}</FormErrorMessage>
					</FormControl>
				)}
			</Field> */}
		</>
	);
}

export default OrganizationForm;
