import i18next from 'i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input, Switch } from '@chakra-ui/react';

import PatternSelect from '../../pattern/PatternSelect';
import { SimplePattern } from '../../../api/client';

export type PrometheusReactionFormProps = {
	patterns?: SimplePattern[];
};

function PrometheusReactionForm({ patterns }: PrometheusReactionFormProps): JSX.Element {
	return (
		<>
			<Field name='name'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.name && form.touched.name)}>
						<FormLabel htmlFor='name'>{i18next.t('NAME')}</FormLabel>
						<Input
							id='name'
							placeholder={i18next.t('PROMETHEUS_REACTION_NAME_PLACEHOLDER')}
							autoComplete='none'
							{...field}
						/>
						<FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name='minimumInterval'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.minimumInterval && form.touched.minimumInterval)}>
						<FormLabel htmlFor='minimumInterval' mt='3'>
							{i18next.t('INTEGRATIONS_MINIMUM_INTERVAL')}
						</FormLabel>
						<Input id='minimumInterval' placeholder='60' autoComplete='none' defaultValue={60} {...field} />
						<FormErrorMessage>{form.errors.minimumInterval as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			{patterns && <PatternSelect required name='patternId' label='Pattern' patterns={patterns} />}

			<Field name='isFiredOnResolve'>
				{({ field: { onChange, ...field }, form }: FieldProps) => (
					<FormControl isInvalid={Boolean(form.errors.isFiredOnResolve && form.touched.isFiredOnResolve)}>
						<FormLabel htmlFor='isFiredOnResolve' mt='3'>
							{i18next.t('PROMETHEUS_TRIGGER_ON_RESOLVE')}
						</FormLabel>
						<Switch
							id='isFiredOnResolve'
							defaultChecked={field.value}
							onChange={() => form.setFieldValue('isFiredOnResolve', !field.value)}
							{...field}
						/>
						<FormErrorMessage>{form.errors.isFiredOnResolve as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default PrometheusReactionForm;
