type SerialTransaction = {
	resolve: (value: string) => void;
	reject: (error: Error) => void;
};

const serialPortFilters = [
	{
		usbProductId: 60000,
		usbVendorId: 4292,
	},
];

export type SerialPortFilters = typeof serialPortFilters;

export const DEFAULT_BAUD_RATE = 115200;

export type Message = {
	status: 'OK' | 'ERROR';
	data?: string;
};

export class IdMismatchError extends Error {}

abstract class SerialInterface {
	protected serialPort?: SerialPort;

	protected expectedBeaconNetworkId: string;

	protected connecting: boolean;

	transactions: SerialTransaction[] = [];

	encoder = new TextEncoder();

	reader?: ReadableStreamReader<Message>;

	streamClosed?: Promise<void>;

	constructor(expectedBeaconNetworkId: string) {
		this.expectedBeaconNetworkId = expectedBeaconNetworkId;
		this.connecting = false;
	}

	async connect(requestPort: boolean = true): Promise<void> {
		this.connecting = true;

		if (requestPort) {
			await this.requestPort();
		}
		await this.open();

		this.listen();

		// wait to ensure the blinky is ready (on Windows it restarts when we listen)
		await new Promise((resolve) => setTimeout(resolve, 1000));

		this.connecting = false;
	}

	async disconnect(resetPort: Boolean = true): Promise<void> {
		await this.reader?.cancel();
		this.reader?.releaseLock();
		this.reader = undefined;

		await this.streamClosed?.catch(() => {});
		this.streamClosed = undefined;

		await this.serialPort?.readable.cancel();
		await this.serialPort?.close();

		if (resetPort) {
			this.serialPort = undefined;
		}
	}

	protected async requestPort(): Promise<void> {
		if (!navigator.serial) {
			throw new Error('This feature is not supported on your browser. Please use Chrome for web serial features.');
		}

		this.serialPort = await navigator.serial.requestPort({ filters: serialPortFilters });
	}

	protected abstract open(): Promise<void>;

	protected async openPort(): Promise<void> {
		return this.port.open({ baudRate: DEFAULT_BAUD_RATE });
	}

	protected async openWithTransformer(transformer: Transformer<Uint8Array, Message>): Promise<void> {
		await this.openPort();
		const transformStream = new TransformStream(transformer);
		this.streamClosed = this.port.readable.pipeTo(transformStream.writable);

		this.reader = transformStream.readable.getReader();
	}

	sendCommand(command: string, retry: number = 0): Promise<string> {
		if (command === '') {
			return new Promise((resolve) => resolve(''));
		}

		if (window.showBeaconLogs) {
			console.info('>', command);
		}

		const writer = this.port.writable!.getWriter();
		const response = new Promise<string>((resolve, reject) => {
			this.transactions.push({
				resolve,
				reject: (e) =>
					retry > 0
						? setTimeout(
								() =>
									this.sendCommand(command, retry - 1)
										.then(resolve)
										.catch(reject),
								100,
						  )
						: reject(e),
			});
		});

		writer.write(this.encoder.encode(`${command}${this.endOfTransmission}`));
		writer.releaseLock();
		return response;
	}

	private async listen(): Promise<void> {
		let listening = true;

		while (listening) {
			// @ts-expect-error
			const result = await this.reader!.read();
			listening = !result.done;
			if (result.value) {
				const transaction = this.transactions.shift();
				if (transaction) {
					// @ts-expect-error
					if (result.value.status === 'OK') {
						// @ts-expect-error
						transaction.resolve(result.value.data!);
					} else {
						// @ts-expect-error
						transaction.reject(new Error(result.value.data));
					}
				}
			}
		}

		this.reader!.releaseLock();
	}

	public get port(): SerialPort {
		if (!this.serialPort) {
			throw new Error('beacon is not connected');
		}

		return this.serialPort;
	}

	public get isConnecting(): boolean {
		return this.connecting;
	}

	protected abstract get endOfTransmission(): string;
}

export default SerialInterface;
