import i18next from 'i18next';
import { Box, Button, Container, Flex, Heading, Icon } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import useSession from '../hooks/useSession';

function LoginRecovery(): JSX.Element {
	const { user } = useSession();
	const boxed = user.id === 0;

	const page = (
		<>
			<Heading size='lg' mb='5'>
				{i18next.t('LOGIN_RECOVERY_TITLE')}
			</Heading>
			<Link to='/login/reset'>
				<Button width='100%' display='flex' justifyContent='space-between'>
					{i18next.t('LOGIN_RECOVERY_FORGOT_PASSWORD')}
					<Icon as={FiChevronRight}></Icon>
				</Button>
			</Link>
			<Link to='/login/resend-confirmation'>
				<Button width='100%' display='flex' justifyContent='space-between' mt='2'>
					{i18next.t('LOGIN_RECOVERY_DIDNT_RECEIVE_EMAIL')}
					<Icon as={FiChevronRight}></Icon>
				</Button>
			</Link>

			<Link to='/login'>
				<Button variant='link' width='100%' display='flex' justifyContent='space-between' mt='4' color='gray'>
					{i18next.t('LOGIN_RECOVERY_CANCEL')}
				</Button>
			</Link>
		</>
	);

	if (boxed) {
		return (
			<>
				<Flex height='100%' justifyContent='center' alignItems='center'>
					<Box width='500px' borderWidth='1px' borderRadius='lg' p='4'>
						{page}
					</Box>
				</Flex>
			</>
		);
	}

	return <Container size='xl'>{page}</Container>;
}

export default LoginRecovery;
