import i18next from 'i18next';
import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tag,
	Text,
} from '@chakra-ui/react';
import { FiChevronRight, FiUser } from 'react-icons/fi';

import useSession from '../../hooks/useSession';
import { DetailedUser } from '../../api/client';

type UserListProps = {
	users: DetailedUser[];
	spacing: string;
	onRemove: (email: string) => void;
	onPromote: (email: string) => void;
	onDowngrade: (email: string) => void;
	loading: boolean;
};

function UserList({ users, spacing, onRemove, onPromote, onDowngrade, loading }: UserListProps): JSX.Element {
	const { user } = useSession();
	const orgUser = users.find((u) => u.id === user?.id);
	// @ts-expect-error
	const isOrgAdmin = orgUser?.isAdmin || orgUser?.isOwner;

	return (
		<>
			{users.length ? (
				// @ts-expect-error
				users.map(({ id, email, username, isAdmin, isOwner }) => (
					<Popover placement='auto-start' isOpen={isOrgAdmin || id === user?.id ? undefined : false} key={id}>
						<PopoverTrigger>
							<Button p='4' mb={spacing} borderRadius='xl' w='100%' key={id}>
								<Flex justifyContent='space-between' alignItems='center' w='100%'>
									<Flex alignItems='center'>
										<Icon mr='2' fontSize='14' as={FiUser} />
										<Heading size='xs'>{username}</Heading>
										<Text fontSize='14px' color='gray.500' mx='2'>
											{email}
										</Text>
										{isAdmin && (
											<Tag bg='yellow.500' mr='2'>
												{i18next.t('USER_LIST_STATUS_ADMIN')}
											</Tag>
										)}
										{isOwner && (
											<Tag bg='blue.500' mr='2'>
												{i18next.t('USER_LIST_STATUS_OWNER')}
											</Tag>
										)}
									</Flex>
									<Flex alignItems='center'>
										<FiChevronRight />
									</Flex>
								</Flex>
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>{i18next.t('USER_LIST_ACTIONS')}</PopoverHeader>
							<PopoverBody>
								{isOrgAdmin &&
									(isAdmin ? (
										!isOwner && (
											<Button
												p='4'
												colorScheme='blue'
												mt='2'
												mb='1'
												borderRadius='xl'
												w='100%'
												key={id}
												onClick={() => onDowngrade(email!)}
												isLoading={loading}
											>
												{i18next.t('USER_LIST_ACTION_REMOVE_ADMIN')}
											</Button>
										)
									) : (
										<Button
											p='4'
											colorScheme='blue'
											mt='2'
											mb='1'
											borderRadius='xl'
											w='100%'
											key={id}
											onClick={() => onPromote(email!)}
											isLoading={loading}
										>
											{i18next.t('USER_LIST_ACTION_PROMOTE_ADMIN')}
										</Button>
									))}

								<Button
									p='4'
									colorScheme='red'
									mt='2'
									mb='1'
									borderRadius='xl'
									w='100%'
									key={id}
									onClick={() => onRemove(email!)}
									isLoading={loading}
								>
									{i18next.t('USER_LIST_ACTION_FIRE')}
								</Button>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				))
			) : (
				<Flex minHeight='30vh' alignItems='center' justifyContent='center'>
					<Box color='gray.600'>{i18next.t('USER_LIST_EMPTY')}</Box>
				</Flex>
			)}
		</>
	);
}

export default UserList;
