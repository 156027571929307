import axios from 'axios';
import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

function Version(): JSX.Element {
	const [version, setVersion] = useState('');

	useEffect(
		() =>
			void (async () => {
				try {
					const { data } = await axios.get('/version.txt');
					setVersion(`Version ${data}`);
				} catch (error) {
					setVersion('Failed to fetch version info');
				}
			})(),
		[],
	);

	if (!version) {
		return <></>;
	}

	return (
		<Text m='4' color='gray.600' fontSize='14px'>
			{version}
		</Text>
	);
}

export default Version;
