import { Button, Code, CodeProps, Icon } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';

type CopiableCodeProps = {
	children: string;
} & CodeProps;

function CopiableCode({ children, ...rest }: CopiableCodeProps): JSX.Element {
	const copy = () => {
		navigator.clipboard.writeText(children);
	};

	return (
		<Code {...rest} justifyContent='space-between'>
			{children}
			<Button variant='outline' ml='2' size='xs' justifySelf='flex-end' onClick={copy}>
				<Icon as={FiCopy} mr='1' />
				Copy
			</Button>
		</Code>
	);
}

export default CopiableCode;
