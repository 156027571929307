import { useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';

type DarkModeProps = {
	children: JSX.Element;
};

function DarkMode({ children }: DarkModeProps): JSX.Element {
	const { setColorMode } = useColorMode();

	useEffect(() => setColorMode('dark'), [setColorMode]);

	return children;
}

export default DarkMode;
