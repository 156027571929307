import { Button, Flex } from '@chakra-ui/react';
import { FiSlash } from 'react-icons/fi';

import { Color } from '../../utility/color';

type PatternFrameProps = {
	colors: Color[];
	selectable?: boolean;
	activeTool?: string;
	activeColor?: Color;
	onClick?: (index: number) => void;
};

function PatternFrame({ colors, activeTool, activeColor, selectable, onClick }: PatternFrameProps): JSX.Element {
	return (
		<Flex grow={1}>
			{new Array(12).fill(0).map((_, index) => {
				const ledColor = colors[index];
				const props = {
					opacity: checkColorIsTransparent(ledColor) && !selectable ? 0 : 1,
					p: '4',
					ml: index === 0 ? '0' : '2',
					mr: index === 11 ? '0' : '2',
					borderColor: 'whiteAlpha.500',
					borderWidth: '2px',
					borderRadius: 'xl',
					background: checkColorIsTransparent(ledColor) ? undefined : convertColorToCSS(ledColor),
					key: index,
					onClick: () => onClick?.(index),
				};

				return selectable ? (
					<Button
						{...props}
						width='100%'
						_hover={{
							background:
								selectable &&
								convertColorToCSS(checkIsMetaTool(activeTool) ? multiplyColor(ledColor, 0.8) : activeColor!),
						}}
					>
						{checkColorIsTransparent(ledColor) && (
							<FiSlash
								style={{
									position: 'absolute',
								}}
							/>
						)}
					</Button>
				) : (
					<Flex grow={1} {...props} />
				);
			})}
		</Flex>
	);
}

function multiplyColor(color: Color, factor: number): Color {
	return {
		r: color.r * factor,
		g: color.g * factor,
		b: color.b * factor,
	};
}

function convertColorToCSS(color: Color): string {
	return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

function checkColorIsTransparent(color: Color): boolean {
	return color.r === 0 && color.g === 0 && color.b === 0;
}

function checkIsMetaTool(tool?: string): boolean {
	return typeof tool !== 'string' || tool === 'pipette';
}

export default PatternFrame;
export { convertColorToCSS, checkColorIsTransparent };
