import i18next from 'i18next';
import slugify from 'slugify';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	UseDisclosureReturn,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import OrganizationForm from './OrganizationForm';
import api from '../../api';
import useCallbackWithLoading from '../../hooks/useCallbackWithLoading';
import { DetailedOrganization } from '../../api/client';
import { switchOrganization } from './OrganizationList';

type OrganizationCreateModalProps = {
	disclosure: UseDisclosureReturn;
};

function OrganizationCreateModal({ disclosure }: OrganizationCreateModalProps): JSX.Element {
	const createOrganization = useCallbackWithLoading(async ({ name }: Partial<DetailedOrganization>) => {
		const org = await api.createOrganization({
			name: name!,
			slug: slugify(name!),
		});

		disclosure.onClose();
		switchOrganization(org, '/');
	});

	return (
		<Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{i18next.t('ORGANIZATION_CREATE')}</ModalHeader>
				<ModalCloseButton />
				<Formik
					onSubmit={createOrganization}
					initialValues={{
						name: '',
						// @ts-expect-error
						users: [],
					}}
				>
					<Form>
						<ModalBody>
							<OrganizationForm />
						</ModalBody>
						<ModalFooter>
							<Button variant='ghost' mr={3} onClick={disclosure.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='blue' type='submit' isLoading={createOrganization.loading}>
								{i18next.t('CREATE')}
							</Button>
						</ModalFooter>
					</Form>
				</Formik>
			</ModalContent>
		</Modal>
	);
}

export default OrganizationCreateModal;
