import { Box, Button } from '@chakra-ui/react';
import CopiableCode from '../../core/CopiableCode';

type PrometheusInstructionsProps = {
	url: string;
	token: string;
};

function PrometheusInstructions({ url, token }: PrometheusInstructionsProps): JSX.Element {
	return (
		<Box>
			Integration with Prometheus is achieved through its AlertManager component. Follow these steps to set it up:
			<Box ml='6' my='2'>
				<ol>
					<li>
						Start by adding a receiver to your alertmanager.yml configuration file:
						<pre>
							<CopiableCode bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mt='1' display='flex'>
								{generateAlertManagerReceiver(url, token)}
							</CopiableCode>
						</pre>
					</li>
					<li style={{ marginTop: 12 }}>
						Next, route some alerts into the Blinky receiver. For example, if you want to route all alerts to your
						Blinky and only critical alerts on an other "mail" receiver:
						<pre>
							<CopiableCode bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mt='1' display='flex'>
								{exampleRoute}
							</CopiableCode>
						</pre>
					</li>
				</ol>
			</Box>
			Please note that this is a simplified configuration. AlertManager offers many features like alert filtering. For a
			comprehensive understanding of its capabilities, refer to the{' '}
			<a href='https://prometheus.io/docs/alerting/latest/configuration/' target='_blank' rel='noreferrer'>
				<Button variant='link' colorScheme='blue'>
					AlertManager documentation
				</Button>
			</a>
			.
		</Box>
	);
}

function generateAlertManagerReceiver(url: string, token: string) {
	return `receivers:
  - name: blinky-receiver
    webhook_configs:
      - url: '${url}'
        http_config:
          authorization:
            credentials: '${token}'`;
}

const exampleRoute = `route:
  routes:
    - receiver: blinky
      continue: true
    - receiver: mail
      matchers:
        - severity = critical
      continue: true`;

export default PrometheusInstructions;
