import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiPlus } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

import Error from '../components/core/Error';
import InputList from '../components/core/InputList';
import IntegrationIcon from '../components/integrations/IntegrationIcon';
import Loading from '../components/core/Loading';
import Reaction from '../components/reaction/Reaction';
import SaveableInput from '../components/core/SaveableInput';
import StatusCakeInstructions from '../components/integrations/statuscake/StatusCakeInstructions';
import StatusCakeReactionForm from '../components/integrations/statuscake/StatusCakeReactionForm';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import StatusCakeForm, { StatusCakeSpec } from '../components/integrations/statuscake/StatusCakeForm';
import { DetailedStatusCakeMatch } from '../api/client';
import { getIngestURL } from '../utility/host';

function StatusCake(): JSX.Element {
	const { organization } = useSession();
	const navigate = useNavigate();
	const reactionEditionModal = useDisclosure();
	const [editingReaction, setEditingReaction] = useState<DetailedStatusCakeMatch>();
	const updateModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const { id } = useParams();
	const {
		queries: [statusCake, targets, patterns],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrieveStatusCake, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
		useQuery(api.listPatterns, organization.id),
	);

	const edit = useCallbackWithLoading((name: string) => api.patchStatusCake(Number(id), organization.id, { name }));

	const save = useCallbackWithLoading(
		async (id: number, name: string, isFiredOnResolve: boolean, statusCodeFilter: string, tagsFilter: string[]) => {
			try {
				await api.patchStatusCakeMatch(Number(id), organization.id, {
					name,
					statusCodeFilter,
					tagsFilter: tagsFilter.filter((tagFilter: string) => tagFilter !== ''),
					isFiredOnResolve,
				});
			} catch (e) {
				return false;
			}
			return true;
		},
	);

	const update = useCallbackWithLoading(async ({ name }: StatusCakeSpec) => {
		try {
			await edit(name);
		} finally {
			refetch();
			updateModal.onClose();
		}
	});

	const deleteStatusCake = useCallbackWithLoading(async () => {
		try {
			await api.destroyStatusCake(Number(id), organization.id);
		} finally {
			deleteModal.onClose();
			navigate('/integrations');
		}
	});

	const addReaction = useCallbackWithLoading(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async ({ name, patternId, minimumInterval, isFiredOnResolve, statusCodeFilter, tagsFilter }: any) => {
			try {
				await api.createStatusCakeMatch(organization.id, {
					name,
					isFiredOnResolve,
					statusCodeFilter,
					tagsFilter,
					statuscake: Number(id),
					reaction: {
						minimumInterval,
						patternId,
					},
				});
			} finally {
				refetch();
				reactionEditionModal.onClose();
			}
		},
	);

	const openReactionEditionModal = (reaction?: DetailedStatusCakeMatch) => {
		setEditingReaction(reaction);
		reactionEditionModal.onOpen();
	};

	const editReaction = useCallbackWithLoading(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async ({ id, name, reaction, minimumInterval, isFiredOnResolve, statusCodeFilter, tagsFilter }: any) => {
			try {
				await api.patchReaction(reaction.id, organization.id, {
					minimumInterval,
					patternId: reaction.pattern.id,
				});

				await api.patchStatusCakeMatch(id, organization.id, {
					name,
					isFiredOnResolve,
				});
			} finally {
				refetch();
				reactionEditionModal.onClose();
			}
		},
	);

	const removeMatch = async (id: number) => {
		try {
			await api.destroyStatusCakeMatch(id, organization.id);
		} finally {
			refetch();
		}
	};

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <Error fullscreen error={error} />;
	}

	const { name, matches = [], token } = statusCake.data!;
	const url = `${getIngestURL()}/statuscake/${token}`;

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='6'>
				<Flex alignItems='center'>
					<Heading size='lg' mr='2'>
						<IntegrationIcon kind='StatusCake' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<div>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={updateModal.onOpen}>
						Rename
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						Delete
					</Button>
				</div>
			</Flex>

			{!matches.length && <Box color='gray.500'>No reactions configured yet.</Box>}

			<Accordion allowMultiple defaultIndex={[]}>
				{/* @ts-expect-error */}
				{matches.map((match) => (
					<AccordionItem key={match.id}>
						<h2>
							<AccordionButton>
								{match.name}
								<AccordionIcon />
							</AccordionButton>
						</h2>

						<AccordionPanel>
							<Heading size='sm' mb='2'>
								Status code filter{' '}
								<Tooltip label='Only valid for "Uptime" alerts with type "HTTP" or "head"'>ⓘ</Tooltip>
								<br />
								<small>
									This field must be a list of HTTP status codes separated by comma. It support range using the form
									'&lt;begin&gt;-&lt;end&gt;'. Status code 0 means connection refusal or timeout.
								</small>
							</Heading>
							<SaveableInput
								defaultValue={match.statusCodeFilter}
								placeholder='403,500-502,504-599'
								handleSave={(statusCodeFilter) =>
									save(match.id, match.name, match.isFiredOnResolve, statusCodeFilter, match.tagsFilter)
								}
							></SaveableInput>
							<Box mt='4' />
							<Heading size='sm' mb='2'>
								Tags filter <Tooltip label='Only valid for "Uptime" alerts'>ⓘ</Tooltip>
							</Heading>
							<InputList
								defaultValue={match.tagsFilter}
								handleSave={(tagsFilter) =>
									save(match.id, match.name, match.isFiredOnResolve, match.statusCodeFilter, tagsFilter)
								}
							></InputList>
							<Box mt='4' />
							<Reaction
								reaction={match.reaction}
								targets={targets.data!.results!}
								patterns={patterns.data!.results!}
								refetch={refetch}
							/>

							<Button
								colorScheme='blue'
								leftIcon={<FiEdit3 />}
								mt='4'
								mr='2'
								onClick={() => openReactionEditionModal(match)}
							>
								Edit
							</Button>
							<Button colorScheme='red' leftIcon={<FiDelete />} mt='4' onClick={() => removeMatch(match.id)}>
								Delete
							</Button>
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>

			<Button leftIcon={<FiPlus />} onClick={() => openReactionEditionModal()} mt='4'>
				Add Reaction
			</Button>

			<Accordion allowMultiple defaultIndex={[]} mt='6'>
				<AccordionItem>
					<h2>
						<AccordionButton>
							How to setup StatusCake integration
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel>
						<StatusCakeInstructions url={url} token={token} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Modal isOpen={updateModal.isOpen} onClose={updateModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit StatusCake integration</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={update}
						initialValues={{
							name,
						}}
					>
						<Form>
							<ModalBody>
								<StatusCakeForm />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={updateModal.onClose}>
									Cancel
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={update.loading}>
									Update
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete StatusCake integration
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? The integration will stop working and this action cannot be undone.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								Cancel
							</Button>
							<Button colorScheme='red' ml={3} onClick={deleteStatusCake} isLoading={deleteStatusCake.loading}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Modal isOpen={reactionEditionModal.isOpen} onClose={reactionEditionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{editingReaction ? 'Edit' : 'Create'} StatusCake Reaction</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editingReaction ? editReaction : addReaction}
						initialValues={
							editingReaction
								? {
										...editingReaction,
										minimumInterval: editingReaction.reaction.minimumInterval,
								  }
								: {
										name: '',
										patternId: 0,
										tagsFilter: [],
										isFiredOnResolve: false,
										minimumInterval: 60,
								  }
						}
					>
						<Form>
							<ModalBody>
								<StatusCakeReactionForm patterns={editingReaction ? undefined : patterns.data!.results!} />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={reactionEditionModal.onClose}>
									Cancel
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editReaction.loading}>
									{editingReaction ? 'Update' : 'Create'}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>
		</>
	);
}
export default StatusCake;
