import {
	Box,
	Button,
	Code,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tag,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { useState } from 'react';

import CreatableSelect from './CreatableSelect';

type EditableStringListProps = {
	items: string[];
	addItem: (item: string) => void;
	removeItem: (index: number) => void;
	options?: string[];
};

function EditableStringList({ items, addItem, removeItem, options }: EditableStringListProps): JSX.Element {
	const createModal = useDisclosure();
	const [createModalInput, setCreateModalInput] = useState('');

	const add = () => {
		if (createModalInput.length < 1) {
			return;
		}

		// @ts-expect-error
		addItem(createModalInput.value);
		setCreateModalInput('');
		createModal.onClose();
	};

	return (
		<Box border='1px solid rgba(255, 255, 255, 0.1)' borderRadius='lg' p='1' alignItems='center'>
			{!items.length && (
				<Text p='2' color='gray'>
					No events selected (match everything)
				</Text>
			)}

			{items.map((item, index) => (
				<Tag m='1' key={index}>
					<Code backgroundColor='transparent'>{item}</Code>
					<Icon as={FiX} ml='2' onClick={() => removeItem(index)} cursor='pointer' />
				</Tag>
			))}

			<Button variant='link' colorScheme='blue' onClick={createModal.onOpen} m='2'>
				+ Add Event
			</Button>

			<Modal isOpen={createModal.isOpen} onClose={createModal.onClose}>
				<ModalOverlay />
				<ModalContent minW='800px'>
					<ModalHeader>Filter by event</ModalHeader>
					<ModalBody>
						<CreatableSelect
							value={{
								label: createModalInput,
								value: createModalInput,
							}}
							onChange={(event) => setCreateModalInput(event.value)}
							options={options?.map((option) => ({
								label: option,
								value: option,
							}))}
						/>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='blue' onClick={add}>
							Add
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default EditableStringList;
