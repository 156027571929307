import { FiBookmark, FiChevronDown } from 'react-icons/fi';
import { Flex, Icon, Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OrganizationCreateModal from './OrganizationCreateModal';
import api from '../../api';
import useSession from '../../hooks/useSession';
import OrganizationList, { switchOrganization } from './OrganizationList';

function OrganizationSelect(): JSX.Element | null {
	const { organization } = useSession();
	const [isFocused, setIsFocused] = useState(false);
	const createModal = useDisclosure();
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			if (organization.id !== 0) {
				return;
			}

			const orgs = await api.listOrganizations();
			if (localStorage.getItem('invitation')) {
				navigate('/organization/join');
			} else if (orgs.results!.length === 0) {
				navigate('/organization');
			} else {
				switchOrganization(orgs.results![0]);
			}
		})();
	}, [organization]);

	if (organization.id === 0) {
		return null;
	}

	return (
		<>
			<Flex alignItems='center'>
				<Menu>
					<MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }} onClick={() => setIsFocused(true)}>
						<Flex alignItems='center'>
							<Icon as={FiBookmark} mr='2' />
							{organization.name}
							<Icon as={FiChevronDown} ml='1' />
						</Flex>
					</MenuButton>
					<MenuList bg='gray.900' borderColor='gray.700'>
						{isFocused ? <OrganizationList onCreate={createModal.onOpen} /> : null}
					</MenuList>
				</Menu>
			</Flex>
			<OrganizationCreateModal disclosure={createModal} />
		</>
	);
}

export default OrganizationSelect;
