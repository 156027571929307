import i18next from 'i18next';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';

type UserRegisterInput = Parameters<typeof api.createUser>[0];

function Register(): JSX.Element {
	const toast = useToast();
	const navigate = useNavigate();

	const register = useCallbackWithLoading(async (input: UserRegisterInput) => {
		try {
			await api.apiV1AuthRegistrationCreate(
				{
					email: input.email || '',
					username: input.username,
					password1: input.password,
					password2: input.password,
				},
				{
					headers: {
						'Accept-Language': i18next.language,
					},
				},
			);

			toast({
				title: i18next.t('REGISTER_SUCCESS_TITLE'),
				description: i18next.t('REGISTER_SUCCESS_TIP'),
				duration: 5000,
				isClosable: true,
				status: 'success',
				position: 'top',
			});

			navigate('/');
		} catch (error) {}
	});

	return (
		<Flex height='100%' justifyContent='center' alignItems='center'>
			<Box width='500px' borderWidth='1px' borderRadius='lg' p='4'>
				<Heading size='lg' mb='5'>
					{i18next.t('REGISTER_TITLE')}
				</Heading>
				<Formik
					onSubmit={register}
					initialValues={{
						username: '',
						email: '',
						password: '',
					}}
				>
					<Form>
						<Field name='username'>
							{({ field, form }: FieldProps) => (
								<FormControl isRequired>
									<FormLabel htmlFor='username'>{i18next.t('NAME')}</FormLabel>
									<Input id='username' placeholder='Monkeynator' autoComplete='none' {...field} />
									<FormErrorMessage>{form.errors.username as string}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Box mt='3' />
						<Field name='email'>
							{({ field, form }: FieldProps) => (
								<FormControl isRequired>
									<FormLabel htmlFor='email'>{i18next.t('EMAIL')}</FormLabel>
									<Input id='email' placeholder='monkeynator@enix.io' autoComplete='none' {...field} />
									<FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Box mt='3' />
						<Field name='password'>
							{({ field, form }: FieldProps) => (
								<FormControl isRequired>
									<FormLabel htmlFor='password'>{i18next.t('PASSWORD')}</FormLabel>
									<Input id='password' type='password' placeholder='*****' autoComplete='none' {...field} />
									<FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Flex alignItems='center' justifyContent='flex-end' pt='4'>
							<Flex mr='4'>
								<Link to='/login'>{i18next.t('REGISTER_LINK_TO_LOGIN')}</Link>
							</Flex>
							<Button colorScheme='blue' type='submit' isLoading={register.loading}>
								{i18next.t('REGISTER')}
							</Button>
						</Flex>
					</Form>
				</Formik>
			</Box>
		</Flex>
	);
}

export default Register;
