import i18next from 'i18next';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Code,
	Flex,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tag,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiGitlab } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

import Error from '../components/core/Error';
import GitlabInstructions from '../components/integrations/gitlab/GitlabInstructions';
import GitlabReactionForm from '../components/integrations/gitlab/GitlabReactionForm';
import Loading from '../components/core/Loading';
import Reaction from '../components/reaction/Reaction';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import GitlabForm, { GitlabSpec } from '../components/integrations/gitlab/GitlabForm';
import { DetailedGitlab, DetailedReaction } from '../api/client';
import { getIngestURL } from '../utility/host';

type GitlabReactionSpec = {
	patternId: number;
	minimumInterval: number;
};

function Gitlab(): JSX.Element {
	const navigate = useNavigate();
	const { organization } = useSession();
	const editionModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const reactionEditionModal = useDisclosure();
	const [editingReaction, setEditingReaction] = useState<DetailedReaction | null>(null);
	const [editingReactionKind, setEditingReactionKind] = useState('');
	const { id } = useParams();
	const {
		queries: [gitlab, targets, patterns],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrieveGitlab, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
		useQuery(api.listPatterns, organization.id),
	);

	const editGitlab = useCallbackWithLoading(async ({ name }: GitlabSpec) => {
		try {
			await api.patchGitlab(Number(id), organization.id, { name });
		} finally {
			refetch();
			editionModal.onClose();
		}
	});

	const deleteGitlab = useCallbackWithLoading(async () => {
		try {
			await api.destroyGitlab(Number(id), organization.id);
		} finally {
			deleteModal.onClose();
			navigate('/integrations');
		}
	});

	const enableEvent = useCallbackWithLoading(async ({ patternId, minimumInterval }: GitlabReactionSpec) => {
		try {
			const reaction = await api.createReaction(organization.id, {
				patternId,
				// @ts-ignore
				minimumInterval,
			});

			await api.patchGitlab(Number(id), organization.id, {
				[`${editingReactionKind}Reaction`]: { id: reaction.id },
			});
		} finally {
			refetch();
			setEditingReactionKind('');
			reactionEditionModal.onClose();
		}
	});

	const editEvent = useCallbackWithLoading(async ({ minimumInterval }: GitlabReactionSpec) => {
		if (!editingReaction) {
			return;
		}

		try {
			await api.patchReaction(editingReaction.id, organization.id, {
				// @ts-ignore
				minimumInterval,
			});
		} finally {
			refetch();
			setEditingReactionKind('');
			setEditingReaction(null);
			reactionEditionModal.onClose();
		}
	});

	const disableEvent = useCallbackWithLoading(async (kind: string) => {
		const reactionKey = `${kind}Reaction`;

		try {
			// @ts-expect-error
			await api.destroyReaction(gitlab.data![reactionKey].id, organization.id);
		} finally {
			refetch();
		}
	});

	const editReaction = (kind: string, reaction: DetailedReaction | null) => {
		setEditingReactionKind(kind);
		setEditingReaction(reaction);
		reactionEditionModal.onOpen();
	};

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <Error fullscreen error={error} />;
	}

	const { name, token } = gitlab.data!;
	// @ts-expect-error
	const reactions = getAllGitlabReactions(gitlab.data!);
	const url = `${getIngestURL()}/gitlab`;
	const editionInProgress = editGitlab.loading || enableEvent.loading || editEvent.loading || disableEvent.loading;

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='2'>
				<Flex alignItems='center'>
					<Heading size='lg'>
						<Icon as={FiGitlab} mr='2' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<div>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={editionModal.onOpen}>
						{i18next.t('RENAME')}
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						{i18next.t('DELETE')}
					</Button>
				</div>
			</Flex>

			<Heading size='md' my='4'>
				{i18next.t('GITLAB_EVENTS')}
			</Heading>

			<Accordion allowMultiple defaultIndex={[]}>
				{Object.entries(reactions).map(([kind, reaction]) => (
					<AccordionItem>
						<h2>
							<AccordionButton>
								<Flex textAlign='left' alignItems='center' justifyContent='center'>
									{i18next.t('GITLAB_EVENT')}: <Code ml='1'>{kind}</Code>
									{reaction && (
										<Tag bg='green.500' ml='2' size='sm'>
											{i18next.t('GITLAB_ACTIVE')}
										</Tag>
									)}
								</Flex>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>
							{reaction ? (
								<>
									<Reaction
										reaction={reaction}
										targets={targets.data!.results!}
										patterns={patterns.data!.results!}
										refetch={refetch}
									/>
									<Flex>
										<Button colorScheme='blue' mt='4' onClick={() => editReaction(kind, reaction)}>
											<Icon as={FiEdit3} mr='1' />
											{i18next.t('GITLAB_EDIT_EVENT')}
										</Button>
										<Button colorScheme='red' mt='4' ml='2' onClick={() => disableEvent(kind)}>
											<Icon as={FiDelete} mr='1' />
											{i18next.t('GITLAB_DISABLE_EVENT')}
										</Button>
									</Flex>
								</>
							) : (
								<Flex alignItems='center' justifyContent='center'>
									<Text color='gray'>{i18next.t('GITLAB_EVENT_DISABLED')}</Text>
									<Button colorScheme='blue' ml='4' onClick={() => editReaction(kind, null)}>
										{i18next.t('GITLAB_ENABLE_EVENT')}
									</Button>
								</Flex>
							)}
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>

			<Heading size='md' mt='6' mb='4'>
				{i18next.t('GITLAB_CONFIGURATION')}
			</Heading>

			<Accordion allowMultiple defaultIndex={[]} mt='4'>
				<AccordionItem>
					<h2>
						<AccordionButton>
							How to setup Gitlab integration
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel>
						<GitlabInstructions url={url} token={token} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Modal isOpen={editionModal.isOpen} onClose={editionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{i18next.t('GITLAB_EDIT_TITLE')}</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editGitlab}
						initialValues={{
							name,
							minimumInterval: 0,
						}}
					>
						<Form>
							<ModalBody>
								<GitlabForm />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={editionModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editionInProgress}>
									{i18next.t('UPDATE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('GITLAB_DELETE_TITLE')}
						</AlertDialogHeader>

						<AlertDialogBody>{i18next.t('GITLAB_DELETE_TIP')}</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' ml={3} onClick={deleteGitlab} isLoading={deleteGitlab.loading}>
								{i18next.t('DELETE')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Modal isOpen={reactionEditionModal.isOpen} onClose={reactionEditionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{i18next.t(editingReaction ? 'GITLAB_REACTION_EDIT_TITLE' : 'GITLAB_REACTION_CREATE_TITLE')}
					</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editingReaction ? editEvent : enableEvent}
						// @ts-expect-error
						initialValues={
							editingReaction || {
								patternId: 0,
								minimumInterval: 60,
							}
						}
					>
						<Form>
							<ModalBody>
								<GitlabReactionForm patterns={editingReaction ? undefined : patterns.data!.results!} />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={reactionEditionModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editionInProgress}>
									{i18next.t(editingReaction ? 'UPDATE' : 'CREATE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>
		</>
	);
}

function getAllGitlabReactions(gitlab?: DetailedGitlab): { [kind: string]: DetailedReaction | undefined } {
	return {
		push: gitlab?.pushReaction,
		tagPush: gitlab?.tagPushReaction,
		issueOpened: gitlab?.issueOpenedReaction,
		issueCommented: gitlab?.issueCommentedReaction,
		pipelineSuccess: gitlab?.pipelineSuccessReaction,
		pipelineFailure: gitlab?.pipelineFailureReaction,
	};
}

export default Gitlab;
