import i18next from 'i18next';
import { Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { FiFolder, FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Empty from '../components/core/Empty';
import Error from '../components/core/Error';
import GroupList from '../components/group/GroupList';
import Loading from '../components/core/Loading';
import Prompt from '../components/layout/Prompt';
import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';

function Groups(): JSX.Element {
	const navigate = useNavigate();
	const creationModal = useDisclosure();
	const { organization } = useSession();
	const { data, loading, error } = useQuery(api.listGroupTargets, organization.id);

	const createGroup = useCallbackWithLoading(async (name: string) => {
		const group = await api.createGroupTarget(organization.id, { name });
		navigate(`/groups/${group.id}`);
	});

	if (error) {
		return <Error fullscreen error={error} />;
	}
	if (loading || !data) {
		return <Loading />;
	}

	return (
		<>
			{data.results?.length ? (
				<>
					<Flex justifyContent='space-between' alignItems='flex-end' mb='6'>
						<Heading size='lg'>{i18next.t('GROUPS_TITLE')}</Heading>
						<Button colorScheme='blue' leftIcon={<FiPlus />} onClick={creationModal.onOpen}>
							{i18next.t('GROUPS_CREATE')}
						</Button>
					</Flex>

					<GroupList link groups={data.results!} spacing='4' />
				</>
			) : (
				<Empty
					title={i18next.t('GROUPS_LIST_EMPTY')}
					action={i18next.t('GROUPS_CREATE')}
					// @ts-expect-error
					icon={FiFolder}
					onCreate={creationModal.onOpen}
				>
					{i18next.t('GROUPS_CREATE_TIP')}
				</Empty>
			)}

			<Prompt
				title={i18next.t('GROUPS_CREATE')}
				inputLabel={i18next.t('NAME')}
				okButtonText={i18next.t('CREATE')}
				placeholder={i18next.t('GROUP_RENAME_INPUT_PLACEHOLDER')}
				loading={createGroup.loading}
				handleSubmit={createGroup}
				disclosure={creationModal}
			/>
		</>
	);
}

export default Groups;
