import { Button, Flex, Input } from '@chakra-ui/react';
import { FiSave } from 'react-icons/fi';
import { useState } from 'react';

type InputListProps = {
	defaultValue: string;
	placeholder: string;
	handleSave: (value: string) => Promise<unknown>;
};

function SaveableInput({ defaultValue, placeholder, handleSave }: InputListProps): JSX.Element {
	const [value, setValue] = useState<string>(defaultValue);
	const [dirty, setDirty] = useState(false);
	const [saving, setSaving] = useState(false);

	const handleInputChange = (value: string) => {
		setValue(value);
		setDirty(true);
	};

	const save = async () => {
		setSaving(true);
		if (await handleSave(value)) {
			setDirty(false);
		}
		setSaving(false);
	};

	return (
		<>
			<Flex alignItems='center' mb='2'>
				<Input
					value={value}
					placeholder={placeholder}
					onChange={(event) => handleInputChange(event.target.value)}
					_placeholder={{
						color: 'whiteAlpha.600',
					}}
				/>
			</Flex>

			{dirty && (
				<Button colorScheme='blue' leftIcon={<FiSave />} onClick={save} isLoading={saving}>
					Save
				</Button>
			)}
		</>
	);
}

export default SaveableInput;
