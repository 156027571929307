/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Adds nested create feature */
export interface DetailedBeacon {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** @default 0 */
  pendingOrdersCount: number;
  orders: string;
  /** @default 0 */
  reactionsCount: number;
  name: string;
  status: "up" | "down";
  version: string;
  hardware: string;
  networkId: string;
  statusLedBrightness?: number;
  ceilingInstallation?: boolean;
}

/** Adds nested create feature */
export interface DetailedGithub {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  pushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  tagPushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueOpenedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueCommentedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pullRequestOpenedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pullRequestCommentedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  checkSuiteCompletedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  checkSuiteFailedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedGitlab {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  pushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  tagPushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueOpenedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueCommentedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pipelineSuccessReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pipelineFailureReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedGitlab2 {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  matches: string;
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedGitlab2Match {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  reaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name: string;
  /** @maxLength 255 */
  event?: string;
  gitlab2: number;
}

/** Adds nested create feature */
export interface DetailedGroupTarget {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** @default [] */
  targets?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    beacon: SimpleBeacon;
    groupTarget: SimpleGroupTarget;
    name: string;
  }[];
  name: string;
}

/** Adds nested create feature */
export interface DetailedIntegration {
  id: number;
  name: string;
  kind: string;
  impl: string;
  owner: SimpleOrganization;
}

/** Adds nested create feature */
export interface DetailedObservium {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  matches: string;
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedObserviumMatch {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  reaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name: string;
  triggerOnFire: boolean;
  triggerOnRecover: boolean;
  triggerOnReminder: boolean;
  observium: number;
}

/** Adds nested create feature */
export interface DetailedOrder {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  reaction: DetailedReaction;
  trigger: string;
  status: "pending" | "played" | "failed";
  rawPattern: Record<string, any>;
  /** @format date-time */
  lastPublishedAt: string;
  details?: string | null;
  beacon: number;
}

export interface DetailedOrganization {
  id: number;
  users: string;
  /**
   * The name of the organization
   * @maxLength 200
   */
  name: string;
  isActive?: boolean;
  /** @format date-time */
  created: string;
  /** @format date-time */
  modified: string;
  /**
   * The name in all lowercase, suitable for URL identification
   * @maxLength 200
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
}

/** Adds nested create feature */
export interface DetailedPagerDuty {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  matches: string;
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
  signingSecret?: string;
}

/** Adds nested create feature */
export interface DetailedPagerDutyMatch {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  reaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name: string;
  events?: Record<string, any>;
  pagerduty: number;
}

/** Adds nested create feature */
export interface DetailedPattern {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  name: string;
  raw: Record<string, any>;
  isTemplate?: boolean;
}

/** Adds nested create feature */
export interface DetailedPrometheus {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  matches: string;
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedPrometheusMatch {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  reaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  rules: {
    label: string;
    value: string;
  }[];
  name: string;
  isFiredOnResolve: boolean;
  prometheus: number;
}

/** Adds nested create feature */
export interface DetailedReaction {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  pattern: SimplePattern;
  patternId: number;
  trigger: DetailedIntegration;
  /** @default [] */
  targets: {
    id: number;
    beacon: SimpleBeacon;
    groupTarget: SimpleGroupTarget;
  }[];
  minimumInterval: string;
}

/** Adds nested create feature */
export interface DetailedRobusta {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  matches: string;
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedRobustaMatch {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  reaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name: string;
  robusta: number;
}

/** Adds nested create feature */
export interface DetailedStatusCake {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  matches: string;
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

/** Adds nested create feature */
export interface DetailedStatusCakeMatch {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  reaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  tagsFilter?: string[];
  statusCodeFilter?: string;
  name: string;
  isFiredOnResolve: boolean;
  statuscake: number;
}

/** Adds nested create feature */
export interface DetailedTarget {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  beacon: SimpleBeacon;
  groupTarget: SimpleGroupTarget;
  name: string;
}

export interface DetailedUser {
  id: number;
  /** @format date-time */
  lastLogin?: string | null;
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  isSuperuser?: boolean;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  isStaff?: boolean;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  isActive?: boolean;
  /** @format date-time */
  dateJoined?: string;
}

/** Adds nested create feature */
export interface DetailedWebhook {
  id: number;
  /** @format date-time */
  createdAt: string;
  createdBy: SimpleUser;
  /** @format date-time */
  updatedAt: string;
  updatedBy: SimpleUser;
  owner: SimpleOrganization;
  /** Adds nested create feature */
  defaultReaction: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  targetBeaconsCount: string;
  name: string;
  /** @format uuid */
  token: string;
}

export interface FilterRule {
  key: string;
  op: "eq" | "neq" | "regex" | "nregex";
  value: string;
}

/** Serializer for JWT authentication. */
export interface JWT {
  accessToken: string;
  refreshToken: string;
  /** User model w/o password */
  user: {
    /** ID */
    pk: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username: string;
    /**
     * Email address
     * @format email
     */
    email: string;
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
  };
}

export interface Login {
  username?: string;
  /** @format email */
  email?: string;
  password: string;
}

export interface PaginatedDetailedGitlab2MatchList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    /** Adds nested create feature */
    reaction: {
      id: number;
      /** @format date-time */
      createdAt: string;
      createdBy: SimpleUser;
      /** @format date-time */
      updatedAt: string;
      updatedBy: SimpleUser;
      owner: SimpleOrganization;
      pattern: SimplePattern;
      patternId: number;
      trigger: DetailedIntegration;
      /** @default [] */
      targets: {
        id: number;
        beacon: SimpleBeacon;
        groupTarget: SimpleGroupTarget;
      }[];
      minimumInterval: string;
    };
    filters: {
      key: string;
      op: "eq" | "neq" | "regex" | "nregex";
      value: string;
    }[];
    name: string;
    /** @maxLength 255 */
    event?: string;
    gitlab2: number;
  }[];
}

export interface PaginatedDetailedIntegrationList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
    kind: string;
    impl: string;
    owner: SimpleOrganization;
  }[];
}

export interface PaginatedDetailedObserviumMatchList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    /** Adds nested create feature */
    reaction: {
      id: number;
      /** @format date-time */
      createdAt: string;
      createdBy: SimpleUser;
      /** @format date-time */
      updatedAt: string;
      updatedBy: SimpleUser;
      owner: SimpleOrganization;
      pattern: SimplePattern;
      patternId: number;
      trigger: DetailedIntegration;
      /** @default [] */
      targets: {
        id: number;
        beacon: SimpleBeacon;
        groupTarget: SimpleGroupTarget;
      }[];
      minimumInterval: string;
    };
    filters: {
      key: string;
      op: "eq" | "neq" | "regex" | "nregex";
      value: string;
    }[];
    name: string;
    triggerOnFire: boolean;
    triggerOnRecover: boolean;
    triggerOnReminder: boolean;
    observium: number;
  }[];
}

export interface PaginatedDetailedOrderList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    reaction: DetailedReaction;
    trigger: string;
    status: "pending" | "played" | "failed";
    rawPattern: Record<string, any>;
    /** @format date-time */
    lastPublishedAt: string;
    details?: string | null;
    beacon: number;
  }[];
}

export interface PaginatedDetailedPagerDutyMatchList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    /** Adds nested create feature */
    reaction: {
      id: number;
      /** @format date-time */
      createdAt: string;
      createdBy: SimpleUser;
      /** @format date-time */
      updatedAt: string;
      updatedBy: SimpleUser;
      owner: SimpleOrganization;
      pattern: SimplePattern;
      patternId: number;
      trigger: DetailedIntegration;
      /** @default [] */
      targets: {
        id: number;
        beacon: SimpleBeacon;
        groupTarget: SimpleGroupTarget;
      }[];
      minimumInterval: string;
    };
    filters: {
      key: string;
      op: "eq" | "neq" | "regex" | "nregex";
      value: string;
    }[];
    name: string;
    events?: Record<string, any>;
    pagerduty: number;
  }[];
}

export interface PaginatedDetailedPrometheusMatchList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    /** Adds nested create feature */
    reaction: {
      id: number;
      /** @format date-time */
      createdAt: string;
      createdBy: SimpleUser;
      /** @format date-time */
      updatedAt: string;
      updatedBy: SimpleUser;
      owner: SimpleOrganization;
      pattern: SimplePattern;
      patternId: number;
      trigger: DetailedIntegration;
      /** @default [] */
      targets: {
        id: number;
        beacon: SimpleBeacon;
        groupTarget: SimpleGroupTarget;
      }[];
      minimumInterval: string;
    };
    rules: {
      label: string;
      value: string;
    }[];
    name: string;
    isFiredOnResolve: boolean;
    prometheus: number;
  }[];
}

export interface PaginatedDetailedReactionList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  }[];
}

export interface PaginatedDetailedRobustaMatchList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    /** Adds nested create feature */
    reaction: {
      id: number;
      /** @format date-time */
      createdAt: string;
      createdBy: SimpleUser;
      /** @format date-time */
      updatedAt: string;
      updatedBy: SimpleUser;
      owner: SimpleOrganization;
      pattern: SimplePattern;
      patternId: number;
      trigger: DetailedIntegration;
      /** @default [] */
      targets: {
        id: number;
        beacon: SimpleBeacon;
        groupTarget: SimpleGroupTarget;
      }[];
      minimumInterval: string;
    };
    filters: {
      key: string;
      op: "eq" | "neq" | "regex" | "nregex";
      value: string;
    }[];
    name: string;
    robusta: number;
  }[];
}

export interface PaginatedDetailedStatusCakeMatchList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    /** Adds nested create feature */
    reaction: {
      id: number;
      /** @format date-time */
      createdAt: string;
      createdBy: SimpleUser;
      /** @format date-time */
      updatedAt: string;
      updatedBy: SimpleUser;
      owner: SimpleOrganization;
      pattern: SimplePattern;
      patternId: number;
      trigger: DetailedIntegration;
      /** @default [] */
      targets: {
        id: number;
        beacon: SimpleBeacon;
        groupTarget: SimpleGroupTarget;
      }[];
      minimumInterval: string;
    };
    tagsFilter?: string[];
    statusCodeFilter?: string;
    name: string;
    isFiredOnResolve: boolean;
    statuscake: number;
  }[];
}

export interface PaginatedSimpleBeaconList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
    status: "up" | "down";
    hardware: string;
    version: string;
    /** @default 0 */
    pendingOrdersCount: number;
    owner: SimpleOrganization;
  }[];
}

export interface PaginatedSimpleGithubList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleGitlab2List {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleGitlabList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleGroupTargetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
    owner: SimpleOrganization;
  }[];
}

export interface PaginatedSimpleObserviumList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleOrganizationList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /**
     * The name of the organization
     * @maxLength 200
     */
    name: string;
    /**
     * The name in all lowercase, suitable for URL identification
     * @maxLength 200
     * @pattern ^[-a-zA-Z0-9_]+$
     */
    slug: string;
  }[];
}

export interface PaginatedSimplePagerDutyList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimplePatternList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
    owner: SimpleOrganization;
  }[];
}

export interface PaginatedSimplePrometheusList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleRobustaList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleStatusCakeList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PaginatedSimpleTargetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    beacon: SimpleBeacon;
    groupTarget: SimpleGroupTarget;
  }[];
}

export interface PaginatedSimpleUserList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username: string;
    /**
     * Email address
     * @format email
     * @maxLength 254
     */
    email?: string;
    /** @maxLength 128 */
    password: string;
  }[];
}

export interface PaginatedSimpleWebhookList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results?: {
    id: number;
    name: string;
  }[];
}

export interface PasswordChange {
  /** @maxLength 128 */
  newPassword1: string;
  /** @maxLength 128 */
  newPassword2: string;
}

/** Serializer for requesting a password reset e-mail. */
export interface PasswordReset {
  /** @format email */
  email: string;
}

/** Serializer for confirming a password reset attempt. */
export interface PasswordResetConfirm {
  /** @maxLength 128 */
  newPassword1: string;
  /** @maxLength 128 */
  newPassword2: string;
  uid: string;
  token: string;
}

/** Adds nested create feature */
export interface PatchedDetailedBeacon {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** @default 0 */
  pendingOrdersCount?: number;
  orders?: string;
  /** @default 0 */
  reactionsCount?: number;
  name?: string;
  status?: "up" | "down";
  version?: string;
  hardware?: string;
  networkId?: string;
  statusLedBrightness?: number;
  ceilingInstallation?: boolean;
}

/** Adds nested create feature */
export interface PatchedDetailedGithub {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  pushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  tagPushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueOpenedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueCommentedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pullRequestOpenedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pullRequestCommentedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  checkSuiteCompletedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  checkSuiteFailedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedGitlab {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  pushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  tagPushReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueOpenedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  issueCommentedReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pipelineSuccessReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  /** Adds nested create feature */
  pipelineFailureReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedGitlab2 {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  matches?: string;
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedGitlab2Match {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  reaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters?: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name?: string;
  /** @maxLength 255 */
  event?: string;
  gitlab2?: number;
}

/** Adds nested create feature */
export interface PatchedDetailedGroupTarget {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** @default [] */
  targets?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    beacon: SimpleBeacon;
    groupTarget: SimpleGroupTarget;
    name: string;
  }[];
  name?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedIntegration {
  id?: number;
  name?: string;
  kind?: string;
  impl?: string;
  owner?: SimpleOrganization;
}

/** Adds nested create feature */
export interface PatchedDetailedObservium {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  matches?: string;
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedObserviumMatch {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  reaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters?: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name?: string;
  triggerOnFire?: boolean;
  triggerOnRecover?: boolean;
  triggerOnReminder?: boolean;
  observium?: number;
}

/** Adds nested create feature */
export interface PatchedDetailedOrder {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  reaction?: DetailedReaction;
  trigger?: string;
  status?: "pending" | "played" | "failed";
  rawPattern?: Record<string, any>;
  /** @format date-time */
  lastPublishedAt?: string;
  details?: string | null;
  beacon?: number;
}

export interface PatchedDetailedOrganization {
  id?: number;
  users?: string;
  /**
   * The name of the organization
   * @maxLength 200
   */
  name?: string;
  isActive?: boolean;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  modified?: string;
  /**
   * The name in all lowercase, suitable for URL identification
   * @maxLength 200
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedPagerDuty {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  matches?: string;
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
  signingSecret?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedPagerDutyMatch {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  reaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters?: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name?: string;
  events?: Record<string, any>;
  pagerduty?: number;
}

/** Adds nested create feature */
export interface PatchedDetailedPattern {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  name?: string;
  raw?: Record<string, any>;
  isTemplate?: boolean;
}

/** Adds nested create feature */
export interface PatchedDetailedPrometheus {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  matches?: string;
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedPrometheusMatch {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  reaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  rules?: {
    label: string;
    value: string;
  }[];
  name?: string;
  isFiredOnResolve?: boolean;
  prometheus?: number;
}

/** Adds nested create feature */
export interface PatchedDetailedReaction {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  pattern?: SimplePattern;
  patternId?: number;
  trigger?: DetailedIntegration;
  /** @default [] */
  targets?: {
    id: number;
    beacon: SimpleBeacon;
    groupTarget: SimpleGroupTarget;
  }[];
  minimumInterval?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedRobusta {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  matches?: string;
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedRobustaMatch {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  reaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  filters?: {
    key: string;
    op: "eq" | "neq" | "regex" | "nregex";
    value: string;
  }[];
  name?: string;
  robusta?: number;
}

/** Adds nested create feature */
export interface PatchedDetailedStatusCake {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  matches?: string;
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedStatusCakeMatch {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  reaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  tagsFilter?: string[];
  statusCodeFilter?: string;
  name?: string;
  isFiredOnResolve?: boolean;
  statuscake?: number;
}

/** Adds nested create feature */
export interface PatchedDetailedTarget {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  beacon?: SimpleBeacon;
  groupTarget?: SimpleGroupTarget;
  name?: string;
}

/** Adds nested create feature */
export interface PatchedDetailedWebhook {
  id?: number;
  /** @format date-time */
  createdAt?: string;
  createdBy?: SimpleUser;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: SimpleUser;
  owner?: SimpleOrganization;
  /** Adds nested create feature */
  defaultReaction?: {
    id: number;
    /** @format date-time */
    createdAt: string;
    createdBy: SimpleUser;
    /** @format date-time */
    updatedAt: string;
    updatedBy: SimpleUser;
    owner: SimpleOrganization;
    pattern: SimplePattern;
    patternId: number;
    trigger: DetailedIntegration;
    /** @default [] */
    targets: {
      id: number;
      beacon: SimpleBeacon;
      groupTarget: SimpleGroupTarget;
    }[];
    minimumInterval: string;
  };
  targetBeaconsCount?: string;
  name?: string;
  /** @format uuid */
  token?: string;
}

export interface PatchedSimpleUser {
  id?: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 128 */
  password?: string;
}

/** User model w/o password */
export interface PatchedUserDetails {
  /** ID */
  pk?: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username?: string;
  /**
   * Email address
   * @format email
   */
  email?: string;
  /** @maxLength 150 */
  firstName?: string;
  /** @maxLength 150 */
  lastName?: string;
}

export interface PrometheusMatchRule {
  label: string;
  value: string;
}

export interface Register {
  /**
   * @minLength 1
   * @maxLength 150
   */
  username: string;
  /** @format email */
  email: string;
  password1: string;
  password2: string;
}

export interface ResendEmailVerification {
  /** @format email */
  email: string;
}

export interface RestAuthDetail {
  detail: string;
}

/** Adds nested create feature */
export interface SimpleBeacon {
  id: number;
  name: string;
  status: "up" | "down";
  hardware: string;
  version: string;
  /** @default 0 */
  pendingOrdersCount: number;
  owner: SimpleOrganization;
}

/** Adds nested create feature */
export interface SimpleGithub {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimpleGitlab {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimpleGitlab2 {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimpleGroupTarget {
  id: number;
  name: string;
  owner: SimpleOrganization;
}

/** Adds nested create feature */
export interface SimpleObservium {
  id: number;
  name: string;
}

export interface SimpleOrganization {
  id: number;
  /**
   * The name of the organization
   * @maxLength 200
   */
  name: string;
  /**
   * The name in all lowercase, suitable for URL identification
   * @maxLength 200
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug: string;
}

/** Adds nested create feature */
export interface SimplePagerDuty {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimplePattern {
  id: number;
  name: string;
  owner: SimpleOrganization;
}

/** Adds nested create feature */
export interface SimplePrometheus {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimpleRobusta {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimpleStatusCake {
  id: number;
  name: string;
}

/** Adds nested create feature */
export interface SimpleTarget {
  id: number;
  beacon: SimpleBeacon;
  groupTarget: SimpleGroupTarget;
}

export interface SimpleUser {
  id: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 128 */
  password: string;
}

/** Adds nested create feature */
export interface SimpleWebhook {
  id: number;
  name: string;
}

export interface TokenRefresh {
  access: string;
  refresh: string;
}

export interface TokenVerify {
  token: string;
}

/** User model w/o password */
export interface UserDetails {
  /** ID */
  pk: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * Email address
   * @format email
   */
  email: string;
  /** @maxLength 150 */
  firstName?: string;
  /** @maxLength 150 */
  lastName?: string;
}

export interface VerifyEmail {
  key: string;
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Blinky API
 * @version 0.0.0
 *
 * API to interact with the Blinky platform
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
     *
     * @tags api
     * @name ApiV1AuthLoginCreate
     * @request POST:/api/v1/auth/login/
     * @secure
     */
    apiV1AuthLoginCreate: (
      data: {
        username?: string;
        /** @format email */
        email?: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          accessToken: string;
          refreshToken: string;
          /** User model w/o password */
          user: {
            /** ID */
            pk: number;
            /**
             * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
             * @maxLength 150
             * @pattern ^[\w.@+-]+$
             */
            username: string;
            /**
             * Email address
             * @format email
             */
            email: string;
            /** @maxLength 150 */
            firstName?: string;
            /** @maxLength 150 */
            lastName?: string;
          };
        },
        any
      >({
        path: `/api/v1/auth/login/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django logout method and delete the Token object assigned to the current User object. Accepts/Returns nothing.
     *
     * @tags api
     * @name ApiV1AuthLogoutCreate
     * @request POST:/api/v1/auth/logout/
     * @secure
     */
    apiV1AuthLogoutCreate: (params: RequestParams = {}) =>
      this.request<
        {
          detail: string;
        },
        any
      >({
        path: `/api/v1/auth/logout/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     *
     * @tags api
     * @name ApiV1AuthPasswordChangeCreate
     * @request POST:/api/v1/auth/password/change/
     * @secure
     */
    apiV1AuthPasswordChangeCreate: (
      data: {
        /** @maxLength 128 */
        newPassword1: string;
        /** @maxLength 128 */
        newPassword2: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          detail: string;
        },
        any
      >({
        path: `/api/v1/auth/password/change/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
     *
     * @tags api
     * @name ApiV1AuthPasswordResetCreate
     * @request POST:/api/v1/auth/password/reset/
     * @secure
     */
    apiV1AuthPasswordResetCreate: (
      data: {
        /** @format email */
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          detail: string;
        },
        any
      >({
        path: `/api/v1/auth/password/reset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Password reset e-mail link is confirmed, therefore this resets the user's password. Accepts the following POST parameters: token, uid, new_password1, new_password2 Returns the success/fail message.
     *
     * @tags api
     * @name ApiV1AuthPasswordResetConfirmCreate
     * @request POST:/api/v1/auth/password/reset/confirm/
     * @secure
     */
    apiV1AuthPasswordResetConfirmCreate: (
      data: {
        /** @maxLength 128 */
        newPassword1: string;
        /** @maxLength 128 */
        newPassword2: string;
        uid: string;
        token: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          detail: string;
        },
        any
      >({
        path: `/api/v1/auth/password/reset/confirm/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1AuthRegistrationCreate
     * @request POST:/api/v1/auth/registration/
     * @secure
     */
    apiV1AuthRegistrationCreate: (
      data: {
        /**
         * @minLength 1
         * @maxLength 150
         */
        username: string;
        /** @format email */
        email: string;
        password1: string;
        password2: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          accessToken: string;
          refreshToken: string;
          /** User model w/o password */
          user: {
            /** ID */
            pk: number;
            /**
             * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
             * @maxLength 150
             * @pattern ^[\w.@+-]+$
             */
            username: string;
            /**
             * Email address
             * @format email
             */
            email: string;
            /** @maxLength 150 */
            firstName?: string;
            /** @maxLength 150 */
            lastName?: string;
          };
        },
        any
      >({
        path: `/api/v1/auth/registration/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1AuthRegistrationResendEmailCreate
     * @request POST:/api/v1/auth/registration/resend-email/
     * @secure
     */
    apiV1AuthRegistrationResendEmailCreate: (
      data: {
        /** @format email */
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          detail: string;
        },
        any
      >({
        path: `/api/v1/auth/registration/resend-email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1AuthRegistrationVerifyEmailCreate
     * @request POST:/api/v1/auth/registration/verify-email/
     * @secure
     */
    apiV1AuthRegistrationVerifyEmailCreate: (
      data: {
        key: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          detail: string;
        },
        any
      >({
        path: `/api/v1/auth/registration/verify-email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags api
     * @name ApiV1AuthTokenRefreshCreate
     * @request POST:/api/v1/auth/token/refresh/
     */
    apiV1AuthTokenRefreshCreate: (
      data: {
        refresh: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          access: string;
          refresh: string;
        },
        any
      >({
        path: `/api/v1/auth/token/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
     *
     * @tags api
     * @name ApiV1AuthTokenVerifyCreate
     * @request POST:/api/v1/auth/token/verify/
     */
    apiV1AuthTokenVerifyCreate: (
      data: {
        token: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/v1/auth/token/verify/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags api
     * @name ApiV1AuthUserRetrieve
     * @request GET:/api/v1/auth/user/
     * @secure
     */
    apiV1AuthUserRetrieve: (params: RequestParams = {}) =>
      this.request<
        {
          /** ID */
          pk: number;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           */
          email: string;
          /** @maxLength 150 */
          firstName?: string;
          /** @maxLength 150 */
          lastName?: string;
        },
        any
      >({
        path: `/api/v1/auth/user/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags api
     * @name ApiV1AuthUserUpdate
     * @request PUT:/api/v1/auth/user/
     * @secure
     */
    apiV1AuthUserUpdate: (
      data: {
        /**
         * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
         * @maxLength 150
         * @pattern ^[\w.@+-]+$
         */
        username: string;
        /** @maxLength 150 */
        firstName?: string;
        /** @maxLength 150 */
        lastName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** ID */
          pk: number;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           */
          email: string;
          /** @maxLength 150 */
          firstName?: string;
          /** @maxLength 150 */
          lastName?: string;
        },
        any
      >({
        path: `/api/v1/auth/user/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags api
     * @name ApiV1AuthUserPartialUpdate
     * @request PATCH:/api/v1/auth/user/
     * @secure
     */
    apiV1AuthUserPartialUpdate: (
      data: {
        /**
         * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
         * @maxLength 150
         * @pattern ^[\w.@+-]+$
         */
        username?: string;
        /** @maxLength 150 */
        firstName?: string;
        /** @maxLength 150 */
        lastName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** ID */
          pk: number;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           */
          email: string;
          /** @maxLength 150 */
          firstName?: string;
          /** @maxLength 150 */
          lastName?: string;
        },
        any
      >({
        path: `/api/v1/auth/user/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1BrokerRetrieve
     * @request GET:/api/v1/broker
     * @secure
     */
    apiV1BrokerRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/broker`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1FirmwareRetrieve
     * @request GET:/api/v1/firmware
     * @secure
     */
    apiV1FirmwareRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/firmware`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1FirmwareVersionRetrieve
     * @request GET:/api/v1/firmware/version
     * @secure
     */
    apiV1FirmwareVersionRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/firmware/version`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestGithubCreate
     * @request POST:/api/v1/ingest/github/{id}
     */
    apiV1IngestGithubCreate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/github/${id}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestGitlabCreate
     * @request POST:/api/v1/ingest/gitlab
     */
    apiV1IngestGitlabCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/gitlab`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestGitlab2Create
     * @request POST:/api/v1/ingest/gitlab2
     */
    apiV1IngestGitlab2Create: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/gitlab2`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestObserviumCreate
     * @request POST:/api/v1/ingest/observium/{token}
     */
    apiV1IngestObserviumCreate: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/observium/${token}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestPagerdutyCreate
     * @request POST:/api/v1/ingest/pagerduty/{token}
     */
    apiV1IngestPagerdutyCreate: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/pagerduty/${token}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestPrometheusCreate
     * @request POST:/api/v1/ingest/prometheus
     * @secure
     */
    apiV1IngestPrometheusCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/prometheus`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestRobustaCreate
     * @request POST:/api/v1/ingest/robusta/{token}
     */
    apiV1IngestRobustaCreate: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/robusta/${token}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestStatuscakeCreate
     * @request POST:/api/v1/ingest/statuscake/{token}
     */
    apiV1IngestStatuscakeCreate: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/statuscake/${token}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestWebhookRetrieve
     * @request GET:/api/v1/ingest/webhook/{token}
     * @secure
     */
    apiV1IngestWebhookRetrieve: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/webhook/${token}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ApiV1IngestWebhookCreate
     * @request POST:/api/v1/ingest/webhook/{token}
     * @secure
     */
    apiV1IngestWebhookCreate: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ingest/webhook/${token}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListOrganizations
     * @request GET:/api/v1/organizations/
     * @secure
     */
    listOrganizations: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /**
             * The name of the organization
             * @maxLength 200
             */
            name: string;
            /**
             * The name in all lowercase, suitable for URL identification
             * @maxLength 200
             * @pattern ^[-a-zA-Z0-9_]+$
             */
            slug: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateOrganization
     * @request POST:/api/v1/organizations/
     * @secure
     */
    createOrganization: (
      data: {
        /**
         * The name of the organization
         * @maxLength 200
         */
        name: string;
        isActive?: boolean;
        /**
         * The name in all lowercase, suitable for URL identification
         * @maxLength 200
         * @pattern ^[-a-zA-Z0-9_]+$
         */
        slug: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          users: string;
          /**
           * The name of the organization
           * @maxLength 200
           */
          name: string;
          isActive?: boolean;
          /** @format date-time */
          created: string;
          /** @format date-time */
          modified: string;
          /**
           * The name in all lowercase, suitable for URL identification
           * @maxLength 200
           * @pattern ^[-a-zA-Z0-9_]+$
           */
          slug: string;
        },
        any
      >({
        path: `/api/v1/organizations/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListBeacons
     * @request GET:/api/v1/organizations/{ownerPk}/beacons/
     * @secure
     */
    listBeacons: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
            status: "up" | "down";
            hardware: string;
            version: string;
            /** @default 0 */
            pendingOrdersCount: number;
            owner: SimpleOrganization;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateBeacon
     * @request POST:/api/v1/organizations/{ownerPk}/beacons/
     * @secure
     */
    createBeacon: (
      ownerPk: number,
      data: {
        name: string;
        status: "up" | "down";
        version: string;
        hardware: string;
        networkId: string;
        statusLedBrightness?: number;
        ceilingInstallation?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default 0 */
          pendingOrdersCount: number;
          orders: string;
          /** @default 0 */
          reactionsCount: number;
          name: string;
          status: "up" | "down";
          version: string;
          hardware: string;
          networkId: string;
          statusLedBrightness?: number;
          ceilingInstallation?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListOrders
     * @request GET:/api/v1/organizations/{ownerPk}/beacons/{beaconPk}/orders/
     * @secure
     */
    listOrders: (
      beaconPk: number,
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            reaction: DetailedReaction;
            trigger: string;
            status: "pending" | "played" | "failed";
            rawPattern: Record<string, any>;
            /** @format date-time */
            lastPublishedAt: string;
            details?: string | null;
            beacon: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${beaconPk}/orders/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateOrder
     * @request POST:/api/v1/organizations/{ownerPk}/beacons/{beaconPk}/orders/
     * @secure
     */
    createOrder: (
      beaconPk: number,
      ownerPk: number,
      data: {
        status: "pending" | "played" | "failed";
        rawPattern: Record<string, any>;
        /** @format date-time */
        lastPublishedAt: string;
        details?: string | null;
        beacon: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          reaction: DetailedReaction;
          trigger: string;
          status: "pending" | "played" | "failed";
          rawPattern: Record<string, any>;
          /** @format date-time */
          lastPublishedAt: string;
          details?: string | null;
          beacon: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${beaconPk}/orders/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveOrder
     * @request GET:/api/v1/organizations/{ownerPk}/beacons/{beaconPk}/orders/{id}/
     * @secure
     */
    retrieveOrder: (beaconPk: number, id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          reaction: DetailedReaction;
          trigger: string;
          status: "pending" | "played" | "failed";
          rawPattern: Record<string, any>;
          /** @format date-time */
          lastPublishedAt: string;
          details?: string | null;
          beacon: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${beaconPk}/orders/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateOrder
     * @request PUT:/api/v1/organizations/{ownerPk}/beacons/{beaconPk}/orders/{id}/
     * @secure
     */
    updateOrder: (
      beaconPk: number,
      id: number,
      ownerPk: number,
      data: {
        status: "pending" | "played" | "failed";
        rawPattern: Record<string, any>;
        /** @format date-time */
        lastPublishedAt: string;
        details?: string | null;
        beacon: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          reaction: DetailedReaction;
          trigger: string;
          status: "pending" | "played" | "failed";
          rawPattern: Record<string, any>;
          /** @format date-time */
          lastPublishedAt: string;
          details?: string | null;
          beacon: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${beaconPk}/orders/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchOrder
     * @request PATCH:/api/v1/organizations/{ownerPk}/beacons/{beaconPk}/orders/{id}/
     * @secure
     */
    patchOrder: (
      beaconPk: number,
      id: number,
      ownerPk: number,
      data: {
        status?: "pending" | "played" | "failed";
        rawPattern?: Record<string, any>;
        /** @format date-time */
        lastPublishedAt?: string;
        details?: string | null;
        beacon?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          reaction: DetailedReaction;
          trigger: string;
          status: "pending" | "played" | "failed";
          rawPattern: Record<string, any>;
          /** @format date-time */
          lastPublishedAt: string;
          details?: string | null;
          beacon: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${beaconPk}/orders/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyOrder
     * @request DELETE:/api/v1/organizations/{ownerPk}/beacons/{beaconPk}/orders/{id}/
     * @secure
     */
    destroyOrder: (beaconPk: number, id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/beacons/${beaconPk}/orders/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveBeacon
     * @request GET:/api/v1/organizations/{ownerPk}/beacons/{id}/
     * @secure
     */
    retrieveBeacon: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default 0 */
          pendingOrdersCount: number;
          orders: string;
          /** @default 0 */
          reactionsCount: number;
          name: string;
          status: "up" | "down";
          version: string;
          hardware: string;
          networkId: string;
          statusLedBrightness?: number;
          ceilingInstallation?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateBeacon
     * @request PUT:/api/v1/organizations/{ownerPk}/beacons/{id}/
     * @secure
     */
    updateBeacon: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
        status: "up" | "down";
        version: string;
        hardware: string;
        networkId: string;
        statusLedBrightness?: number;
        ceilingInstallation?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default 0 */
          pendingOrdersCount: number;
          orders: string;
          /** @default 0 */
          reactionsCount: number;
          name: string;
          status: "up" | "down";
          version: string;
          hardware: string;
          networkId: string;
          statusLedBrightness?: number;
          ceilingInstallation?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchBeacon
     * @request PATCH:/api/v1/organizations/{ownerPk}/beacons/{id}/
     * @secure
     */
    patchBeacon: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
        status?: "up" | "down";
        version?: string;
        hardware?: string;
        networkId?: string;
        statusLedBrightness?: number;
        ceilingInstallation?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default 0 */
          pendingOrdersCount: number;
          orders: string;
          /** @default 0 */
          reactionsCount: number;
          name: string;
          status: "up" | "down";
          version: string;
          hardware: string;
          networkId: string;
          statusLedBrightness?: number;
          ceilingInstallation?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyBeacon
     * @request DELETE:/api/v1/organizations/{ownerPk}/beacons/{id}/
     * @secure
     */
    destroyBeacon: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/beacons/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name GetByNetworkIdBeacon
     * @request GET:/api/v1/organizations/{ownerPk}/beacons/by-network-id/{networkId}/
     * @secure
     */
    getByNetworkIdBeacon: (networkId: string, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default 0 */
          pendingOrdersCount: number;
          orders: string;
          /** @default 0 */
          reactionsCount: number;
          name: string;
          status: "up" | "down";
          version: string;
          hardware: string;
          networkId: string;
          statusLedBrightness?: number;
          ceilingInstallation?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/by-network-id/${networkId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PreviewUpdateBeacon
     * @request PATCH:/api/v1/organizations/{ownerPk}/beacons/preview-update/{id}/
     * @secure
     */
    previewUpdateBeacon: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
        status?: "up" | "down";
        version?: string;
        hardware?: string;
        networkId?: string;
        statusLedBrightness?: number;
        ceilingInstallation?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default 0 */
          pendingOrdersCount: number;
          orders: string;
          /** @default 0 */
          reactionsCount: number;
          name: string;
          status: "up" | "down";
          version: string;
          hardware: string;
          networkId: string;
          statusLedBrightness?: number;
          ceilingInstallation?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/beacons/preview-update/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name SendConfigurationBeacon
     * @request PATCH:/api/v1/organizations/{ownerPk}/beacons/send-configuration/{id}/
     * @secure
     */
    sendConfigurationBeacon: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/beacons/send-configuration/${id}/`,
        method: "PATCH",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListGroupTargets
     * @request GET:/api/v1/organizations/{ownerPk}/group_targets/
     * @secure
     */
    listGroupTargets: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
            owner: SimpleOrganization;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateGroupTarget
     * @request POST:/api/v1/organizations/{ownerPk}/group_targets/
     * @secure
     */
    createGroupTarget: (
      ownerPk: number,
      data: {
        /** @default [] */
        targets?: {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        }[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default [] */
          targets?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
            name: string;
          }[];
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveGroupTarget
     * @request GET:/api/v1/organizations/{ownerPk}/group_targets/{id}/
     * @secure
     */
    retrieveGroupTarget: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default [] */
          targets?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
            name: string;
          }[];
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateGroupTarget
     * @request PUT:/api/v1/organizations/{ownerPk}/group_targets/{id}/
     * @secure
     */
    updateGroupTarget: (
      id: number,
      ownerPk: number,
      data: {
        /** @default [] */
        targets?: {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        }[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default [] */
          targets?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
            name: string;
          }[];
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchGroupTarget
     * @request PATCH:/api/v1/organizations/{ownerPk}/group_targets/{id}/
     * @secure
     */
    patchGroupTarget: (
      id: number,
      ownerPk: number,
      data: {
        /** @default [] */
        targets?: {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        }[];
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** @default [] */
          targets?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
            name: string;
          }[];
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyGroupTarget
     * @request DELETE:/api/v1/organizations/{ownerPk}/group_targets/{id}/
     * @secure
     */
    destroyGroupTarget: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/group_targets/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name LinkToGroupTarget
     * @request GET:/api/v1/organizations/{ownerPk}/group_targets/{id}/link/{targetPk}/
     * @secure
     */
    linkToGroupTarget: (id: number, ownerPk: number, targetPk: string, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          name: string;
          owner: SimpleOrganization;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/${id}/link/${targetPk}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UnlinkFromGroupTarget
     * @request GET:/api/v1/organizations/{ownerPk}/group_targets/{id}/unlink/{targetPk}/
     * @secure
     */
    unlinkFromGroupTarget: (id: number, ownerPk: number, targetPk: string, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          name: string;
          owner: SimpleOrganization;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/group_targets/${id}/unlink/${targetPk}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListIntegrations
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/
     * @secure
     */
    listIntegrations: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
            kind: string;
            impl: string;
            owner: SimpleOrganization;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateIntegration
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/
     * @secure
     */
    createIntegration: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          name: string;
          kind: string;
          impl: string;
          owner: SimpleOrganization;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveIntegration
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/{id}/
     * @secure
     */
    retrieveIntegration: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          name: string;
          kind: string;
          impl: string;
          owner: SimpleOrganization;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateIntegration
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/{id}/
     * @secure
     */
    updateIntegration: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          name: string;
          kind: string;
          impl: string;
          owner: SimpleOrganization;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchIntegration
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/{id}/
     * @secure
     */
    patchIntegration: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          name: string;
          kind: string;
          impl: string;
          owner: SimpleOrganization;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyIntegration
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/{id}/
     * @secure
     */
    destroyIntegration: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListGithubs
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/githubs/
     * @secure
     */
    listGithubs: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/githubs/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateGithub
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/githubs/
     * @secure
     */
    createGithub: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        pushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        tagPushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pullRequestOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pullRequestCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        checkSuiteCompletedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        checkSuiteFailedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteCompletedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteFailedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/githubs/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveGithub
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/githubs/{id}/
     * @secure
     */
    retrieveGithub: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteCompletedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteFailedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/githubs/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateGithub
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/githubs/{id}/
     * @secure
     */
    updateGithub: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        pushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        tagPushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pullRequestOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pullRequestCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        checkSuiteCompletedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        checkSuiteFailedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteCompletedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteFailedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/githubs/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchGithub
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/githubs/{id}/
     * @secure
     */
    patchGithub: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        pushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        tagPushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pullRequestOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pullRequestCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        checkSuiteCompletedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        checkSuiteFailedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pullRequestCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteCompletedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          checkSuiteFailedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/githubs/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyGithub
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/githubs/{id}/
     * @secure
     */
    destroyGithub: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/githubs/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListGitlab2S
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/gitlab2/
     * @secure
     */
    listGitlab2S: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateGitlab2
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/gitlab2/
     * @secure
     */
    createGitlab2: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListGitlab2Matches
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/gitlab2-matches/
     * @secure
     */
    listGitlab2Matches: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            /** Adds nested create feature */
            reaction: {
              id: number;
              /** @format date-time */
              createdAt: string;
              createdBy: SimpleUser;
              /** @format date-time */
              updatedAt: string;
              updatedBy: SimpleUser;
              owner: SimpleOrganization;
              pattern: SimplePattern;
              patternId: number;
              trigger: DetailedIntegration;
              /** @default [] */
              targets: {
                id: number;
                beacon: SimpleBeacon;
                groupTarget: SimpleGroupTarget;
              }[];
              minimumInterval: string;
            };
            filters: {
              key: string;
              op: "eq" | "neq" | "regex" | "nregex";
              value: string;
            }[];
            name: string;
            /** @maxLength 255 */
            event?: string;
            gitlab2: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateGitlab2Match
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/gitlab2-matches/
     * @secure
     */
    createGitlab2Match: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        /** @maxLength 255 */
        event?: string;
        gitlab2: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          /** @maxLength 255 */
          event?: string;
          gitlab2: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2-matches/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveGitlab2Match
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/gitlab2-matches/{id}/
     * @secure
     */
    retrieveGitlab2Match: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          /** @maxLength 255 */
          event?: string;
          gitlab2: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2-matches/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateGitlab2Match
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/gitlab2-matches/{id}/
     * @secure
     */
    updateGitlab2Match: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        /** @maxLength 255 */
        event?: string;
        gitlab2: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          /** @maxLength 255 */
          event?: string;
          gitlab2: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2-matches/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchGitlab2Match
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/gitlab2-matches/{id}/
     * @secure
     */
    patchGitlab2Match: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction?: {
          patternId: number;
          minimumInterval: string;
        };
        filters?: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name?: string;
        /** @maxLength 255 */
        event?: string;
        gitlab2?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          /** @maxLength 255 */
          event?: string;
          gitlab2: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2-matches/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyGitlab2Match
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/gitlab2-matches/{id}/
     * @secure
     */
    destroyGitlab2Match: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2-matches/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveGitlab2
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/gitlab2/{id}/
     * @secure
     */
    retrieveGitlab2: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateGitlab2
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/gitlab2/{id}/
     * @secure
     */
    updateGitlab2: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchGitlab2
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/gitlab2/{id}/
     * @secure
     */
    patchGitlab2: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyGitlab2
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/gitlab2/{id}/
     * @secure
     */
    destroyGitlab2: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlab2/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListGitlabs
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/gitlabs/
     * @secure
     */
    listGitlabs: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlabs/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateGitlab
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/gitlabs/
     * @secure
     */
    createGitlab: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        pushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        tagPushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pipelineSuccessReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pipelineFailureReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineSuccessReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineFailureReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlabs/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveGitlab
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/gitlabs/{id}/
     * @secure
     */
    retrieveGitlab: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineSuccessReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineFailureReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlabs/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateGitlab
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/gitlabs/{id}/
     * @secure
     */
    updateGitlab: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        pushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        tagPushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pipelineSuccessReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pipelineFailureReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineSuccessReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineFailureReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlabs/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchGitlab
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/gitlabs/{id}/
     * @secure
     */
    patchGitlab: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        pushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        tagPushReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueOpenedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        issueCommentedReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pipelineSuccessReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        /** Adds nested create feature */
        pipelineFailureReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          pushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          tagPushReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueOpenedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          issueCommentedReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineSuccessReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          /** Adds nested create feature */
          pipelineFailureReaction?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlabs/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyGitlab
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/gitlabs/{id}/
     * @secure
     */
    destroyGitlab: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/gitlabs/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListObserviums
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/observium/
     * @secure
     */
    listObserviums: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateObservium
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/observium/
     * @secure
     */
    createObservium: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListObserviummatches
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/observium-matches/
     * @secure
     */
    listObserviummatches: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            /** Adds nested create feature */
            reaction: {
              id: number;
              /** @format date-time */
              createdAt: string;
              createdBy: SimpleUser;
              /** @format date-time */
              updatedAt: string;
              updatedBy: SimpleUser;
              owner: SimpleOrganization;
              pattern: SimplePattern;
              patternId: number;
              trigger: DetailedIntegration;
              /** @default [] */
              targets: {
                id: number;
                beacon: SimpleBeacon;
                groupTarget: SimpleGroupTarget;
              }[];
              minimumInterval: string;
            };
            filters: {
              key: string;
              op: "eq" | "neq" | "regex" | "nregex";
              value: string;
            }[];
            name: string;
            triggerOnFire: boolean;
            triggerOnRecover: boolean;
            triggerOnReminder: boolean;
            observium: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateObserviumMatch
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/observium-matches/
     * @secure
     */
    createObserviumMatch: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        triggerOnFire: boolean;
        triggerOnRecover: boolean;
        triggerOnReminder: boolean;
        observium: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          triggerOnFire: boolean;
          triggerOnRecover: boolean;
          triggerOnReminder: boolean;
          observium: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium-matches/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveObserviumMatch
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/observium-matches/{id}/
     * @secure
     */
    retrieveObserviumMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          triggerOnFire: boolean;
          triggerOnRecover: boolean;
          triggerOnReminder: boolean;
          observium: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium-matches/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateObserviumMatch
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/observium-matches/{id}/
     * @secure
     */
    updateObserviumMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        triggerOnFire: boolean;
        triggerOnRecover: boolean;
        triggerOnReminder: boolean;
        observium: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          triggerOnFire: boolean;
          triggerOnRecover: boolean;
          triggerOnReminder: boolean;
          observium: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium-matches/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchObserviumMatch
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/observium-matches/{id}/
     * @secure
     */
    patchObserviumMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction?: {
          patternId: number;
          minimumInterval: string;
        };
        filters?: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name?: string;
        triggerOnFire?: boolean;
        triggerOnRecover?: boolean;
        triggerOnReminder?: boolean;
        observium?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          triggerOnFire: boolean;
          triggerOnRecover: boolean;
          triggerOnReminder: boolean;
          observium: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium-matches/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyObserviumMatch
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/observium-matches/{id}/
     * @secure
     */
    destroyObserviumMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium-matches/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveObservium
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/observium/{id}/
     * @secure
     */
    retrieveObservium: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateObservium
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/observium/{id}/
     * @secure
     */
    updateObservium: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchObservium
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/observium/{id}/
     * @secure
     */
    patchObservium: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyObservium
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/observium/{id}/
     * @secure
     */
    destroyObservium: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/observium/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListPagerDutys
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/pagerduty/
     * @secure
     */
    listPagerDutys: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreatePagerDuty
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/pagerduty/
     * @secure
     */
    createPagerDuty: (
      ownerPk: number,
      data: {
        name: string;
        signingSecret?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
          signingSecret?: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListPagerdutymatches
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/pagerduty-matches/
     * @secure
     */
    listPagerdutymatches: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            /** Adds nested create feature */
            reaction: {
              id: number;
              /** @format date-time */
              createdAt: string;
              createdBy: SimpleUser;
              /** @format date-time */
              updatedAt: string;
              updatedBy: SimpleUser;
              owner: SimpleOrganization;
              pattern: SimplePattern;
              patternId: number;
              trigger: DetailedIntegration;
              /** @default [] */
              targets: {
                id: number;
                beacon: SimpleBeacon;
                groupTarget: SimpleGroupTarget;
              }[];
              minimumInterval: string;
            };
            filters: {
              key: string;
              op: "eq" | "neq" | "regex" | "nregex";
              value: string;
            }[];
            name: string;
            events?: Record<string, any>;
            pagerduty: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreatePagerDutyMatch
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/pagerduty-matches/
     * @secure
     */
    createPagerDutyMatch: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        events?: Record<string, any>;
        pagerduty: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          events?: Record<string, any>;
          pagerduty: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty-matches/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrievePagerDutyMatch
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/pagerduty-matches/{id}/
     * @secure
     */
    retrievePagerDutyMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          events?: Record<string, any>;
          pagerduty: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty-matches/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdatePagerDutyMatch
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/pagerduty-matches/{id}/
     * @secure
     */
    updatePagerDutyMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        events?: Record<string, any>;
        pagerduty: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          events?: Record<string, any>;
          pagerduty: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty-matches/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchPagerDutyMatch
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/pagerduty-matches/{id}/
     * @secure
     */
    patchPagerDutyMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction?: {
          patternId: number;
          minimumInterval: string;
        };
        filters?: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name?: string;
        events?: Record<string, any>;
        pagerduty?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          events?: Record<string, any>;
          pagerduty: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty-matches/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyPagerDutyMatch
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/pagerduty-matches/{id}/
     * @secure
     */
    destroyPagerDutyMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty-matches/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrievePagerDuty
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/pagerduty/{id}/
     * @secure
     */
    retrievePagerDuty: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
          signingSecret?: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdatePagerDuty
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/pagerduty/{id}/
     * @secure
     */
    updatePagerDuty: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
        signingSecret?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
          signingSecret?: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchPagerDuty
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/pagerduty/{id}/
     * @secure
     */
    patchPagerDuty: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
        signingSecret?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
          signingSecret?: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyPagerDuty
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/pagerduty/{id}/
     * @secure
     */
    destroyPagerDuty: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/pagerduty/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListPrometheis
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/prometheus/
     * @secure
     */
    listPrometheis: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreatePrometheus
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/prometheus/
     * @secure
     */
    createPrometheus: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListPrometheusmatches
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/prometheus-matches/
     * @secure
     */
    listPrometheusmatches: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            /** Adds nested create feature */
            reaction: {
              id: number;
              /** @format date-time */
              createdAt: string;
              createdBy: SimpleUser;
              /** @format date-time */
              updatedAt: string;
              updatedBy: SimpleUser;
              owner: SimpleOrganization;
              pattern: SimplePattern;
              patternId: number;
              trigger: DetailedIntegration;
              /** @default [] */
              targets: {
                id: number;
                beacon: SimpleBeacon;
                groupTarget: SimpleGroupTarget;
              }[];
              minimumInterval: string;
            };
            rules: {
              label: string;
              value: string;
            }[];
            name: string;
            isFiredOnResolve: boolean;
            prometheus: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreatePrometheusMatch
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/prometheus-matches/
     * @secure
     */
    createPrometheusMatch: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        rules: {
          label: string;
          value: string;
        }[];
        name: string;
        isFiredOnResolve: boolean;
        prometheus: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          rules: {
            label: string;
            value: string;
          }[];
          name: string;
          isFiredOnResolve: boolean;
          prometheus: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus-matches/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrievePrometheusMatch
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/prometheus-matches/{id}/
     * @secure
     */
    retrievePrometheusMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          rules: {
            label: string;
            value: string;
          }[];
          name: string;
          isFiredOnResolve: boolean;
          prometheus: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus-matches/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdatePrometheusMatch
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/prometheus-matches/{id}/
     * @secure
     */
    updatePrometheusMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        rules: {
          label: string;
          value: string;
        }[];
        name: string;
        isFiredOnResolve: boolean;
        prometheus: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          rules: {
            label: string;
            value: string;
          }[];
          name: string;
          isFiredOnResolve: boolean;
          prometheus: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus-matches/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchPrometheusMatch
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/prometheus-matches/{id}/
     * @secure
     */
    patchPrometheusMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction?: {
          patternId: number;
          minimumInterval: string;
        };
        rules?: {
          label: string;
          value: string;
        }[];
        name?: string;
        isFiredOnResolve?: boolean;
        prometheus?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          rules: {
            label: string;
            value: string;
          }[];
          name: string;
          isFiredOnResolve: boolean;
          prometheus: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus-matches/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyPrometheusMatch
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/prometheus-matches/{id}/
     * @secure
     */
    destroyPrometheusMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus-matches/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrievePrometheus
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/prometheus/{id}/
     * @secure
     */
    retrievePrometheus: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdatePrometheus
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/prometheus/{id}/
     * @secure
     */
    updatePrometheus: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchPrometheus
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/prometheus/{id}/
     * @secure
     */
    patchPrometheus: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyPrometheus
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/prometheus/{id}/
     * @secure
     */
    destroyPrometheus: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/prometheus/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListRobustas
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/robusta/
     * @secure
     */
    listRobustas: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateRobusta
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/robusta/
     * @secure
     */
    createRobusta: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListRobustamatches
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/robusta-matches/
     * @secure
     */
    listRobustamatches: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            /** Adds nested create feature */
            reaction: {
              id: number;
              /** @format date-time */
              createdAt: string;
              createdBy: SimpleUser;
              /** @format date-time */
              updatedAt: string;
              updatedBy: SimpleUser;
              owner: SimpleOrganization;
              pattern: SimplePattern;
              patternId: number;
              trigger: DetailedIntegration;
              /** @default [] */
              targets: {
                id: number;
                beacon: SimpleBeacon;
                groupTarget: SimpleGroupTarget;
              }[];
              minimumInterval: string;
            };
            filters: {
              key: string;
              op: "eq" | "neq" | "regex" | "nregex";
              value: string;
            }[];
            name: string;
            robusta: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateRobustaMatch
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/robusta-matches/
     * @secure
     */
    createRobustaMatch: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        robusta: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          robusta: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta-matches/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveRobustaMatch
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/robusta-matches/{id}/
     * @secure
     */
    retrieveRobustaMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          robusta: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta-matches/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateRobustaMatch
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/robusta-matches/{id}/
     * @secure
     */
    updateRobustaMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        filters: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name: string;
        robusta: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          robusta: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta-matches/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchRobustaMatch
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/robusta-matches/{id}/
     * @secure
     */
    patchRobustaMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction?: {
          patternId: number;
          minimumInterval: string;
        };
        filters?: {
          key: string;
          op: "eq" | "neq" | "regex" | "nregex";
          value: string;
        }[];
        name?: string;
        robusta?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          filters: {
            key: string;
            op: "eq" | "neq" | "regex" | "nregex";
            value: string;
          }[];
          name: string;
          robusta: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta-matches/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyRobustaMatch
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/robusta-matches/{id}/
     * @secure
     */
    destroyRobustaMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta-matches/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveRobusta
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/robusta/{id}/
     * @secure
     */
    retrieveRobusta: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateRobusta
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/robusta/{id}/
     * @secure
     */
    updateRobusta: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchRobusta
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/robusta/{id}/
     * @secure
     */
    patchRobusta: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyRobusta
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/robusta/{id}/
     * @secure
     */
    destroyRobusta: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/robusta/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListStatusCakes
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/statuscake/
     * @secure
     */
    listStatusCakes: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateStatusCake
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/statuscake/
     * @secure
     */
    createStatusCake: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListStatuscakematches
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/statuscake-matches/
     * @secure
     */
    listStatuscakematches: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            /** Adds nested create feature */
            reaction: {
              id: number;
              /** @format date-time */
              createdAt: string;
              createdBy: SimpleUser;
              /** @format date-time */
              updatedAt: string;
              updatedBy: SimpleUser;
              owner: SimpleOrganization;
              pattern: SimplePattern;
              patternId: number;
              trigger: DetailedIntegration;
              /** @default [] */
              targets: {
                id: number;
                beacon: SimpleBeacon;
                groupTarget: SimpleGroupTarget;
              }[];
              minimumInterval: string;
            };
            tagsFilter?: string[];
            statusCodeFilter?: string;
            name: string;
            isFiredOnResolve: boolean;
            statuscake: number;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateStatusCakeMatch
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/statuscake-matches/
     * @secure
     */
    createStatusCakeMatch: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        tagsFilter?: string[];
        statusCodeFilter?: string;
        name: string;
        isFiredOnResolve: boolean;
        statuscake: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          tagsFilter?: string[];
          statusCodeFilter?: string;
          name: string;
          isFiredOnResolve: boolean;
          statuscake: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake-matches/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveStatusCakeMatch
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/statuscake-matches/{id}/
     * @secure
     */
    retrieveStatusCakeMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          tagsFilter?: string[];
          statusCodeFilter?: string;
          name: string;
          isFiredOnResolve: boolean;
          statuscake: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake-matches/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateStatusCakeMatch
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/statuscake-matches/{id}/
     * @secure
     */
    updateStatusCakeMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction: {
          patternId: number;
          minimumInterval: string;
        };
        tagsFilter?: string[];
        statusCodeFilter?: string;
        name: string;
        isFiredOnResolve: boolean;
        statuscake: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          tagsFilter?: string[];
          statusCodeFilter?: string;
          name: string;
          isFiredOnResolve: boolean;
          statuscake: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake-matches/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchStatusCakeMatch
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/statuscake-matches/{id}/
     * @secure
     */
    patchStatusCakeMatch: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        reaction?: {
          patternId: number;
          minimumInterval: string;
        };
        tagsFilter?: string[];
        statusCodeFilter?: string;
        name?: string;
        isFiredOnResolve?: boolean;
        statuscake?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          reaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          tagsFilter?: string[];
          statusCodeFilter?: string;
          name: string;
          isFiredOnResolve: boolean;
          statuscake: number;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake-matches/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyStatusCakeMatch
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/statuscake-matches/{id}/
     * @secure
     */
    destroyStatusCakeMatch: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake-matches/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveStatusCake
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/statuscake/{id}/
     * @secure
     */
    retrieveStatusCake: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateStatusCake
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/statuscake/{id}/
     * @secure
     */
    updateStatusCake: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchStatusCake
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/statuscake/{id}/
     * @secure
     */
    patchStatusCake: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          matches: string;
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyStatusCake
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/statuscake/{id}/
     * @secure
     */
    destroyStatusCake: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/statuscake/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListWebhooks
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/webhooks/
     * @secure
     */
    listWebhooks: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/webhooks/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateWebhook
     * @request POST:/api/v1/organizations/{ownerPk}/integrations/webhooks/
     * @secure
     */
    createWebhook: (
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        defaultReaction: {
          patternId: number;
          minimumInterval: string;
        };
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          defaultReaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/webhooks/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveWebhook
     * @request GET:/api/v1/organizations/{ownerPk}/integrations/webhooks/{id}/
     * @secure
     */
    retrieveWebhook: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          defaultReaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/webhooks/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateWebhook
     * @request PUT:/api/v1/organizations/{ownerPk}/integrations/webhooks/{id}/
     * @secure
     */
    updateWebhook: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        defaultReaction: {
          patternId: number;
          minimumInterval: string;
        };
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          defaultReaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/webhooks/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchWebhook
     * @request PATCH:/api/v1/organizations/{ownerPk}/integrations/webhooks/{id}/
     * @secure
     */
    patchWebhook: (
      id: number,
      ownerPk: number,
      data: {
        /** Adds nested create feature */
        defaultReaction?: {
          patternId: number;
          minimumInterval: string;
        };
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          /** Adds nested create feature */
          defaultReaction: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          };
          targetBeaconsCount: string;
          name: string;
          /** @format uuid */
          token: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/integrations/webhooks/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyWebhook
     * @request DELETE:/api/v1/organizations/{ownerPk}/integrations/webhooks/{id}/
     * @secure
     */
    destroyWebhook: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/integrations/webhooks/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListPatterns
     * @request GET:/api/v1/organizations/{ownerPk}/patterns/
     * @secure
     */
    listPatterns: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            name: string;
            owner: SimpleOrganization;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/patterns/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreatePattern
     * @request POST:/api/v1/organizations/{ownerPk}/patterns/
     * @secure
     */
    createPattern: (
      ownerPk: number,
      data: {
        name: string;
        raw: Record<string, any>;
        isTemplate?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          name: string;
          raw: Record<string, any>;
          isTemplate?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/patterns/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrievePattern
     * @request GET:/api/v1/organizations/{ownerPk}/patterns/{id}/
     * @secure
     */
    retrievePattern: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          name: string;
          raw: Record<string, any>;
          isTemplate?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/patterns/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdatePattern
     * @request PUT:/api/v1/organizations/{ownerPk}/patterns/{id}/
     * @secure
     */
    updatePattern: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
        raw: Record<string, any>;
        isTemplate?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          name: string;
          raw: Record<string, any>;
          isTemplate?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/patterns/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchPattern
     * @request PATCH:/api/v1/organizations/{ownerPk}/patterns/{id}/
     * @secure
     */
    patchPattern: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
        raw?: Record<string, any>;
        isTemplate?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          name: string;
          raw: Record<string, any>;
          isTemplate?: boolean;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/patterns/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyPattern
     * @request DELETE:/api/v1/organizations/{ownerPk}/patterns/{id}/
     * @secure
     */
    destroyPattern: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/patterns/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListReactions
     * @request GET:/api/v1/organizations/{ownerPk}/reactions/
     * @secure
     */
    listReactions: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /** @format date-time */
            createdAt: string;
            createdBy: SimpleUser;
            /** @format date-time */
            updatedAt: string;
            updatedBy: SimpleUser;
            owner: SimpleOrganization;
            pattern: SimplePattern;
            patternId: number;
            trigger: DetailedIntegration;
            /** @default [] */
            targets: {
              id: number;
              beacon: SimpleBeacon;
              groupTarget: SimpleGroupTarget;
            }[];
            minimumInterval: string;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateReaction
     * @request POST:/api/v1/organizations/{ownerPk}/reactions/
     * @secure
     */
    createReaction: (
      ownerPk: number,
      data: {
        patternId: number;
        minimumInterval: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          pattern: SimplePattern;
          trigger: DetailedIntegration;
          /** @default [] */
          targets: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
          minimumInterval: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveReaction
     * @request GET:/api/v1/organizations/{ownerPk}/reactions/{id}/
     * @secure
     */
    retrieveReaction: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          pattern: SimplePattern;
          trigger: DetailedIntegration;
          /** @default [] */
          targets: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
          minimumInterval: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateReaction
     * @request PUT:/api/v1/organizations/{ownerPk}/reactions/{id}/
     * @secure
     */
    updateReaction: (
      id: number,
      ownerPk: number,
      data: {
        patternId: number;
        minimumInterval: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          pattern: SimplePattern;
          trigger: DetailedIntegration;
          /** @default [] */
          targets: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
          minimumInterval: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchReaction
     * @request PATCH:/api/v1/organizations/{ownerPk}/reactions/{id}/
     * @secure
     */
    patchReaction: (
      id: number,
      ownerPk: number,
      data: {
        patternId?: number;
        minimumInterval?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          pattern: SimplePattern;
          trigger: DetailedIntegration;
          /** @default [] */
          targets: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
          minimumInterval: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyReaction
     * @request DELETE:/api/v1/organizations/{ownerPk}/reactions/{id}/
     * @secure
     */
    destroyReaction: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/reactions/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name LinkToReaction
     * @request GET:/api/v1/organizations/{ownerPk}/reactions/{id}/link/{targetPk}/
     * @secure
     */
    linkToReaction: (id: number, ownerPk: number, targetPk: string, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          pattern: SimplePattern;
          trigger: DetailedIntegration;
          /** @default [] */
          targets: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
          minimumInterval: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/${id}/link/${targetPk}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UnlinkFromReaction
     * @request GET:/api/v1/organizations/{ownerPk}/reactions/{id}/unlink/{targetPk}/
     * @secure
     */
    unlinkFromReaction: (id: number, ownerPk: number, targetPk: string, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          pattern: SimplePattern;
          trigger: DetailedIntegration;
          /** @default [] */
          targets: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
          minimumInterval: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/reactions/${id}/unlink/${targetPk}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListTargets
     * @request GET:/api/v1/organizations/{ownerPk}/targets/
     * @secure
     */
    listTargets: (
      ownerPk: number,
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            beacon: SimpleBeacon;
            groupTarget: SimpleGroupTarget;
          }[];
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/targets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateTarget
     * @request POST:/api/v1/organizations/{ownerPk}/targets/
     * @secure
     */
    createTarget: (
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/targets/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveTarget
     * @request GET:/api/v1/organizations/{ownerPk}/targets/{id}/
     * @secure
     */
    retrieveTarget: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/targets/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateTarget
     * @request PUT:/api/v1/organizations/{ownerPk}/targets/{id}/
     * @secure
     */
    updateTarget: (
      id: number,
      ownerPk: number,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/targets/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchTarget
     * @request PATCH:/api/v1/organizations/{ownerPk}/targets/{id}/
     * @secure
     */
    patchTarget: (
      id: number,
      ownerPk: number,
      data: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          createdAt: string;
          createdBy: SimpleUser;
          /** @format date-time */
          updatedAt: string;
          updatedBy: SimpleUser;
          owner: SimpleOrganization;
          beacon: SimpleBeacon;
          groupTarget: SimpleGroupTarget;
          name: string;
        },
        any
      >({
        path: `/api/v1/organizations/${ownerPk}/targets/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyTarget
     * @request DELETE:/api/v1/organizations/{ownerPk}/targets/{id}/
     * @secure
     */
    destroyTarget: (id: number, ownerPk: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${ownerPk}/targets/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveOrganization
     * @request GET:/api/v1/organizations/{id}/
     * @secure
     */
    retrieveOrganization: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          users: string;
          /**
           * The name of the organization
           * @maxLength 200
           */
          name: string;
          isActive?: boolean;
          /** @format date-time */
          created: string;
          /** @format date-time */
          modified: string;
          /**
           * The name in all lowercase, suitable for URL identification
           * @maxLength 200
           * @pattern ^[-a-zA-Z0-9_]+$
           */
          slug: string;
        },
        any
      >({
        path: `/api/v1/organizations/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateOrganization
     * @request PUT:/api/v1/organizations/{id}/
     * @secure
     */
    updateOrganization: (
      id: number,
      data: {
        /**
         * The name of the organization
         * @maxLength 200
         */
        name: string;
        isActive?: boolean;
        /**
         * The name in all lowercase, suitable for URL identification
         * @maxLength 200
         * @pattern ^[-a-zA-Z0-9_]+$
         */
        slug: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          users: string;
          /**
           * The name of the organization
           * @maxLength 200
           */
          name: string;
          isActive?: boolean;
          /** @format date-time */
          created: string;
          /** @format date-time */
          modified: string;
          /**
           * The name in all lowercase, suitable for URL identification
           * @maxLength 200
           * @pattern ^[-a-zA-Z0-9_]+$
           */
          slug: string;
        },
        any
      >({
        path: `/api/v1/organizations/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchOrganization
     * @request PATCH:/api/v1/organizations/{id}/
     * @secure
     */
    patchOrganization: (
      id: number,
      data: {
        /**
         * The name of the organization
         * @maxLength 200
         */
        name?: string;
        isActive?: boolean;
        /**
         * The name in all lowercase, suitable for URL identification
         * @maxLength 200
         * @pattern ^[-a-zA-Z0-9_]+$
         */
        slug?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          users: string;
          /**
           * The name of the organization
           * @maxLength 200
           */
          name: string;
          isActive?: boolean;
          /** @format date-time */
          created: string;
          /** @format date-time */
          modified: string;
          /**
           * The name in all lowercase, suitable for URL identification
           * @maxLength 200
           * @pattern ^[-a-zA-Z0-9_]+$
           */
          slug: string;
        },
        any
      >({
        path: `/api/v1/organizations/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyOrganization
     * @request DELETE:/api/v1/organizations/{id}/
     * @secure
     */
    destroyOrganization: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DowngradeOrganization
     * @request POST:/api/v1/organizations/{id}/downgrade/
     * @secure
     */
    downgradeOrganization: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${id}/downgrade/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name FireOrganization
     * @request POST:/api/v1/organizations/{id}/fire/
     * @secure
     */
    fireOrganization: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${id}/fire/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name InviteOrganization
     * @request POST:/api/v1/organizations/{id}/invite/
     * @secure
     */
    inviteOrganization: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${id}/invite/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PromoteOrganization
     * @request POST:/api/v1/organizations/{id}/promote/
     * @secure
     */
    promoteOrganization: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/organizations/${id}/promote/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name ListUsers
     * @request GET:/api/v1/users/
     * @secure
     */
    listUsers: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Which field to use when ordering the results. */
        ordering?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example 123 */
          count?: number;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=400&limit=100"
           */
          next?: string | null;
          /**
           * @format uri
           * @example "http://api.example.org/accounts/?offset=200&limit=100"
           */
          previous?: string | null;
          results?: {
            id: number;
            /**
             * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
             * @maxLength 150
             * @pattern ^[\w.@+-]+$
             */
            username: string;
            /**
             * Email address
             * @format email
             * @maxLength 254
             */
            email?: string;
            /** @maxLength 128 */
            password: string;
          }[];
        },
        any
      >({
        path: `/api/v1/users/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name CreateUser
     * @request POST:/api/v1/users/
     * @secure
     */
    createUser: (
      data: {
        /**
         * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
         * @maxLength 150
         * @pattern ^[\w.@+-]+$
         */
        username: string;
        /**
         * Email address
         * @format email
         * @maxLength 254
         */
        email?: string;
        /** @maxLength 128 */
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           * @maxLength 254
           */
          email?: string;
        },
        any
      >({
        path: `/api/v1/users/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name RetrieveUser
     * @request GET:/api/v1/users/{id}/
     * @secure
     */
    retrieveUser: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
          /** @format date-time */
          lastLogin?: string | null;
          /**
           * Superuser status
           * Designates that this user has all permissions without explicitly assigning them.
           */
          isSuperuser?: boolean;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           * @maxLength 254
           */
          email?: string;
          /**
           * Staff status
           * Designates whether the user can log into this admin site.
           */
          isStaff?: boolean;
          /**
           * Active
           * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
           */
          isActive?: boolean;
          /** @format date-time */
          dateJoined?: string;
        },
        any
      >({
        path: `/api/v1/users/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name UpdateUser
     * @request PUT:/api/v1/users/{id}/
     * @secure
     */
    updateUser: (
      id: number,
      data: {
        /**
         * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
         * @maxLength 150
         * @pattern ^[\w.@+-]+$
         */
        username: string;
        /**
         * Email address
         * @format email
         * @maxLength 254
         */
        email?: string;
        /** @maxLength 128 */
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           * @maxLength 254
           */
          email?: string;
        },
        any
      >({
        path: `/api/v1/users/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name PatchUser
     * @request PATCH:/api/v1/users/{id}/
     * @secure
     */
    patchUser: (
      id: number,
      data: {
        /**
         * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
         * @maxLength 150
         * @pattern ^[\w.@+-]+$
         */
        username?: string;
        /**
         * Email address
         * @format email
         * @maxLength 254
         */
        email?: string;
        /** @maxLength 128 */
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: number;
          /**
           * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
           * @maxLength 150
           * @pattern ^[\w.@+-]+$
           */
          username: string;
          /**
           * Email address
           * @format email
           * @maxLength 254
           */
          email?: string;
        },
        any
      >({
        path: `/api/v1/users/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags api
     * @name DestroyUser
     * @request DELETE:/api/v1/users/{id}/
     * @secure
     */
    destroyUser: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
}
