import i18next from 'i18next';
import { Box, Button, Flex, Heading, Icon } from '@chakra-ui/react';
import { FiChevronRight, FiZap } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import BeaconPreview from '../beacon/BeaconPreview';
import RemotePatternPlayer from './RemotePatternPlayer';
import useSession from '../../hooks/useSession';
import { SimplePattern } from '../../api/client';

type PatternListProps = {
	patterns: SimplePattern[];
	spacing: string;
	link?: boolean;
	onSelect?: (id: number) => void;
};

function PatternList({ patterns, spacing, link, onSelect }: PatternListProps): JSX.Element {
	const { organization } = useSession();
	const [previewId, setPreviewId] = useState(-1);

	return (
		<>
			{patterns.length ? (
				patterns.map(({ id, name, owner }) => {
					const button = (
						<Button
							p='4'
							mb={spacing}
							borderRadius='xl'
							w='100%'
							key={id}
							onClick={() => onSelect?.(id)}
							onMouseEnter={() => setPreviewId(id)}
							onMouseLeave={() => setPreviewId(-1)}
						>
							<Flex justifyContent='space-between' alignItems='center' w='100%'>
								<Flex alignItems='center'>
									<Box w='20px' h='20px'>
										{previewId === id ? (
											<RemotePatternPlayer
												id={id}
												organizationId={organization.id}
												renderFrame={(colors) => <BeaconPreview colors={colors} bg='gray.700' />}
											/>
										) : (
											<Icon fontSize='14' as={FiZap} />
										)}
									</Box>
									<Heading size='xs' ml='2' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='70vw'>
										{name}
									</Heading>
								</Flex>
								<Flex alignItems='center'>
									<FiChevronRight />
								</Flex>
							</Flex>
						</Button>
					);

					if (link) {
						return <Link to={`/patterns/${id}`}>{button}</Link>;
					}

					return button;
				})
			) : (
				<Flex minHeight='30vh' alignItems='center' justifyContent='center'>
					<Box color='gray.600'>{i18next.t('PATTERN_LIST_EMPTY')}</Box>
				</Flex>
			)}
		</>
	);
}

export default PatternList;
