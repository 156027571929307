import i18next from 'i18next';
import {
	Button,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	UseDisclosureReturn,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import IntegrationList from './IntegrationList';
import GithubForm, { GithubSpec } from './github/GithubForm';
import Gitlab2Form, { Gitlab2Spec } from './gitlab2/Gitlab2Form';
import GitlabForm, { GitlabSpec } from './gitlab/GitlabForm';
import ObserviumForm, { ObserviumSpec } from './observium/ObserviumForm';
import PagerDutyForm, { PagerDutySpec } from './pagerduty/PagerDutyForm';
import PrometheusForm, { PrometheusSpec } from './prometheus/PrometheusForm';
import RobustaForm, { RobustaSpec } from './robusta/RobustaForm';
import StatusCakeForm, { StatusCakeSpec } from './statuscake/StatusCakeForm';
import WebhookForm, { WebhookSpec } from './webhook/WebhookForm';
import {
	DetailedGithub,
	DetailedGitlab,
	DetailedGitlab2,
	DetailedObservium,
	DetailedPagerDuty,
	DetailedPrometheus,
	DetailedRobusta,
	DetailedStatusCake,
	DetailedWebhook,
	SimplePattern,
} from '../../api/client';

export type GenericIntegration<T, U> = T & {
	impl: T;
	kind: U;
};

export type Integration =
	| GenericIntegration<DetailedWebhook, 'Webhook'>
	| GenericIntegration<DetailedGitlab, 'Gitlab'>
	| GenericIntegration<DetailedGitlab2, 'Gitlab2'>
	| GenericIntegration<DetailedGithub, 'Github'>
	| GenericIntegration<DetailedPrometheus, 'Prometheus'>
	| GenericIntegration<DetailedObservium, 'Observium'>
	| GenericIntegration<DetailedStatusCake, 'StatusCake'>
	| GenericIntegration<DetailedPagerDuty, 'PagerDuty'>
	| GenericIntegration<DetailedRobusta, 'Robusta'>;

export type IntegrationSpec =
	| GenericIntegration<WebhookSpec, 'Webhook'>
	| GenericIntegration<GitlabSpec, 'Gitlab'>
	| GenericIntegration<Gitlab2Spec, 'Gitlab2'>
	| GenericIntegration<GithubSpec, 'Github'>
	| GenericIntegration<PrometheusSpec, 'Prometheus'>
	| GenericIntegration<ObserviumSpec, 'Observium'>
	| GenericIntegration<StatusCakeSpec, 'StatusCake'>
	| GenericIntegration<PagerDutySpec, 'PagerDuty'>
	| GenericIntegration<RobustaSpec, 'Robusta'>;

export type IntegrationFormProps = {
	disclosure: UseDisclosureReturn;
	loading: boolean;
	patterns: SimplePattern[];
	onSubmit: (values: IntegrationSpec) => void;
};

function IntegrationForm({ disclosure, loading, patterns, onSubmit }: IntegrationFormProps): JSX.Element {
	const [kind, setKind] = useState<string>();

	const handleBack = (forceClose: boolean) => {
		if (kind) {
			setKind(undefined);
			if (forceClose) {
				disclosure.onClose();
			}
		} else {
			disclosure.onClose();
		}
	};

	const handeSubmit = (values: IntegrationSpec) =>
		onSubmit({
			...values,
			kind,
		} as IntegrationSpec);

	const getIntegrationForm = (kind: string) => {
		switch (kind) {
			case 'Webhook':
				return <WebhookForm patterns={patterns} />;
			case 'Gitlab':
				return <GitlabForm />;
			case 'Gitlab2':
				return <Gitlab2Form />;
			case 'Github':
				return <GithubForm />;
			case 'Prometheus':
				return <PrometheusForm />;
			case 'StatusCake':
				return <StatusCakeForm />;
			case 'Observium':
				return <ObserviumForm />;
			case 'PagerDuty':
				return <PagerDutyForm />;
			case 'Robusta':
				return <RobustaForm />;
		}

		return null;
	};

	return (
		<Modal isOpen={disclosure.isOpen} onClose={() => handleBack(true)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{i18next.t('INTEGRATIONS_CREATE_MODAL_TITLE')}</ModalHeader>
				<ModalCloseButton />
				<Formik
					onSubmit={handeSubmit}
					initialValues={
						{
							name: '',
							patternId: '0',
							minimumInterval: '60',
						} as IntegrationSpec
					}
				>
					<Form>
						<ModalBody>
							{kind ? (
								getIntegrationForm(kind)
							) : (
								<>
									<Heading size='xs' mb='2'>
										{i18next.t('INTEGRATIONS_CREATE_MODAL_TIP')}
									</Heading>
									<IntegrationList onSelect={setKind} />
								</>
							)}
						</ModalBody>
						<ModalFooter>
							<Button variant='ghost' mr={3} onClick={() => handleBack(false)}>
								{i18next.t(kind ? 'BACK' : 'CANCEL')}
							</Button>
							<Button colorScheme='blue' type='submit' disabled={!kind} isLoading={loading}>
								{i18next.t('CREATE')}
							</Button>
						</ModalFooter>
					</Form>
				</Formik>
			</ModalContent>
		</Modal>
	);
}

export default IntegrationForm;
