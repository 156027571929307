import { useEffect } from 'react';

function useLeaveGuard(enable: boolean): void {
	useEffect(() => {
		if (!enable) {
			return () => {};
		}

		const handler = (event: BeforeUnloadEvent) => {
			const message = "There is unsaved changes. If you leave now they'll be discarded forever";

			// Hack for old IE support
			(event || window.event).returnValue = message;
			return message;
		};

		window.addEventListener('beforeunload', handler);
		return () => window.removeEventListener('beforeunload', handler);
	}, [enable]);
}

export default useLeaveGuard;
