import i18next from 'i18next';
import { AiOutlineAlert } from 'react-icons/ai';
import { Box, Button, Flex, Heading, Icon, Tag, Text, Tooltip } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MouseEvent, useContext } from 'react';
import { compareVersions } from 'compare-versions';

import Card from '../core/Card';
import FirmwareVersionContext from '../core/FirmwareVersionContext';
import { SimpleBeacon } from '../../api/client';

type BeaconProps = {
	beacon: SimpleBeacon;
	unlink?: (id: number) => void;
};

function Beacon({
	beacon: { id, name, status, version, hardware, pendingOrdersCount, owner },
	unlink,
}: BeaconProps): JSX.Element {
	const availableVersion = useContext(FirmwareVersionContext);
	const isUpToDate = !availableVersion || checkUpToDate(version, availableVersion);

	const handleUnlink = (event: MouseEvent) => {
		event.stopPropagation();
		unlink?.(id);
	};

	return (
		<Card overflow='hidden'>
			<Flex justifyContent='space-between' alignItems='center' w='100%'>
				<Box overflow='hidden' whiteSpace='nowrap'>
					<Flex direction='column'>
						<Flex alignItems='center' mb='2'>
							<Icon mr='1' fontSize={19} as={AiOutlineAlert} />
							<Link to={`/beacons/${id}`}>
								<Button variant='link'>
									<Heading size='sm' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
										{name}
									</Heading>
								</Button>
							</Link>
						</Flex>
						<Flex alignItems='center'>
							<Flex>
								{status === 'up' ? (
									<Tag bg='green.500'>{i18next.t('ONLINE')}</Tag>
								) : (
									<Tag bg='red.500'>{i18next.t('OFFLINE')}</Tag>
								)}
							</Flex>
							{version &&
								(isUpToDate ? (
									<Tag bg='blue.200' ml='1'>
										{version} ({hardware})
									</Tag>
								) : (
									<Tooltip label={`${i18next.t('BEACON_UPGRADE_AVAILABLE')} (${availableVersion})`}>
										<Tag bg='red.500' ml='1'>
											⚠️ {version} ({hardware})
										</Tag>
									</Tooltip>
								))}
							{pendingOrdersCount ? (
								<Flex color='yellow.500' ml='2' alignItems='center'>
									<FiAlertCircle />
									<Text fontSize='14px' ml='1'>
										{i18next.t('BEACON_NOT_RESPONDING', {
											pendingOrdersCount,
										})}
									</Text>
								</Flex>
							) : null}
						</Flex>
					</Flex>
				</Box>
				<Flex alignItems='center'>
					{unlink && (
						<Button variant='link' mr='4' onClick={handleUnlink}>
							{i18next.t('UNLINK')}
						</Button>
					)}
				</Flex>
			</Flex>
		</Card>
	);
}

function checkUpToDate(version: string, availableVersion: string): boolean {
	if (version === 'unknown') {
		return true;
	}

	try {
		return compareVersions(version, availableVersion) >= 0;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export default Beacon;
export { checkUpToDate };
