import { Box, Button, Code } from '@chakra-ui/react';
import CopiableCode from '../../core/CopiableCode';

import addWebhookInstructions from './addWebhookInstructions.png';

type GithubInstructionsProps = {
	url: string;
	token: string;
};

function GithubInstructions({ url, token }: GithubInstructionsProps): JSX.Element {
	return (
		<Box>
			To configure this integration in your Github repository, follow these steps:
			<Box ml='6' my='2'>
				<ol>
					<li>
						Open your repository and navigate to Settings {'=>'} Webhooks, then click on the "Add webhook" button
						<img src={addWebhookInstructions} alt='Add Webhook Steps' style={{ marginTop: 8 }} />
					</li>
					<li style={{ marginTop: 12 }}>
						Fill in the following information in the form:
						<Box ml='6' mt='1'>
							<ul>
								<li>
									<b>Payload URL</b>: <CopiableCode>{url}</CopiableCode>
								</li>
								<li>
									<b>Content type</b>: <Code>application/json</Code>
								</li>
								<li>
									<b>Secret</b>: <CopiableCode>{token}</CopiableCode>
								</li>
								<li>
									<b>Which events would you like to trigger this webhook?</b>: Send me everything
								</li>
							</ul>
						</Box>
					</li>
					<li style={{ marginTop: 12 }}>Finally, click on the "Add webhook" button to save your webhook.</li>
				</ol>
			</Box>
			Learn more about{' '}
			<a href='https://docs.github.com/en/webhooks' target='_blank' rel='noreferrer'>
				<Button variant='link' colorScheme='blue'>
					Github Webhooks in the documentation
				</Button>
			</a>
			.
		</Box>
	);
}

export default GithubInstructions;
