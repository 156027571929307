import EventEmitter from 'events';

export type Storage = 'local' | 'session';

const events = new EventEmitter();

function writeStorage(type: Storage, key: string, value: string): void {
	const storage = window[`${type}Storage`];
	storage.setItem(key, value);
	events.emit(type, key, value);
}

function writeLocalStorage(key: string, value: string): void {
	writeStorage('local', key, value);
}

function writeSessionStorage(key: string, value: string): void {
	writeStorage('session', key, value);
}

function deleteFromStorage(type: Storage, key: string): void {
	const storage = window[`${type}Storage`];
	storage.removeItem(key);
	events.emit(type, key, null);
}

function deleteFromLocalStorage(key: string): void {
	deleteFromStorage('local', key);
}

function deleteFromSessionStorage(key: string): void {
	deleteFromStorage('session', key);
}

export {
	events as storageEvents,
	writeLocalStorage,
	writeSessionStorage,
	deleteFromLocalStorage,
	deleteFromSessionStorage,
};
