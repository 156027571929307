import { createStandaloneToast, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
	colors: {
		blue: {
			200: 'rgb(68, 128, 247)',
			300: 'rgb(58, 118, 237)',
			400: 'rgb(48, 108, 227)',
		},
		red: {
			200: '#F56565',
			300: '#E53E3E',
			400: '#C53030',
		},
	},
});

const toast = createStandaloneToast({
	theme,
	colorMode: 'dark',
});

export default theme;
export { toast };
