import i18next from 'i18next';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiPlus } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { SiPrometheus } from 'react-icons/si';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

import Error from '../components/core/Error';
import KeyValueInput from '../components/core/KeyValueInput';
import Loading from '../components/core/Loading';
import PrometheusInstructions from '../components/integrations/prometheus/PrometheusInstructions';
import PrometheusReactionForm from '../components/integrations/prometheus/PrometheusReactionForm';
import Reaction from '../components/reaction/Reaction';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useErrorToast from '../hooks/useErrorToast';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import PrometheusForm, { PrometheusSpec } from '../components/integrations/prometheus/PrometheusForm';
import { DetailedPrometheusMatch } from '../api/client';
import { getIngestURL } from '../utility/host';

type PrometheusAlertCondition = {
	label: string;
	value: string;
};

function Prometheus(): JSX.Element {
	const toastError = useErrorToast();
	const { organization } = useSession();
	const navigate = useNavigate();
	const reactionEditionModal = useDisclosure();
	const [editingReaction, setEditingReaction] = useState<DetailedPrometheusMatch>();
	const updateModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const { id } = useParams();
	const {
		queries: [prometheus, targets, patterns],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrievePrometheus, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
		useQuery(api.listPatterns, organization.id),
	);

	const edit = useCallbackWithLoading((name: string) => api.patchPrometheus(Number(id), organization.id, { name }));

	const save = useCallbackWithLoading(
		async (id: number, name: string, isFiredOnResolve: boolean, conditions: PrometheusAlertCondition[]) => {
			if (conditions.find((condition) => !condition.label || !condition.value)) {
				toastError(i18next.t('PROMEHTEUS_INVALID_LABELS_TITLE'), i18next.t('PROMEHTEUS_INVALID_LABELS_TIP_EMPTY'));
				return;
			}

			try {
				await api.patchPrometheusMatch(Number(id), organization.id, {
					name,
					rules: conditions,
					isFiredOnResolve,
				});
			} catch (e) {}
		},
	);

	const update = useCallbackWithLoading(async ({ name }: PrometheusSpec) => {
		try {
			await edit(name);
		} finally {
			refetch();
			updateModal.onClose();
		}
	});

	const deletePrometheus = useCallbackWithLoading(async () => {
		try {
			await api.destroyPrometheus(Number(id), organization.id);
		} finally {
			deleteModal.onClose();
			navigate('/integrations');
		}
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const addReaction = useCallbackWithLoading(async ({ name, patternId, minimumInterval, isFiredOnResolve }: any) => {
		try {
			await api.createPrometheusMatch(organization.id, {
				name,
				isFiredOnResolve,
				rules: [],
				prometheus: Number(id),
				reaction: {
					minimumInterval,
					patternId,
				},
			});
		} finally {
			refetch();
			reactionEditionModal.onClose();
		}
	});

	const openReactionEditionModal = (reaction?: DetailedPrometheusMatch) => {
		setEditingReaction(reaction);
		reactionEditionModal.onOpen();
	};

	const editReaction = useCallbackWithLoading(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async ({ id, name, reaction, minimumInterval, isFiredOnResolve }: any) => {
			try {
				await api.patchReaction(reaction.id, organization.id, {
					minimumInterval,
					patternId: reaction.pattern.id,
				});

				await api.patchPrometheusMatch(id, organization.id, {
					name,
					isFiredOnResolve,
					rules: editingReaction!.rules,
				});
			} finally {
				refetch();
				reactionEditionModal.onClose();
			}
		},
	);

	const removeMatch = async (id: number) => {
		try {
			await api.destroyPrometheusMatch(id, organization.id);
		} finally {
			refetch();
		}
	};

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <Error fullscreen error={error} />;
	}

	const { name, token, matches } = prometheus.data!;
	const url = `${getIngestURL()}/prometheus`;

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='6'>
				<Flex alignItems='center'>
					<Heading size='lg'>
						<Icon as={SiPrometheus} mr='2' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<div>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={updateModal.onOpen}>
						{i18next.t('RENAME')}
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						{i18next.t('DELETE')}
					</Button>
				</div>
			</Flex>

			{!(matches || []).length && <Box color='gray.500'>{i18next.t('PROMETHEUS_NO_REACTIONS')}</Box>}

			<Accordion allowMultiple defaultIndex={[]}>
				{/* @ts-expect-error */}
				{(matches || []).map((match) => (
					<AccordionItem key={match.id}>
						<h2>
							<AccordionButton>
								{match.name}
								<AccordionIcon />
							</AccordionButton>
						</h2>

						<AccordionPanel>
							<Heading size='sm' mb='2'>
								{i18next.t('PROMEHTEUS_LABEL_CONITIONS')}
							</Heading>
							<KeyValueInput
								defaultValue={match.rules}
								handleSave={(conditions) => save(match.id, match.name, match.isFiredOnResolve, conditions)}
							/>
							<Box mt='4' />
							<Reaction
								reaction={match.reaction}
								targets={targets.data!.results!}
								patterns={patterns.data!.results!}
								refetch={refetch}
							/>

							<Button
								colorScheme='blue'
								leftIcon={<FiEdit3 />}
								mt='4'
								mr='2'
								onClick={() => openReactionEditionModal(match)}
							>
								{i18next.t('EDIT')}
							</Button>
							<Button colorScheme='red' leftIcon={<FiDelete />} mt='4' onClick={() => removeMatch(match.id)}>
								{i18next.t('DELETE')}
							</Button>
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>

			<Button leftIcon={<FiPlus />} onClick={() => openReactionEditionModal()} mt='4'>
				{i18next.t('PROMEHTEUS_ADD_REACTIION')}
			</Button>

			<Accordion allowMultiple defaultIndex={[]} mt='6'>
				<AccordionItem>
					<h2>
						<AccordionButton>
							How to setup Prometheus integration
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel>
						<PrometheusInstructions url={url} token={token} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Modal isOpen={updateModal.isOpen} onClose={updateModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Prometheus integration</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={update}
						initialValues={{
							name,
						}}
					>
						<Form>
							<ModalBody>
								<PrometheusForm />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={updateModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={update.loading}>
									{i18next.t('UPDATE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('PROMEHTEUS_DELETE_TITLE')}
						</AlertDialogHeader>

						<AlertDialogBody>{i18next.t('PROMEHTEUS_DELETE_TIP')}</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' ml={3} onClick={deletePrometheus} isLoading={deletePrometheus.loading}>
								{i18next.t('DELETE')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Modal isOpen={reactionEditionModal.isOpen} onClose={reactionEditionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{i18next.t(editingReaction ? 'PROMEHTEUS_EDIT_REACTION' : 'PROMEHTEUS_CREATE_REACTION')}
					</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editingReaction ? editReaction : addReaction}
						initialValues={
							editingReaction
								? {
										...editingReaction,
										minimumInterval: editingReaction.reaction.minimumInterval,
								  }
								: {
										name: '',
										patternId: 0,
										isFiredOnResolve: false,
										minimumInterval: 60,
								  }
						}
					>
						<Form>
							<ModalBody>
								<PrometheusReactionForm patterns={editingReaction ? undefined : patterns.data!.results!} />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={reactionEditionModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editReaction.loading}>
									{i18next.t(editingReaction ? 'UPDATE' : 'CREATE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Prometheus;
