import i18next from 'i18next';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import { BrowserApiError } from '../api/error';

type UserLoginInput = Parameters<typeof api.login>[0];

function Login(): JSX.Element {
	const navigate = useNavigate();
	const [error, setError] = useState(false);

	const login = useCallbackWithLoading(async (credentials: UserLoginInput) => {
		try {
			await api.login(credentials);
			navigate('/');
		} catch (err) {
			const error = err as BrowserApiError;

			error.reword('no_active_account', () => ({
				title: i18next.t('LOGIN_INVALID_TITLE'),
				message: i18next.t('LOGIN_INVALID_TIP'),
			}));

			setError(true);
		}
	});

	return (
		<Flex height='100%' justifyContent='center' alignItems='center'>
			<Box width='500px' borderWidth='1px' borderRadius='lg' p='4'>
				<Heading size='lg' mb='5'>
					{i18next.t('LOGIN_TITLE')}
				</Heading>
				<Formik
					onSubmit={login}
					initialValues={{
						email: '',
						password: '',
					}}
				>
					<Form>
						<Field name='email'>
							{({ field, form }: FieldProps) => (
								<FormControl isRequired isInvalid={error}>
									<FormLabel htmlFor='email'>{i18next.t('EMAIL')}</FormLabel>
									<Input id='email' placeholder='monkeynator@enix.io' autoComplete='none' tabIndex={1} {...field} />
									<FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Box mt='3' />
						<Field name='password'>
							{({ field, form }: FieldProps) => (
								<FormControl isRequired isInvalid={error}>
									<Flex alignItems='center' justifyContent='space-between' mb='2'>
										<FormLabel htmlFor='password' mb='0'>
											{i18next.t('PASSWORD')}
										</FormLabel>
										<Link to='/login/recover'>
											<Button variant='link' color='gray.500' tabIndex={4}>
												{i18next.t('LOGIN_FORGOT_PASSWORD')}
											</Button>
										</Link>
									</Flex>
									<Input
										id='password'
										type='password'
										placeholder='*****'
										autoComplete='none'
										tabIndex={2}
										{...field}
									/>
									<FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
								</FormControl>
							)}
						</Field>

						<Flex alignItems='center' justifyContent='flex-end' pt='4'>
							<Flex mr='4'>
								<Link to='/register'>{i18next.t('LOGIN_LINK_TO_REGISTER')}</Link>
							</Flex>
							<Button colorScheme='blue' type='submit' isLoading={login.loading} tabIndex={3}>
								{i18next.t('LOGIN')}
							</Button>
						</Flex>
					</Form>
				</Formik>
			</Box>
		</Flex>
	);
}

export default Login;
