import Loading from '../core/Loading';
import PatternPlayer from './PatternPlayer';
import PatternSpec from './PatternSpec';
import api from '../../api';
import useQuery from '../../hooks/useQuery';
import { Color } from '../../utility/color';

type RemotePatternPlayerProps = {
	organizationId: number;
	id: number;
	renderFrame: (colors: Color[]) => JSX.Element;
};

function RemotePatternPlayer({ organizationId, id, renderFrame }: RemotePatternPlayerProps): JSX.Element {
	const { data, loading } = useQuery(api.retrievePattern, id, organizationId);

	if (loading) {
		return <Loading mt='2px' />;
	}

	return <PatternPlayer pattern={new PatternSpec(data!.raw)} renderFrame={renderFrame} />;
}

export default RemotePatternPlayer;
