import i18next from 'i18next';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMicropythonSerialInterface } from '../hooks/useSerialInterface';

function FlashBeacon(): JSX.Element {
	const beaconFlasher = useMicropythonSerialInterface();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef<HTMLButtonElement>(null);
	const [currentFirmwareVersion, setCurrentFirmwareVersion] = useState('');
	const [nextFirmwareVersion, setNextFirmwareVersion] = useState('');
	const [flashLogs, setFlashLogs] = useState(new Array<string>());
	const [done, setDone] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!beaconFlasher) {
			return;
		}

		beaconFlasher.getFirmwareVersions().then(({ current, next }) => {
			setCurrentFirmwareVersion(current);
			setNextFirmwareVersion(next);
			onOpen();
		});
	}, [beaconFlasher, onOpen]);

	const confirmFlashDevice = async () => {
		if (!beaconFlasher) {
			return;
		}
		onClose();
		await beaconFlasher.flashDevice((log) => {
			if (log[0] === '\r') {
				setFlashLogs(([...flashLogs]) => {
					flashLogs.pop();
					flashLogs.push(log);
					return flashLogs;
				});
			} else {
				console.info(log);
				setFlashLogs((flashLogs) => [...flashLogs, log]);
			}
		});
		setDone(true);
	};

	const cancelFlashDevice = () => {
		if (beaconFlasher) {
			beaconFlasher.disconnect();
		}
		onClose();
		navigate('/');
	};

	return (
		<>
			<Text fontSize='xl' as='b'>
				{i18next.t('FLASH_BEACON_TIP')}
			</Text>

			<pre
				style={{
					overflow: 'auto',
					fontSize: 'small',
					marginTop: '1em',
				}}
			>
				{flashLogs.join('')}
			</pre>

			{done && (
				<Button mt='4' onClick={() => navigate(`/`)}>
					{i18next.t('FLASH_BEACON_BACK')}
				</Button>
			)}

			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('FLASH_BEACON_TITLE')} ({currentFirmwareVersion} -&gt; {nextFirmwareVersion})
						</AlertDialogHeader>

						<AlertDialogBody>
							{i18next.t('FLASH_BEACON_CONFIRM_TIP', {
								currentFirmwareVersion,
								nextFirmwareVersion,
							})}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={cancelFlashDevice}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' onClick={confirmFlashDevice} ml={3}>
								{i18next.t('CONFIRM')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default FlashBeacon;
