import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import useSession from '../../hooks/useSession';

type LayoutProps = {
	children: ReactNode;
};

function Layout({ children }: LayoutProps): JSX.Element {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { user } = useSession();

	return user.id === 0 ? (
		<>{children}</>
	) : (
		<Box minH='100vh' bg='gray.900'>
			<Sidebar
				onClose={onClose}
				display={{
					base: 'none',
					md: 'block',
				}}
			/>
			<Drawer
				autoFocus={false}
				returnFocusOnClose={false}
				placement='left'
				size='full'
				isOpen={isOpen}
				onClose={onClose}
				onOverlayClick={onClose}
			>
				<DrawerContent>
					<Sidebar
						onClose={onClose}
						display={{
							base: 'block',
							md: 'none',
						}}
					/>
				</DrawerContent>
			</Drawer>
			<Header onOpen={onOpen} />
			<Box
				p='8'
				ml={{
					base: 0,
					md: 60,
				}}
			>
				{children}
			</Box>
		</Box>
	);
}

export default Layout;
