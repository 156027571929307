import { Box } from '@chakra-ui/react';

import IntegrationListItem from './IntegrationListItem';

type IntegrationListProps = {
	onSelect: (integration: string) => void;
};

function IntegrationList({ onSelect }: IntegrationListProps): JSX.Element {
	return (
		<Box borderWidth='1px' borderRadius='lg' overflow='hidden'>
			<IntegrationListItem onSelect={onSelect} kind='Webhook' />
			<IntegrationListItem onSelect={onSelect} kind='Prometheus' />
			<IntegrationListItem onSelect={onSelect} kind='Github' />
			<IntegrationListItem onSelect={onSelect} kind='Gitlab2' />
			<IntegrationListItem onSelect={onSelect} kind='StatusCake' />
			<IntegrationListItem onSelect={onSelect} kind='Observium' />
			<IntegrationListItem onSelect={onSelect} kind='PagerDuty' />
			<IntegrationListItem onSelect={onSelect} kind='Robusta' />
		</Box>
	);
}

export default IntegrationList;
