import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiPlus } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';

import Error from '../components/core/Error';
import IntegrationIcon from '../components/integrations/IntegrationIcon';
import Loading from '../components/core/Loading';
import RobustaInstructions from '../components/integrations/robusta/RobustaInstructions';
import RobustaMatch from '../components/integrations/robusta/RobustaMatch';
import RobustaReactionForm from '../components/integrations/robusta/RobustaReactionForm';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import RobustaForm, { RobustaSpec } from '../components/integrations/robusta/RobustaForm';
import { DetailedRobustaMatch } from '../api/client';
import { getIngestURL } from '../utility/host';

function Robusta(): JSX.Element {
	const { organization } = useSession();
	const navigate = useNavigate();
	const reactionEditionModal = useDisclosure();
	const [editingReaction, setEditingReaction] = useState<DetailedRobustaMatch>();
	const updateModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const { id } = useParams();
	const {
		queries: [robusta, targets, patterns],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrieveRobusta, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
		useQuery(api.listPatterns, organization.id),
	);

	const edit = useCallbackWithLoading((name: string) =>
		api.patchRobusta(Number(id), organization.id, {
			name,
		}),
	);

	const save = useCallbackWithLoading(async (id: number, name: string, filters: unknown) => {
		try {
			await api.patchRobustaMatch(Number(id), organization.id, {
				name,
				// @ts-expect-error
				filters,
			});
		} catch (e) {
			return false;
		}
		return true;
	});

	const update = useCallbackWithLoading(async ({ name }: RobustaSpec) => {
		try {
			await edit(name);
		} finally {
			refetch();
			updateModal.onClose();
		}
	});

	const deleteRobusta = useCallbackWithLoading(async () => {
		try {
			await api.destroyRobusta(Number(id), organization.id);
		} finally {
			deleteModal.onClose();
			navigate('/integrations');
		}
	});

	const addReaction = useCallbackWithLoading(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async ({ name, patternId, minimumInterval }: any) => {
			try {
				await api.createRobustaMatch(organization.id, {
					name,
					filters: [],
					robusta: Number(id),
					reaction: {
						minimumInterval,
						patternId,
					},
				});
			} finally {
				refetch();
				reactionEditionModal.onClose();
			}
		},
	);

	const openReactionEditionModal = (reaction?: DetailedRobustaMatch) => {
		setEditingReaction(reaction);
		reactionEditionModal.onOpen();
	};

	const editReaction = useCallbackWithLoading(
		async ({
			id,
			name,
			reaction,
			minimumInterval,
			filters, // eslint-disable-next-line @typescript-eslint/no-explicit-any
		}: any) => {
			try {
				await api.patchReaction(reaction.id, organization.id, {
					minimumInterval,
					patternId: reaction.pattern.id,
				});

				await api.patchRobustaMatch(id, organization.id, {
					name,
					filters,
				});
			} finally {
				refetch();
				reactionEditionModal.onClose();
			}
		},
	);

	const removeMatch = async (id: number) => {
		try {
			await api.destroyRobustaMatch(id, organization.id);
		} finally {
			refetch();
		}
	};

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <Error fullscreen error={error} />;
	}

	const { name, matches = [], token } = robusta.data!;
	const url = `${getIngestURL()}/robusta/${token}`;

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='6'>
				<Flex alignItems='center'>
					<Heading size='lg' mr='2'>
						<IntegrationIcon kind='Robusta' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<div>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={updateModal.onOpen}>
						Edit
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						Delete
					</Button>
				</div>
			</Flex>

			<Heading size='sm' mb='2'>
				Matches
			</Heading>

			{!matches.length && <Box color='gray.500'>No reactions configured yet.</Box>}

			<Accordion allowMultiple defaultIndex={[]}>
				{/* @ts-expect-error */}
				{matches.map((match) => (
					<RobustaMatch
						key={match.id}
						match={match}
						// @ts-expect-error
						save={save}
						openReactionEditionModal={openReactionEditionModal}
						patterns={patterns.data!.results!}
						targets={targets.data!.results!}
						refetch={refetch}
						removeMatch={removeMatch}
					/>
				))}
			</Accordion>

			<Button leftIcon={<FiPlus />} onClick={() => openReactionEditionModal()} mt='4'>
				Add Match
			</Button>

			<Accordion allowMultiple defaultIndex={[]} mt='8'>
				<AccordionItem>
					<h2>
						<AccordionButton>
							How to setup Robusta integration
							<AccordionIcon />
						</AccordionButton>
					</h2>

					<AccordionPanel>
						<RobustaInstructions url={url} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Modal isOpen={updateModal.isOpen} onClose={updateModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Robusta integration</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={update}
						initialValues={{
							name,
						}}
					>
						<Form>
							<ModalBody>
								<RobustaForm />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={updateModal.onClose}>
									Cancel
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={update.loading}>
									Update
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Robusta integration
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? The integration will stop working and this action cannot be undone.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								Cancel
							</Button>
							<Button colorScheme='red' ml={3} onClick={deleteRobusta} isLoading={deleteRobusta.loading}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Modal isOpen={reactionEditionModal.isOpen} onClose={reactionEditionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{editingReaction ? 'Edit' : 'Create'} Robusta Reaction</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editingReaction ? editReaction : addReaction}
						initialValues={
							editingReaction
								? {
										...editingReaction,
										minimumInterval: editingReaction.reaction.minimumInterval,
								  }
								: {
										name: '',
										patternId: 0,
										minimumInterval: 60,
								  }
						}
					>
						<Form>
							<ModalBody>
								<RobustaReactionForm patterns={editingReaction ? undefined : patterns.data!.results!} />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={reactionEditionModal.onClose}>
									Cancel
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editReaction.loading}>
									{editingReaction ? 'Update' : 'Create'}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Robusta;
