import i18next from 'i18next';
import { FiPlus } from 'react-icons/fi';
import { Icon, MenuItem } from '@chakra-ui/react';

import Loading from '../core/Loading';
import api from '../../api';
import useQuery from '../../hooks/useQuery';
import useSession from '../../hooks/useSession';
import { SimpleOrganization } from '../../api/client';
import { writeLocalStorage } from '../../utility/storage';

type OrganizationListProps = {
	onCreate: () => void;
};

function OrganizationList({ onCreate }: OrganizationListProps): JSX.Element {
	const { data, loading } = useQuery(api.listOrganizations);
	const { organization } = useSession();

	if (loading) {
		return (
			<MenuItem isDisabled>
				<Loading />
			</MenuItem>
		);
	}

	return (
		<>
			{data!
				.results!.filter((org) => org.id !== organization.id)
				.map((organization) => (
					<MenuItem key={organization.id} onClick={() => switchOrganization(organization)}>
						{organization.name}
					</MenuItem>
				))}
			<MenuItem alignItems='center' onClick={onCreate}>
				<Icon as={FiPlus} mr='2' />
				{i18next.t('ORGANIZATION_CREATE')}
			</MenuItem>
		</>
	);
}

function switchOrganization(organization: SimpleOrganization, redirect?: string): void {
	writeLocalStorage('organization-id', organization.id.toString());
	writeLocalStorage('organization-name', organization.name);

	if (redirect) {
		window.location.replace(redirect);
	} else {
		const url = new URL(window.location.href);
		url.pathname = url.pathname.split('/')[1];
		window.location.replace(url.toString());
	}
}

export default OrganizationList;
export { switchOrganization };
