import i18next from 'i18next';
import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { FiAlertTriangle, FiChevronRight, FiPlus } from 'react-icons/fi';

import Beacon from '../beacon/Beacon';
import BeaconList from '../beacon/BeaconList';
import Group from './Group';
import GroupList from './GroupList';
import { DetailedGroupTarget, SimpleTarget } from '../../api/client';

type LinkedTargetListProps = {
	linkedTargets: SimpleTarget[];
	allTargets: SimpleTarget[];
	linkTarget: (id: number) => Promise<void>;
	unlinkTarget: (id: number) => Promise<void>;
};

function LinkedTargetList({ linkedTargets, allTargets, linkTarget, unlinkTarget }: LinkedTargetListProps): JSX.Element {
	const linkModal = useDisclosure();
	const allBeacons = allTargets.filter((target) => target.beacon).map((target) => target.beacon);
	const allGroups = allTargets.filter((target) => target.groupTarget).map((target) => target.groupTarget);

	const handleSelect = async (id: number) => {
		await linkTarget(id);
		linkModal.onClose();
	};

	return (
		<>
			<Heading size='sm' mt='6' mb='2'>
				{i18next.t('LINKED_TARGET_LIST_TITLE')} ({linkedTargets.length})
			</Heading>

			{linkedTargets?.map((target, index) => (
				<Box mb='2' key={index}>
					{target.beacon && <Beacon beacon={target.beacon} unlink={unlinkTarget} />}
					{target.groupTarget && <Group group={target.groupTarget as DetailedGroupTarget} unlink={unlinkTarget} />}
				</Box>
			))}

			{!linkedTargets?.length && (
				<Flex color='yellow.500' mb='4' alignItems='center'>
					<Icon as={FiAlertTriangle} mr='1' />
					{i18next.t('LINKED_TARGET_LIST_NO_BEACONS')}
				</Flex>
			)}

			<Button
				p='4'
				border='dashed'
				borderColor='whiteAlpha.500'
				borderWidth='2px'
				borderRadius='xl'
				background='transparent'
				w='100%'
				h='auto'
				onClick={linkModal.onOpen}
			>
				<Flex justifyContent='space-between' alignItems='center' w='100%'>
					<Flex alignItems='center'>
						<FiPlus />
						<Box ml='2'>{i18next.t('LINKED_TARGET_LINK_TARGET')}</Box>
					</Flex>
					<FiChevronRight />
				</Flex>
			</Button>

			<Modal isOpen={linkModal.isOpen} onClose={linkModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{i18next.t('LINKED_TARGET_LINK_TARGET_TITLE')}</ModalHeader>
					<ModalBody>
						{allGroups && (
							<>
								<Heading size='sm' mb='2'>
									{i18next.t('LINKED_TARGET_LINK_TARGET_GROUPS')}
								</Heading>
								<GroupList groups={allGroups} onSelect={handleSelect} />
								<Heading size='sm' mb='2'>
									{i18next.t('LINKED_TARGET_LINK_TARGET_BEACONS')}
								</Heading>
							</>
						)}
						<BeaconList beacons={allBeacons} onSelect={handleSelect} />
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='blue' onClick={linkModal.onClose}>
							{i18next.t('CANCEL')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default LinkedTargetList;
