import { CSSProperties, ReactNode } from 'react';
import {
	Collapse,
	Flex,
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Stack,
	useBreakpointValue,
	useDisclosure,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';

type SidebarItemProps = {
	icon: ReactNode;
	title: string;
	link: string;
	subitems?: SidebarItemProps[];
};

function SidebarItem({ icon, title, link, subitems }: SidebarItemProps): JSX.Element {
	const { isOpen, onToggle } = useDisclosure();

	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	});

	return (
		<Stack spacing={4} width='100%' onClick={subitems && isMobile ? onToggle : undefined}>
			<Popover trigger='hover' placement='right-start'>
				{({ onClose }) => (
					<>
						<PopoverTrigger>
							<Link to={link} style={{ textDecoration: 'none' }}>
								<Flex
									align='center'
									justifyContent='space-between'
									p='4'
									mx='4'
									borderRadius='lg'
									role='group'
									cursor='pointer'
									_hover={{
										bg: 'blue.200',
										color: 'white',
									}}
								>
									<Flex align='center'>
										{icon}
										<div style={noSelectionStyle}>{title}</div>
									</Flex>
									{subitems && (
										<Icon
											as={FiChevronDown}
											transition='all .25s ease-in-out'
											transform={isOpen ? 'rotate(180deg)' : ''}
											w={6}
											h={6}
											display={{
												base: 'block',
												md: 'none',
											}}
										/>
									)}
								</Flex>
							</Link>
						</PopoverTrigger>

						{subitems && isMobile ? (
							<Collapse animateOpacity in={isOpen} style={{ marginTop: 0 }}>
								<Stack pl={4} borderStyle='solid' align='start' borderColor='gray.700'>
									{subitems.map((child) => (
										<SidebarItem key={child.title} {...child} />
									))}
								</Stack>
							</Collapse>
						) : null}

						{subitems && !isMobile ? (
							<PopoverContent
								border={0}
								py={4}
								boxShadow='xl'
								rounded='xl'
								minW='sm'
								bg='gray.800'
								_focus={{ outline: 0 }}
							>
								<Stack>
									{subitems.map((child) => (
										<SidebarItem key={child.title} {...child} />
									))}
								</Stack>
							</PopoverContent>
						) : null}
					</>
				)}
			</Popover>
		</Stack>
	);
}

const noSelectionStyle: CSSProperties = {
	userSelect: 'none',
	WebkitUserSelect: 'none',
};

export default SidebarItem;
