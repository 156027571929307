import { Box, Button } from '@chakra-ui/react';
import CopiableCode from '../../core/CopiableCode';

import addWebhookInstructions from './addWebhookInstructions.png';

type GitlabInstructionsProps = {
	url: string;
	token: string;
};

function GitlabInstructions({ url, token }: GitlabInstructionsProps): JSX.Element {
	return (
		<Box>
			To configure this integration in your Gitlab project, follow these steps:
			<Box ml='6' my='2'>
				<ol>
					<li>
						Open your project and navigate to Settings {'=>'} Webhooks, then click on the "Add new webhook" button.
						<img src={addWebhookInstructions} alt='Add Webhook Steps' style={{ marginTop: 8 }} />
					</li>
					<li style={{ marginTop: 12 }}>
						Fill in the following information in the form:
						<Box ml='6' mt='1'>
							<ul>
								<li>
									<b>URL</b>: <CopiableCode>{url}</CopiableCode>
								</li>
								<li>
									<b>Secret token</b>: <CopiableCode>{token}</CopiableCode>
								</li>
								<li>
									<b>Trigger</b>: Select each event that you want to forward to the Blinky App (you can check everything
									and filter later in the Blinky App).
								</li>
							</ul>
						</Box>
					</li>
					<li style={{ marginTop: 12 }}>Finally, click on the "Add webhook" button to save your webhook.</li>
				</ol>
			</Box>
			If you are using the premium subscription of Gitlab, you can also configure a webhook for a whole group.
			<br />
			Learn more about{' '}
			<a href='https://docs.gitlab.com/ee/user/project/integrations/webhooks.html' target='_blank' rel='noreferrer'>
				<Button variant='link' colorScheme='blue'>
					Gitlab Webhooks in the documentation
				</Button>
			</a>
			.
		</Box>
	);
}

export default GitlabInstructions;
