import useErrorBoundary from 'use-error-boundary';
import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useEffect } from 'react';

import Associate from '../pages/Associate';
import Beacon from '../pages/Beacon';
import BeaconConsole from '../pages/BeaconConsole';
import Beacons from '../pages/Beacons';
import DarkMode from '../components/core/DarkMode';
import Error from '../components/core/Error';
import FlashBeacon from '../pages/FlashBeacon';
import Github from '../pages/Github';
import Gitlab from '../pages/Gitlab';
import Gitlab2 from '../pages/Gitlab2';
import Group from '../pages/Group';
import Groups from '../pages/Groups';
import Integrations from '../pages/Integrations';
import Layout from './layout/Layout';
import Login from '../pages/Login';
import LoginRecovery from '../pages/LoginRecovery';
import Observium from '../pages/Observium';
import Organization from '../pages/Organization';
import OrganizationJoin from '../pages/OrganizationJoin';
import PagerDuty from '../pages/PagerDuty';
import Pattern from '../pages/Pattern';
import Patterns from '../pages/Patterns';
import Prometheus from '../pages/Prometheus';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import Robusta from '../pages/Robusta';
import SendEmailConfirmation from '../pages/SendEmailConfirmation';
import SerialInstructions from '../pages/SerialInstructions';
import StatusCake from '../pages/StatusCake';
import UpdatePassword from '../pages/UpdatePassword';
import Webhook from '../pages/Webhook';
import WifiConfiguration from '../pages/WifiConfiguration';
import theme from '../config/theme';
import useSession, { Session } from '../hooks/useSession';
import { FirmwareVersionContextProvider } from '../components/core/FirmwareVersionContext';

function App(): JSX.Element {
	const session = useSession();
	const { user, organization } = session;
	const [params] = useSearchParams();

	useEffect(() => {
		if (params.get('invitation')) {
			localStorage.setItem('invitation', params.get('invitation')!);
			localStorage.setItem('invitation-orgname', params.get('orgname')!);
			window.location.replace('/organization/join');
		}
	}, []);

	return (
		<Layout>
			<Routes>
				{organization.id !== 0 && (
					<>
						<Route path='/integrations/prometheus/:id' element={<Prometheus />} />
						<Route path='/integrations/gitlab/:id' element={<Gitlab />} />
						<Route path='/integrations/gitlab2/:id' element={<Gitlab2 />} />
						<Route path='/integrations/github/:id' element={<Github />} />
						<Route path='/integrations/webhook/:id' element={<Webhook />} />
						<Route path='/integrations/statuscake/:id' element={<StatusCake />} />
						<Route path='/integrations/observium/:id' element={<Observium />} />
						<Route path='/integrations/pagerduty/:id' element={<PagerDuty />} />
						<Route path='/integrations/robusta/:id' element={<Robusta />} />
						<Route path='/integrations' element={<Integrations />} />
						<Route path='/beacons/associate/:networkId' element={<Associate />} />
						<Route path='/beacons/configure/:expectedBeaconNetworkId/instructions' element={<SerialInstructions />} />
						<Route path='/beacons/configure/instructions' element={<SerialInstructions />} />
						<Route path='/beacons/configure/:expectedBeaconNetworkId' element={<WifiConfiguration />} />
						<Route path='/beacons/configure' element={<WifiConfiguration />} />
						<Route path='/beacons/console/:expectedBeaconNetworkId' element={<BeaconConsole />} />
						<Route path='/beacons/upgrade/:expectedBeaconNetworkId/instructions' element={<SerialInstructions />} />
						<Route path='/beacons/upgrade/:expectedBeaconNetworkId' element={<FlashBeacon />} />
						<Route path='/beacons/:id' element={<Beacon />} />
						<Route path='/beacons' element={<Beacons />} />
						<Route path='/groups/:id' element={<Group />} />
						<Route path='/groups' element={<Groups />} />
						<Route path='/patterns/:id' element={<Pattern />} />
						<Route path='/patterns' element={<Patterns />} />
					</>
				)}

				{user.id !== 0 && (
					<>
						<Route path='/organization/join' element={<OrganizationJoin />} />
						<Route path='/organization' element={<Organization />} />
					</>
				)}

				<Route path='/login/update-password/:uid/:code' element={<UpdatePassword />} />
				<Route path='/login/update-password' element={<UpdatePassword />} />
				<Route path='/login/resend-confirmation' element={<SendEmailConfirmation />} />
				<Route path='/login/recover' element={<LoginRecovery />} />
				<Route path='/login/reset' element={<ResetPassword />} />
				<Route path='/login' element={<Login />} />
				<Route path='/register' element={<Register />} />

				{getRootRedirect(session)}
			</Routes>
		</Layout>
	);
}

function AppWrapper(): JSX.Element {
	const { ErrorBoundary, didCatch, error } = useErrorBoundary();

	if (didCatch) {
		return (
			<ChakraProvider theme={theme} cssVarsRoot='body'>
				<DarkMode>
					<Error fullscreen error={error} />
				</DarkMode>
			</ChakraProvider>
		);
	}

	return (
		<ChakraProvider theme={theme} cssVarsRoot='body'>
			<DarkMode>
				<ErrorBoundary>
					<FirmwareVersionContextProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</FirmwareVersionContextProvider>
				</ErrorBoundary>
			</DarkMode>
		</ChakraProvider>
	);
}

function getRootRedirect(session: Session): JSX.Element {
	// user is not logged in
	if (!session.user.id) {
		return <Route path='*' element={<Navigate to='/login' />} />;
	}

	// user is logged in and has a pending invitation
	if (localStorage.getItem('invitation')) {
		return <Route path='*' element={<Navigate to='/organization/join' />} />;
	}

	// user is logged in but has no organization
	if (!session.organization.id) {
		return <Route path='*' element={<Navigate to='/organization' />} />;
	}

	// user is logged in and has an organization
	return <Route path='*' element={<Navigate to='/beacons' />} />;
}

export default AppWrapper;
