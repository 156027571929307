import i18next from 'i18next';
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Heading,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiSave } from 'react-icons/fi';
import { useState } from 'react';

import FilterList from '../../core/FilterList';
import Reaction from '../../reaction/Reaction';
import { DetailedRobustaMatch, SimplePattern, SimpleTarget } from '../../../api/client';
import { FilterSpec } from '../../core/Filter';

type RobustaMatchProps = {
	match: DetailedRobustaMatch;
	save: () => void;
	openReactionEditionModal: (match: DetailedRobustaMatch) => void;
	targets: SimpleTarget[];
	patterns: SimplePattern[];
	refetch: () => void;
	removeMatch: (id: number) => void;
};

function RobustaMatch({
	match,
	targets,
	patterns,
	refetch,
	openReactionEditionModal,
	removeMatch,
	save,
}: RobustaMatchProps): JSX.Element {
	const [dirty, setDirty] = useState(false);
	const [filters, setFilters] = useState(match.filters);

	const addFilter = (filter: FilterSpec) => {
		setFilters([...filters, filter]);
		setDirty(true);
	};

	const removeFilter = (index: number) => {
		setFilters(filters.filter((_, i) => i !== index));
		setDirty(true);
	};

	const saveAndMarkClean = async (...args: unknown[]) => {
		// @ts-expect-error
		await save(...args);
		setDirty(false);
	};

	return (
		<AccordionItem key={match.id}>
			<h2>
				<AccordionButton>
					{match.name}
					<AccordionIcon />
				</AccordionButton>
			</h2>

			<AccordionPanel>
				<Reaction reaction={match.reaction} targets={targets} patterns={patterns} refetch={refetch} />

				<Box mt='6' />

				<Heading size='sm' mb='2' mt='4'>
					Filters
				</Heading>
				<FilterList
					filters={filters}
					addFilter={addFilter}
					removeFilter={removeFilter}
					keyOptions={robustaFilterKeyOptions}
				/>

				<Flex mt='2'>
					<Button
						isDisabled={!dirty}
						colorScheme='blue'
						leftIcon={<FiSave />}
						mt='4'
						mr='2'
						onClick={() => saveAndMarkClean(match.id, match.name, filters)}
					>
						{dirty ? 'Save' : 'Saved'}
					</Button>
					<Button
						variant='outline'
						colorScheme='blue'
						leftIcon={<FiEdit3 />}
						mt='4'
						mr='2'
						onClick={() => openReactionEditionModal(match)}
					>
						{i18next.t('EDIT')}
					</Button>
					<Button
						variant='outline'
						colorScheme='red'
						leftIcon={<FiDelete />}
						mt='4'
						onClick={() => removeMatch(match.id)}
					>
						{i18next.t('DELETE')}
					</Button>
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
}

const robustaFilterKeyOptions = [
	'Resource',
	'Source',
	'Namespace',
	'Node',
	'monitoring_tool',
	'message_type',
	'entity_id',
	'entity_display_name',
	'state_message',
];

export default RobustaMatch;
