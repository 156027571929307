import { Button, Flex, Text } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import IntegrationIcon from './IntegrationIcon';

type IntegrationListItemProps = {
	kind: string;
	onSelect: (integration: string) => void;
};

function IntegrationListItem({ kind, onSelect }: IntegrationListItemProps): JSX.Element {
	return (
		<Button width='100%' justifyContent='space-between' borderRadius='0' onClick={() => onSelect(kind)}>
			<Flex>
				<IntegrationIcon kind={kind} />
				<Text ml='2' fontSize='15px'>
					{getIntegrationDisplayName(kind)}
				</Text>
			</Flex>
			<FiChevronRight />
		</Button>
	);
}

function getIntegrationDisplayName(kind: string): string {
	switch (kind) {
		case 'Gitlab':
			return 'Gitlab (legacy)';

		case 'Gitlab2':
			return 'Gitlab';
	}

	return kind;
}

export default IntegrationListItem;
