import { Box, Button, Heading, Icon, Text } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { ReactNode } from 'react';

type EmptyProps = {
	title: string;
	action?: string;
	icon: ReactNode;
	onCreate: () => void;
	children: ReactNode;
};

function Empty({ title, icon, action, onCreate, children }: EmptyProps): JSX.Element {
	return (
		<Box textAlign='center' py={10} px={6}>
			{/* @ts-expect-error */}
			<Icon boxSize='50px' as={icon} />
			<Heading as='h2' size='xl' mt={6} mb={2}>
				{title}
			</Heading>
			<Text color='gray.500'>{children}</Text>
			<Button colorScheme='blue' leftIcon={<FiPlus />} mt='4' onClick={onCreate}>
				{action || `Create ${title}`}
			</Button>
		</Box>
	);
}

export default Empty;
