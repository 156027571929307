import { useEffect, useState } from 'react';

import PatternSpec from './PatternSpec';
import { Color } from '../../utility/color';

type PatternPlayerProps = {
	pattern: PatternSpec;
	renderFrame: (colors: Color[]) => JSX.Element;
};

function PatternPlayer({ pattern, renderFrame }: PatternPlayerProps): JSX.Element {
	const [frameIndex, setFrameIndex] = useState(0);

	useEffect(() => {
		if (pattern.frames.length < 1) {
			return () => {};
		}

		const timeout = setTimeout(
			() => setFrameIndex(frameIndex >= pattern.frames.length - 1 ? 0 : frameIndex + 1),
			pattern.frames[frameIndex].duration,
		);
		return () => clearTimeout(timeout);
	});

	if (pattern.frames.length < 1) {
		return <></>;
	}

	return renderFrame(pattern.frames[frameIndex].colors);
}

export default PatternPlayer;
