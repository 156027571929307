import i18next from 'i18next';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Checkbox,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Heading,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FiCopy, FiDelete, FiEdit3, FiPlay, FiSave } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorComponent from '../components/core/Error';
import Loading from '../components/core/Loading';
import PatternEditor from '../components/pattern/PatternEditor';
import PatternFrame from '../components/pattern/PatternFrame';
import PatternPlayer from '../components/pattern/PatternPlayer';
import PatternSpec from '../components/pattern/PatternSpec';
import Prompt from '../components/layout/Prompt';
import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useErrorToast from '../hooks/useErrorToast';
import useLeaveGuard from '../hooks/useLeaveGuard';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import { DetailedPattern } from '../api/client';

function Pattern(): JSX.Element {
	const [dirty, setDirty] = useState(false);
	useLeaveGuard(dirty);
	const playDrawer = useDisclosure();
	const toastError = useErrorToast();
	const navigate = useNavigate();
	const [editedValue, setEditedValue] = useState<PatternSpec>();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const renameModal = useDisclosure();
	const duplicateModal = useDisclosure();
	const { user, organization } = useSession();
	const { id } = useParams();
	const { data, loading, error, refetch } = useQuery(api.retrievePattern, Number(id), organization.id);

	const editPattern = useCallbackWithLoading(async ({ name, raw, isTemplate }: Partial<DetailedPattern>) => {
		let errorOccured = false;

		try {
			await api.patchPattern(Number(id), organization.id, {
				name,
				raw,
				isTemplate,
			});
		} catch (error) {
			errorOccured = true;
		}

		return errorOccured;
	});

	const deletePattern = useCallbackWithLoading(async () => {
		try {
			await api.destroyPattern(Number(id), organization.id);
			navigate('/patterns');
		} catch (_) {}

		deleteModal.onClose();
	});

	const duplicatePattern = useCallbackWithLoading(async (name: string) => {
		try {
			const pattern = await api.createPattern(organization.id, {
				name,
				raw: editedValue!,
			});

			navigate(`/patterns/${pattern.id}`);
			refetch();
		} catch (_) {}

		duplicateModal.onClose();
	});

	const renamePattern = useCallbackWithLoading(async (name: string) => {
		await editPattern({
			name,
			isTemplate: data?.isTemplate,
			raw: editedValue!,
		});

		renameModal.onClose();
		refetch();
	});

	const save = async (manual: boolean) => {
		if (!editedValue) {
			return;
		}

		const error = editedValue.validate();
		if (error) {
			if (manual) {
				toastError(i18next.t('PATTERN_INVALID'), error);
			}

			return;
		}

		const failed = await editPattern({
			name: data!.name,
			raw: editedValue,
			isTemplate: data!.isTemplate,
		});

		if (!failed) {
			setDirty(false);
		}
	};

	const updateEditedValue = (value: PatternSpec) => {
		setEditedValue(value);
		setDirty(true);
	};

	const setTemplate = async (event: ChangeEvent<HTMLInputElement>) => {
		await editPattern({
			name: data!.name,
			raw: editedValue!,
			isTemplate: event.target.checked,
		});

		refetch();
	};

	useEffect(() => {
		if (data && !editedValue) {
			setEditedValue(new PatternSpec(data.raw));
		}
	}, [data, editedValue]);

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <ErrorComponent fullscreen error={error} />;
	}

	const { name, owner, isTemplate } = data!;
	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' mb='4'>
				<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
					{name}
				</Heading>
				<Flex>
					{editPattern.loading && (
						<Flex alignItems='center'>
							<Text mr='2'>{i18next.t('PATTERN_SAVING')}...</Text>
							<Loading />
						</Flex>
					)}
					{user.isStaff && organization.id === owner.id && (
						<Flex alignItems='center' color='gray.500' ml='2'>
							<Checkbox mr='2' defaultChecked={isTemplate} onChange={setTemplate} />
							{i18next.t('PATTERN_PUBLIC_TEMPLATE')}
						</Flex>
					)}
					<Button
						variant='outline'
						leftIcon={<FiSave />}
						colorScheme='blue'
						ml='4'
						disabled={!dirty}
						onClick={() => save(true)}
					>
						{i18next.t(dirty ? 'SAVE' : 'PATTERN_SAVED')}
					</Button>
					<Button variant='outline' leftIcon={<FiPlay />} colorScheme='blue' ml='2' onClick={playDrawer.onOpen}>
						{i18next.t('PATTERN_PREVIEW')}
					</Button>
					<Button leftIcon={<FiCopy />} colorScheme='blue' ml='2' onClick={duplicateModal.onOpen}>
						{i18next.t('PATTERN_DUPLICATE')}
					</Button>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={renameModal.onOpen}>
						{i18next.t('RENAME')}
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						{i18next.t('DELETE')}
					</Button>
				</Flex>
			</Flex>

			<PatternEditor value={editedValue} setValue={updateEditedValue} />
			<Prompt
				title={i18next.t('PATTERN_RENAME_TITLE')}
				inputLabel={i18next.t('NAME')}
				okButtonText={i18next.t('RENAME')}
				placeholder={i18next.t('PATTERN_RENAME_PLACEHOLDER')}
				loading={renamePattern.loading}
				handleSubmit={renamePattern}
				disclosure={renameModal}
				initialValue={name}
			/>

			<Prompt
				title={i18next.t('PATTERN_DUPLICATE_TITLE')}
				inputLabel={i18next.t('NAME')}
				okButtonText={i18next.t('DUPLICATE')}
				placeholder={i18next.t('PATTERN_RENAME_PLACEHOLDER')}
				loading={duplicatePattern.loading}
				handleSubmit={duplicatePattern}
				disclosure={duplicateModal}
				initialValue={`${name} (${i18next.t('PATTERN_RENAME_COPY')})`}
			/>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('PATTERN_DELETE_TITLE')}
						</AlertDialogHeader>

						<AlertDialogBody>{i18next.t('PATTERN_DELETE_TIP')}</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' ml={3} onClick={deletePattern} isLoading={deletePattern.loading}>
								{i18next.t('DELETE')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			{editedValue && (
				<Drawer placement='top' onClose={playDrawer.onClose} isOpen={playDrawer.isOpen}>
					<DrawerOverlay />
					<DrawerContent background='transparent'>
						<DrawerBody>
							<PatternPlayer
								pattern={editedValue}
								renderFrame={(colors) => (
									<div>
										<PatternFrame colors={colors} />
									</div>
								)}
							/>
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			)}
		</>
	);
}

export default Pattern;
