import { useEffect, useState } from 'react';

import { Storage, storageEvents } from '../utility/storage';

function useStorage(type: Storage, key: string): string | null {
	const storage = window[`${type}Storage`];
	const [value, setValue] = useState(storage.getItem(key));

	useEffect(() => {
		const handleWrite = (updatedKey: string, value: string) => {
			if (updatedKey !== key) {
				return;
			}

			setValue(value);
		};

		storageEvents.on(type, handleWrite);
		return () => void storageEvents.off(type, handleWrite);
	});

	return value;
}

function useLocalStorage(key: string): string | null {
	return useStorage('local', key);
}

function useSessionStorage(key: string): string | null {
	return useStorage('session', key);
}

export { useLocalStorage, useSessionStorage };
