import humanizeDuration from 'humanize-duration';
import i18next from 'i18next';
import {
	Box,
	Button,
	Flex,
	Heading,
	Icon,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tag,
	Text,
} from '@chakra-ui/react';
import { FiActivity } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import BeaconPreview from '../beacon/BeaconPreview';
import ControllableRemoteOrderPatternPlayer from '../pattern/ControllableRemoteOrderPatternPlayer';
import api from '../../api';
import useCallbackWithLoading from '../../hooks/useCallbackWithLoading';
import useSession from '../../hooks/useSession';
import { DetailedOrder } from '../../api/client';

type OrderListProps = {
	orders: DetailedOrder[];
	spacing: string;
	refetch: () => void;
};

function OrderList({ orders, spacing, refetch }: OrderListProps): JSX.Element {
	const { organization } = useSession();
	const [cancelLoading, setCancelLoading] = useState(false);
	const [hovered, setHovered] = useState<DetailedOrder>();

	const destroyOrder = useCallbackWithLoading(async (order: DetailedOrder) => {
		await api.destroyOrder(order.beacon, order.id, organization.id);
		refetch();
	});

	const cancel = async (order: DetailedOrder) => {
		setCancelLoading(true);
		await destroyOrder(order);
		refetch();
		setCancelLoading(false);
	};

	const cancelAll = async () => {
		setCancelLoading(true);

		for (const order of orders) {
			if (order.status === 'pending') {
				await destroyOrder(order);
			}
		}

		refetch();
		setCancelLoading(false);
	};

	return (
		<Box position='relative'>
			{orders.find((order) => order.status === 'pending') && (
				<Box position='absolute' right='0' top='-8'>
					<Popover placement='auto-start'>
						<PopoverTrigger>
							<Button variant='link' color='gray.500'>
								{i18next.t('ORDER_LIST_CLEAR_ALL_TITLE')}
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>{i18next.t('ORDER_LIST_CLEAR_ALL')}</PopoverHeader>
							<PopoverBody>
								{i18next.t('ORDER_LIST_CLEAR_ALL_TIP')}
								<Button
									p='4'
									colorScheme='red'
									mt='2'
									mb='1'
									borderRadius='xl'
									w='100%'
									isLoading={cancelLoading}
									onClick={cancelAll}
								>
									{i18next.t('ORDER_LIST_CLEAR_ALL_CONFIRM')}
								</Button>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Box>
			)}
			{orders.length ? (
				orders.map((order) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const trigger = order.trigger as any;
					return (
						<>
							<Flex
								p='4'
								mb={spacing}
								borderRadius='xl'
								w='100%'
								py='2'
								key={order.id}
								bg='whiteAlpha.200'
								onMouseEnter={() => setHovered(order)}
								onMouseLeave={() => setHovered(undefined)}
							>
								<Flex justifyContent='space-between' alignItems='center' w='100%'>
									<Flex alignItems='center'>
										<Icon mr='2' fontSize='14' as={FiActivity} />
										{order.status === 'pending' ? (
											<Tag bg='yellow.500' mr='2'>
												{i18next.t('ORDER_LIST_STATUS_PENDING')}
											</Tag>
										) : order.status === 'played' ? (
											<Tag bg='green.500' mr='2'>
												{i18next.t('ORDER_LIST_STATUS_PLAYED')}
											</Tag>
										) : (
											<Tag bg='red.500' mr='2'>
												{i18next.t('ORDER_LIST_STATUS_FAILED')}
											</Tag>
										)}
										<Heading size='xs' mr='1'>
											#{order.id}
										</Heading>
										<Text fontSize='14px' color='gray.500'>
											{order.details}
										</Text>
									</Flex>
									<Flex alignItems='center'>
										<Text color='gray.500' fontSize='14px' mr='2'>
											{formatTiming(order)}&nbsp;&nbsp;·
										</Text>
										<Text color='gray.500' fontSize='14px'>
											{i18next.t('FROM')}
											{trigger?.name ? (
												<Link to={`/integrations/${trigger.kind.toLowerCase()}/${trigger.id}`}>
													<Button variant='link' color='gray.500' fontSize='14px' ml='1' minW='0'>
														<Text overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
															{trigger.name}
														</Text>
													</Button>
												</Link>
											) : (
												` ${i18next.t('ORDER_LIST_DELETED_TRIGGER')}`
											)}
										</Text>
										{order.status === 'pending' && (
											<>
												<Text color='gray.500' fontSize='14px' ml='2'>
													·&nbsp;&nbsp;
												</Text>
												<Popover placement='auto-start'>
													<PopoverTrigger>
														<Button variant='link' color='red.500' fontSize='14px'>
															{i18next.t('CANCEL')}
														</Button>
													</PopoverTrigger>
													<PopoverContent>
														<PopoverArrow />
														<PopoverCloseButton />
														<PopoverHeader>{i18next.t('ORDER_LIST_CANCEL_TITLE')}</PopoverHeader>
														<PopoverBody>
															{i18next.t('ORDER_LIST_CANCEL_TIP')}
															<Button
																p='4'
																colorScheme='red'
																mt='2'
																mb='1'
																borderRadius='xl'
																w='100%'
																isLoading={cancelLoading}
																onClick={() => cancel(order)}
															>
																{i18next.t('CANCEL')}
															</Button>
														</PopoverBody>
													</PopoverContent>
												</Popover>
											</>
										)}
									</Flex>
								</Flex>
								<Flex justifyContent='center' alignItems='center'>
									<Text color='gray.500' fontSize='14px' ml='2'>
										·&nbsp;&nbsp;
									</Text>
									<Box
										w='20px'
										h='20px'
										transform='rotate(360deg)'
										borderRadius='50%'
										overflow='hidden'
										position='relative'
									>
										<ControllableRemoteOrderPatternPlayer
											play={hovered?.id === order.id}
											organizationId={order.owner.id}
											beaconId={order.beacon}
											orderId={order.id}
											renderFrame={(colors) => <BeaconPreview colors={colors} bg='gray.700' />}
										/>
									</Box>
								</Flex>
							</Flex>
						</>
					);
				})
			) : (
				<Flex justifyContent='center' color='gray.500'>
					{i18next.t('ORDER_LIST_EMPTY')}
				</Flex>
			)}
		</Box>
	);
}

function formatTiming(order: DetailedOrder): string {
	if (i18next.language === 'fr') {
		return formatTimingFrench(order);
	}

	return formatTimingEnglish(order);
}

function formatTimingEnglish(order: DetailedOrder): string {
	const createdAt = new Date(order.createdAt);
	const lastSeen = new Date(order.lastPublishedAt);
	const formatAge = (age: Date) => {
		const delta = Date.now() - age.getTime();
		if (delta < 24 * 60 * 60 * 1000) {
			return `${humanizeDuration(delta, {
				largest: 1,
				round: true,
			})} ago`;
		}

		return `on ${age.toLocaleString()}`;
	};

	switch (order.status) {
		case 'played':
			return `Played ${formatAge(lastSeen)}`;

		case 'pending':
			let message = `Created ${formatAge(createdAt)}`;
			if (lastSeen.getTime() > 0 && lastSeen.getTime() - createdAt.getTime() > 1000) {
				message += `, last retry ${formatAge(lastSeen)}`;
			}
			return message;

		case 'failed':
			return `Failed ${formatAge(lastSeen)}`;
	}

	return '<developer error>';
}

function formatTimingFrench(order: DetailedOrder): string {
	const createdAt = new Date(order.createdAt);
	const lastSeen = new Date(order.lastPublishedAt);
	const formatAge = (age: Date) => {
		const delta = Date.now() - age.getTime();
		if (delta < 24 * 60 * 60 * 1000) {
			return `il y a ${humanizeDuration(delta, {
				largest: 1,
				round: true,
				language: 'fr',
			})}`;
		}

		return `le ${age.toLocaleString()}`;
	};

	switch (order.status) {
		case 'played':
			return `Joué ${formatAge(lastSeen)}`;

		case 'pending':
			let message = `En attente ${formatAge(createdAt)}`;
			if (lastSeen.getTime() > 0 && lastSeen.getTime() - createdAt.getTime() > 1000) {
				message += `, dernier essai ${formatAge(lastSeen)}`;
			}
			return message;

		case 'failed':
			return `Échoué ${formatAge(lastSeen)}`;
	}

	return '<developer error>';
}

export default OrderList;
