import i18next from 'i18next';
import { Field, FieldProps } from 'formik';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

import { SimplePattern } from '../../api/client';

type PatternSelectProps = {
	name: string;
	required?: boolean;
	label: React.ReactNode;
	patterns: SimplePattern[];
};

function PatternSelect({ name, label, required, patterns }: PatternSelectProps): JSX.Element {
	return (
		<Field
			name={name}
			validate={(value: string) => (required && value.toString() === '0' ? i18next.t('PATTERN_SELECT_MISSING') : '')}
		>
			{({ field, form }: FieldProps) => (
				<FormControl
					isRequired={required}
					id={name}
					mt='3'
					isInvalid={Boolean(form.errors[name] && form.touched[name])}
				>
					<FormLabel htmlFor={name}>{label}</FormLabel>
					<Select id={name} {...field}>
						{required ? (
							<option disabled value={0}>
								{i18next.t('PATTERN_SELECT_PLACEHOLDER')}
							</option>
						) : (
							<option value={0}>{i18next.t('PATTERN_SELECT_NONE')}</option>
						)}

						{patterns.map(({ id, name }) => (
							<option value={id} key={id}>
								{name}
							</option>
						))}
					</Select>
				</FormControl>
			)}
		</Field>
	);
}

export default PatternSelect;
