import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BeaconSerialInterface from '../components/serial/BeaconSerialInterface';
import MicropythonSerialInterface from '../components/serial/MicropythonSerialInterface';
import useErrorToast from './useErrorToast';
import SerialInterface, { IdMismatchError } from '../components/serial/SerialInterface';

function useSerialInterface<T extends SerialInterface>(type: { new (expectedBeaconNetworkId: string): T }): T | null {
	const { expectedBeaconNetworkId = '' } = useParams();
	const serialInterface = useRef(new type(expectedBeaconNetworkId));
	const toastError = useErrorToast();
	const navigate = useNavigate();
	const [connected, setConnected] = useState(false);

	useEffect(() => {
		if (serialInterface.current.isConnecting || connected) {
			return;
		}

		const connect = async () => {
			try {
				await serialInterface.current.connect();
				setConnected(true);
			} catch (error) {
				if (error instanceof IdMismatchError) {
					toastError('ID mismatch', error.message);
				} else if (![DOMException.NOT_FOUND_ERR, DOMException.SECURITY_ERR].includes((error as DOMException).code)) {
					toastError('Connection failure', (error as Error).toString());
				}

				navigate(-1);
			}
		};

		connect();
	}, [connected, navigate, toastError]);

	return connected ? serialInterface.current : null;
}

export const useBeaconSerialInterface = (): BeaconSerialInterface | null =>
	useSerialInterface<BeaconSerialInterface>(BeaconSerialInterface);
export const useMicropythonSerialInterface = (): MicropythonSerialInterface | null =>
	useSerialInterface<MicropythonSerialInterface>(MicropythonSerialInterface);

export default useSerialInterface;
