import i18next from 'i18next';
import { Box, Button, Flex, Heading, Icon } from '@chakra-ui/react';
import { FiChevronRight, FiFolder } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { SimpleGroupTarget } from '../../api/client';

type GroupListProps = {
	groups: SimpleGroupTarget[];
	spacing?: string;
	link?: boolean;
	onSelect?: (id: number) => void;
};

function GroupList({ groups, spacing, link, onSelect }: GroupListProps): JSX.Element {
	return (
		<Box mb='4'>
			{groups.length ? (
				groups.map(({ id, name, owner /* upBeaconsCount, downBeaconsCount */ }) => {
					const button = (
						<Button p='4' mb={spacing || '2'} borderRadius='xl' w='100%' key={id} onClick={() => onSelect?.(id)}>
							<Flex justifyContent='space-between' alignItems='center' w='100%'>
								<Flex alignItems='center'>
									<Icon mr='2' fontSize='14' as={FiFolder} />
									<Heading size='xs' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='15vw'>
										{name}
									</Heading>
									{/* {upBeaconsCount > 0 && (
										<Tag bg='green.500' ml='2'>
											{upBeaconsCount} online beacon{upBeaconsCount === 1 ? '' : 's'}
										</Tag>
									)}
									{downBeaconsCount > 0 && (
										<Tag bg='red.500' ml='2'>
											{downBeaconsCount} offline beacon{downBeaconsCount === 1 ? '' : 's'}
										</Tag>
									)} */}
								</Flex>
								<Flex alignItems='center'>
									<FiChevronRight />
								</Flex>
							</Flex>
						</Button>
					);

					if (link) {
						return <Link to={`/groups/${id}`}>{button}</Link>;
					}

					return button;
				})
			) : (
				<Flex justifyContent='center' color='gray.500'>
					{i18next.t('GROUPS_LIST_EMPTY')}
				</Flex>
			)}
		</Box>
	);
}

export default GroupList;
