import { Color } from '../../utility/color';

export type PatternFrame = {
	duration: number;
	colors: Color[];
};

const MAX_REPEAT = 255;
const MAX_FRAME_DURATION = 255 * 8;

class PatternSpec {
	frames!: PatternFrame[];

	repeat!: number;

	constructor(data: Partial<PatternSpec>) {
		if (typeof data !== 'object') {
			throw new Error(`invalid data type for pattern: ${typeof data}`);
		}

		if (typeof data.repeat !== 'number') {
			throw new Error(`missing or invalid \`repeat\` key`);
		}

		if (!data.frames) {
			throw new Error(`missing \`frames\` key`);
		}

		data.frames.forEach((frame) => {
			if (typeof frame.duration !== 'number') {
				throw new Error(`invalid frame duration: ${frame.duration}`);
			}

			if (!Array.isArray(frame.colors)) {
				throw new Error(`\`frames.colors\` should be an array`);
			}

			if (frame.colors.length !== 12) {
				throw new Error(`\`frames.colors\` should have 12 items`);
			}

			frame.colors.forEach((color) => {
				if (typeof color.r !== 'number') {
					throw new Error(`\`frames.colors[].r\` should be a number`);
				}

				if (typeof color.g !== 'number') {
					throw new Error(`\`frames.colors[].g\` should be a number`);
				}

				if (typeof color.b !== 'number') {
					throw new Error(`\`frames.colors[].b\` should be a number`);
				}
			});
		});

		Object.assign(this, data);
	}

	compileJSON(): string {
		return JSON.stringify(this);
	}

	// getDuration(): number {
	// 	return this.repeat * this.frames.reduce((total, frame) => total + frame.duration, 0);
	// }

	validate(): Error | null {
		if (this.repeat <= 0) {
			return new Error('Repeat count must be positive');
		}

		if (this.repeat > MAX_REPEAT) {
			return new Error(`Repeat count cannot exceed ${MAX_REPEAT}.`);
		}

		for (const frame of this.frames) {
			if (frame.duration <= 0) {
				return new Error('Frame duration must be positive');
			}

			if (frame.duration > MAX_FRAME_DURATION) {
				return new Error(`Frame duration cannot exceed ${MAX_FRAME_DURATION}.`);
			}
		}

		return null;
	}

	static parseJSON(data: string): PatternSpec {
		return new PatternSpec(JSON.parse(data));
	}
}

export default PatternSpec;
