import Loading from '../core/Loading';
import PatternPlayer from './PatternPlayer';
import PatternSpec from './PatternSpec';
import api from '../../api';
import useQuery from '../../hooks/useQuery';
import { Color } from '../../utility/color';

type RemoteOrderPatternPlayerProps = {
	organizationId: number;
	beaconId: number;
	orderId: number;
	renderFrame: (colors: Color[]) => JSX.Element;
};

function RemoteOrderPatternPlayer({
	organizationId,
	beaconId,
	orderId,
	renderFrame,
}: RemoteOrderPatternPlayerProps): JSX.Element {
	const { data, loading } = useQuery(api.retrieveOrder, beaconId, orderId, organizationId);

	if (loading) {
		return <Loading mt='2px' />;
	}

	// @ts-expect-error
	return <PatternPlayer pattern={PatternSpec.parseJSON(data!.rawPattern)} renderFrame={renderFrame} />;
}

export default RemoteOrderPatternPlayer;
