import ReactCreatableSelect from 'react-select/creatable';

type SelectItem<T> = {
	label: T | React.ReactNode;
	value: T;
};

type CreatableSelectProps<T> = {
	value: SelectItem<T>;
	options?: SelectItem<T>[];
	onChange: (value: SelectItem<T>) => void;
	width?: number;
	menuMaxHeight?: string;
};

function CreatableSelect<T>({ value, onChange, width, menuMaxHeight, options }: CreatableSelectProps<T>): JSX.Element {
	return (
		<ReactCreatableSelect
			value={value}
			// @ts-expect-error
			onChange={onChange}
			options={options}
			placeholder='Type or select a key...'
			styles={{
				placeholder: (provided) => ({
					...provided,
					color: 'var(--chakra-colors-gray-400)',
				}),
				control: (provided) => ({
					...provided,
					background: 'transparent',
					border: '1px solid rgba(255, 255, 255, 0.15)',
				}),
				container: (provided) => ({
					...provided,
					width, // TODO: better sizing handling
					zIndex: 1000,
				}),
				menu: (provided) => ({
					...provided,
					background: 'var(--chakra-colors-gray-700)',
				}),
				option: (provided, state) => ({
					...provided,
					background: state.isSelected ? 'var(--chakra-colors-blue-500)' : 'var(--chakra-colors-gray-700)',
					color: 'var(--chakra-colors-gray-300)',
					':hover': {
						background: 'var(--chakra-colors-gray-600)',
					},
				}),
				singleValue: (provided) => ({
					...provided,
					color: 'var(--chakra-colors-gray-300)',
				}),
				input: (provided) => ({
					...provided,
					color: 'var(--chakra-colors-gray-300)',
				}),
			}}
		/>
	);
}

export default CreatableSelect;
