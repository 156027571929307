import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';

type TooltipProps = ChakraTooltipProps & {
	children: React.ReactNode;
};

function Tooltip({ children, ...props }: TooltipProps): JSX.Element {
	return (
		<ChakraTooltip background='rgb(41, 43, 51)' color='white' placement='top' {...props}>
			{children}
		</ChakraTooltip>
	);
}

export default Tooltip;
