import { createContext, useEffect, useState } from 'react';

import { getHost } from '../../utility/host';

const FirmwareVersionContext = createContext('');

type FirmwareVersionContextProviderProps = {
	children: JSX.Element;
};

export function FirmwareVersionContextProvider({ children }: FirmwareVersionContextProviderProps): JSX.Element {
	const [version, setVersion] = useState('');

	useEffect(() => {
		const getFirmwareVersion = async () => {
			const response = await fetch(`${getHost()}/api/v1/firmware/version`);
			setVersion(await response.json());
		};

		getFirmwareVersion();
	}, [setVersion]);

	return <FirmwareVersionContext.Provider value={version}>{children}</FirmwareVersionContext.Provider>;
}

export default FirmwareVersionContext;
