import BeaconSerialTransformer from './BeaconSerialTransformer';
import SerialInterface, { IdMismatchError } from './SerialInterface';

export type WifiAccessPoint = {
	ssid: string;
	attenuation: number;
	locked: boolean;
};

class BeaconSerialInterface extends SerialInterface {

	async connect(requestPort: boolean = true): Promise<void> {
		await super.connect(requestPort);

		if (!this.expectedBeaconNetworkId) {
			return;
		}

		const beaconId = await this.getBeaconId();

		if (beaconId !== this.expectedBeaconNetworkId) {
			this.disconnect();
			throw new IdMismatchError(`The connected beacon #${beaconId} does not match the expected beacon ID #${this.expectedBeaconNetworkId}`);
		}
	}

	setConfig(key: string, value: string): Promise<string> {
		return this.sendCommand(`set_config ${key} ${value}`);
	}

	commitConfig(): Promise<string> {
		return this.sendCommand('commit_config');
	}

	getConfig(): Promise<string> {
		return this.sendCommand('get_config', 3);
	}

	async configure(): Promise<void> {
		await this.sendCommand('configure');
	}

	async listWifiAccessPoints(): Promise<WifiAccessPoint[]> {
		const result = await this.sendCommand('list_wifi_access_points', 3);
		const wifis = JSON.parse(result) as unknown[][];
		return wifis.map((wifi) => ({
			ssid: wifi[0] as string,
			attenuation: wifi[3] as number,
			locked: (wifi[4] as number) !== 0,
		}));
	}

	getBeaconId(): Promise<string> {
		return this.sendCommand('get_beacon_id', 3);
	}

	blink(pattern: string): Promise<string> {
		return this.sendCommand(`blink ${pattern}`);
	}

	help(): Promise<string> {
		return this.sendCommand('help', 3);
	}

	async reset(): Promise<void> {
		await this.sendCommand('reset');
		await new Promise((r) => setTimeout(r, 1000)); // wait for reset
	}

	async restart(): Promise<void> {
		await this.sendCommand('restart');
	}

	protected async open(): Promise<void> {
		await this.openWithTransformer(new BeaconSerialTransformer());
	}

	protected get endOfTransmission(): string {
		return '\r\n';
	}
}

export default BeaconSerialInterface;
