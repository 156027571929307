import { Box, BoxProps } from '@chakra-ui/react';

type CircularArcProps = BoxProps & {
	rotation: number;
	skew: number;
};

function CircularArc({ rotation, skew, ...props }: CircularArcProps): JSX.Element {
	return (
		<Box
			position='absolute'
			top='50%'
			left='50%'
			w='50vw'
			h='50vw'
			transform={`rotate(${rotation}deg) skew(${skew}deg)`}
			transformOrigin='0 0'
			{...props}
		/>
	);
}

export default CircularArc;
