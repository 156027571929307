import { Box, Button } from '@chakra-ui/react';

import CopiableCode from '../../core/CopiableCode';

type RobustaInstructionsProps = {
	url: string;
};

function RobustaInstructions({ url }: RobustaInstructionsProps): JSX.Element {
	return (
		<>
			To configure this integration in your Robusta instance, follow these steps:
			<Box ml='6' my='2'>
				<ol>
					<li>
						Add a new sink of type victorops to your Robusta configuration file:
						<pre>
							<CopiableCode bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mt='1' display='flex'>
								{generateRobustaConfig(url)}
							</CopiableCode>
						</pre>
					</li>
					<li style={{ marginTop: 12 }}>Apply the config file depending on your deployment method</li>
				</ol>
			</Box>
			(Optional) Robusta is able to filter sent alerts. Learn more about sinks configuration in
			<a href='https://docs.robusta.dev/master/configuration/configuring-sinks.html' target='_blank' rel='noreferrer'>
				<Button variant='link' colorScheme='blue' ml='1'>
					Robusta documentation
				</Button>
			</a>
			.
		</>
	);
}

function generateRobustaConfig(url: string) {
	return `sinksConfig:
  - victorops_sink:
      name: blinky_sink
      url: ${url}`;
}

export default RobustaInstructions;
