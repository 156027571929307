import i18next from 'i18next';
import throttle from 'lodash/throttle';
import { Field, FieldProps, FormikValues } from 'formik';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Switch,
	Text,
} from '@chakra-ui/react';
import { useCallback } from 'react';

import api from '../../api';
import { DetailedBeacon } from '../../api/client';

type BeaconFormProps = {
	organizationId: number;
	beaconId: number;
};

function BeaconForm({ organizationId, beaconId }: BeaconFormProps): JSX.Element {
	const previewUpdateBeacon = useCallback(
		throttle((settings: Partial<DetailedBeacon>) => {
			api.previewUpdateBeacon(beaconId, organizationId, settings);
		}, 200),
		[organizationId, beaconId],
	);

	// eslint-disable-next-line comma-spacing
	const onChange = <T,>(form: FormikValues, field: string, value: T) => {
		form.setFieldValue(field, value);
		previewUpdateBeacon({ [field]: value });
	};

	return (
		<>
			<Field name='name'>
				{({ field, form }: FieldProps) => (
					<FormControl isRequired isInvalid={Boolean(form.errors.name && form.touched.name)}>
						<FormLabel htmlFor='name'>{i18next.t('NAME')}</FormLabel>
						<Input id='name' placeholder={i18next.t('BEACON_NAME_PLACEHOLDER')} autoComplete='none' {...field} />
						<FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name='ceilingInstallation'>
				{({ field, form }: FieldProps) => (
					<FormControl
						isInvalid={Boolean(form.errors.ceilingInstallation && form.touched.ceilingInstallation)}
					>
						<FormLabel htmlFor='ceilingInstallation' mt='3'>
							{i18next.t('BEACON_SETTING_CEILING_INSTALLATION')}
						</FormLabel>
						<Switch
							id='ceilingInstallation'
							name={field.name}
							isChecked={field.value}
							onBlur={field.onBlur}
							onChange={() => onChange(form, 'ceilingInstallation', !field.value)}
						/>
						<FormErrorMessage>{form.errors.ceilingInstallation as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name='statusLedBrightness'>
				{({ field, form }: FieldProps) => (
					<FormControl
						isRequired
						isInvalid={Boolean(form.errors.statusLedBrightness && form.touched.statusLedBrightness)}
					>
						<FormLabel htmlFor='statusLedBrightness' mt='3'>
							{i18next.t('BEACON_SETTING_STATUS_LED_BRIGHTNESS', { value: field.value })}
						</FormLabel>
						<Slider
							id='statusLedBrightness'
							name={field.name}
							value={field.value}
							step={5}
							onBlur={field.onBlur}
							onChange={(value) => onChange(form, 'statusLedBrightness', value)}
						>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb />
						</Slider>
						{field.value > 0 ? null : (
							<Text mt='1' fontSize='xs' color='orange'>
								{i18next.t('BEACON_SETTING_STATUS_LED_BRIGHTNESS_WARNING')}
							</Text>
						)}
						<FormErrorMessage>{form.errors.statusLedBrightness as string}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default BeaconForm;
