import { Box } from '@chakra-ui/react';
import CopiableCode from '../../core/CopiableCode';

import addToContactGroup from './addToContactGroup.png';
import newContactGroup from './newContactGroup.png';
import newTest from './newTest.png';

type StatusCakeInstructionsProps = {
	url: string;
	token: string;
};

function StatusCakeInstructions({ url, token }: StatusCakeInstructionsProps): JSX.Element {
	return (
		<Box>
			To configure this integration in your StatusCake account, follow these steps:
			<Box ml='6' my='2'>
				<ol>
					<li>
						Create a new contact group:
						<Box ml='6' mt='1'>
							<ol type='a'>
								<li>
									On the StatusCake dashboard, navigate to Alerting {'=>'} Contact Groups {'=>'} New Contact Group
									<img src={newContactGroup} alt='Add Webhook Steps' style={{ marginTop: 8 }} />
								</li>
								<li style={{ marginTop: 12 }}>
									Fill in the following information in the form:
									<Box ml='6' mt='1'>
										<ul>
											<li>
												<b>Group name</b>: <CopiableCode>Blinky</CopiableCode>
											</li>
											<li>
												<b>Webhook URL</b>: <CopiableCode>{url}</CopiableCode>
											</li>
											<li>
												<b>Webhook Method</b>: POST
											</li>
										</ul>
									</Box>
								</li>
								<li>Then save the contact group using the "Save now" button</li>
							</ol>
						</Box>
					</li>
					<li style={{ marginTop: 12 }}>
						Use the "Blinky" contact group on some tests to trigger Blinky when status change:
						<Box ml='6' mt='1'>
							<ol type='a'>
								<li>
									On the StatusCake dashboard, navigate to Monitoring {'=>'} Uptime {'=>'} All Tests; then edit the test
									you want
									<img src={newTest} alt='Add Test Steps' style={{ marginTop: 8 }} />
								</li>
								<li style={{ marginTop: 12 }}>
									In the form, add "Blinky" to the list of Contacts Groups:
									<img src={addToContactGroup} alt='Add to contact group steps' style={{ marginTop: 8 }} />
								</li>
								<li style={{ marginTop: 12 }}>Save the test using "Save Now" button</li>
								<li style={{ marginTop: 12 }}>
									You can also use the Bulk Update feature to edit multiple tests at once
								</li>
							</ol>
						</Box>
					</li>
				</ol>
			</Box>
		</Box>
	);
}

export default StatusCakeInstructions;
