import i18next from 'i18next';
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Heading,
} from '@chakra-ui/react';
import { FiDelete, FiEdit3, FiSave } from 'react-icons/fi';
import { useState } from 'react';

import EditableStringList from '../../core/EditableStringList';
import FilterList from '../../core/FilterList';
import Reaction from '../../reaction/Reaction';
import { DetailedPagerDutyMatch, SimplePattern, SimpleTarget } from '../../../api/client';
import { FilterSpec } from '../../core/Filter';

type PagerDutyMatchProps = {
	match: DetailedPagerDutyMatch;
	save: () => void;
	openReactionEditionModal: (match: DetailedPagerDutyMatch) => void;
	targets: SimpleTarget[];
	patterns: SimplePattern[];
	refetch: () => void;
	removeMatch: (id: number) => void;
};

function PagerDutyMatch({
	match,
	targets,
	patterns,
	refetch,
	openReactionEditionModal,
	removeMatch,
	save,
}: PagerDutyMatchProps): JSX.Element {
	const [dirty, setDirty] = useState(false);
	const [filters, setFilters] = useState(match.filters);
	const [events, setEvents] = useState((match.events || []) as string[]);

	const addFilter = (filter: FilterSpec) => {
		setFilters([...filters, filter]);
		setDirty(true);
	};

	const removeFilter = (index: number) => {
		setFilters(filters.filter((_, i) => i !== index));
		setDirty(true);
	};

	const addEvent = (event: string) => {
		setEvents([...events, event]);
		setDirty(true);
	};

	const removeEvent = (index: number) => {
		setEvents(events.filter((_, i) => i !== index));
		setDirty(true);
	};

	const saveAndMarkClean = async (...args: unknown[]) => {
		// @ts-expect-error
		await save(...args);
		setDirty(false);
	};

	return (
		<AccordionItem key={match.id}>
			<h2>
				<AccordionButton>
					{match.name}
					<AccordionIcon />
				</AccordionButton>
			</h2>

			<AccordionPanel>
				<Reaction reaction={match.reaction} targets={targets} patterns={patterns} refetch={refetch} />

				<Box mt='6' />

				<Heading size='sm' mb='2'>
					{i18next.t('PAGERDUTY_EVENTS_TITLE')}
				</Heading>
				<EditableStringList items={events} addItem={addEvent} removeItem={removeEvent} options={pagerDutyEvents} />

				<Heading size='sm' mb='2' mt='4'>
					{i18next.t('FILTER_LIST_TITLE')}
				</Heading>
				<FilterList filters={filters} addFilter={addFilter} removeFilter={removeFilter} />

				<Flex mt='2'>
					<Button
						isDisabled={!dirty}
						colorScheme='blue'
						leftIcon={<FiSave />}
						mt='4'
						mr='2'
						onClick={() => saveAndMarkClean(match.id, match.name, events, filters)}
					>
						{dirty ? 'Save' : 'Saved'}
					</Button>
					<Button
						variant='outline'
						colorScheme='blue'
						leftIcon={<FiEdit3 />}
						mt='4'
						mr='2'
						onClick={() => openReactionEditionModal(match)}
					>
						{i18next.t('EDIT')}
					</Button>
					<Button
						variant='outline'
						colorScheme='red'
						leftIcon={<FiDelete />}
						mt='4'
						onClick={() => removeMatch(match.id)}
					>
						{i18next.t('DELETE')}
					</Button>
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
}

const pagerDutyEvents = [
	'incident.acknowledged',
	'incident.annotated',
	'incident.conference_bridge.updated',
	'incident.custom_field_values.updated',
	'incident.delegated',
	'incident.escalated',
	'incident.priority_updated',
	'incident.reassigned',
	'incident.reopened',
	'incident.resolved',
	'incident.responder.added',
	'incident.responder.replied',
	'incident.status_update_published',
	'incident.triggered',
	'incident.unacknowledged',
	'incident.workflow.completed',
	'incident.workflow.started',
	'service.updated',
	'service.created',
	'service.deleted',
];

export default PagerDutyMatch;
