import { FiAnchor, FiGithub, FiGitlab } from 'react-icons/fi';
import { Flex } from '@chakra-ui/react';
import { SiPagerduty, SiPrometheus } from 'react-icons/si';

import observium from './observium/icon.png';

type IntegrationIconProps = {
	kind: string;
	scale?: string;
};

function IntegrationIcon({ kind, scale }: IntegrationIconProps): JSX.Element {
	switch (kind) {
		case 'Webhook':
			return <FiAnchor />;
		case 'Gitlab':
			return <FiGitlab />;
		case 'Gitlab2':
			return (
				<Flex height='1rem' width='1rem'>
					<img
						src='/integrations/gitlab.svg'
						alt='Gitlab Logo'
						style={{
							maxHeight: '1em',
							scale: scale || '2',
						}}
					/>
				</Flex>
			);
		case 'Github':
			return <FiGithub />;
		case 'Prometheus':
			return <SiPrometheus />;
		case 'StatusCake':
			return <img src='/integrations/statuscake.svg' alt='StatusCake Logo' style={{ maxHeight: '1em' }} />;
		case 'Observium':
			return <img src={observium} alt='Observium Logo' style={{ maxHeight: '1em' }} />;
		case 'PagerDuty':
			return <SiPagerduty />;
		case 'Robusta':
			return <img src='/integrations/robusta.svg' alt='Robusta Logo' style={{ maxHeight: '1em' }} />;
	}

	return <></>;
}

export default IntegrationIcon;
