import { Flex, FlexProps } from '@chakra-ui/react';

type CardProps = FlexProps;

function Card({ children, ...props }: CardProps): JSX.Element {
	return (
		<Flex p='4' borderRadius='xl' w='100%' bg='whiteAlpha.200' {...props}>
			{children}
		</Flex>
	);
}

export default Card;
