import { adjectives, animals, uniqueNamesGenerator } from 'unique-names-generator';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../components/core/Loading';
import api from '../api';
import useSession from '../hooks/useSession';
import { BrowserApiError } from '../api/error';

function Associate(): JSX.Element {
	const navigate = useNavigate();
	const { organization } = useSession();
	const { networkId } = useParams();

	useEffect(() => {
		(async () => {
			try {
				const beacon = await api.createBeacon(organization.id, {
					name: uniqueNamesGenerator({
						dictionaries: [adjectives, animals],
						separator: ' ',
						style: 'capital',
						length: 2,
					}),
					hardware: 'unknown',
					version: 'unknown',
					status: 'down',
					networkId: networkId!,
				});

				navigate(`/beacons/${beacon.id}`);
			} catch (error) {
				const alreadyExistsError = () => ({
					title: 'Already associated',
					message: 'This beacon is already associated to an organization',
				});

				(error as BrowserApiError).reword('unique', alreadyExistsError);
				(error as BrowserApiError).reword('invalid', alreadyExistsError);

				navigate('/beacons');
			}
		})();
	}, []);

	return <Loading />;
}

export default Associate;
