import i18next from 'i18next';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';

import FilterEditor from './FilterEditor';
import Filter, { FilterSpec } from './Filter';

type FilterListProps = {
	keyOptions?: string[];
	filters: FilterSpec[];
	addFilter: (filter: FilterSpec) => void;
	removeFilter: (index: number) => void;
};

function FilterList({ keyOptions, filters, addFilter, removeFilter }: FilterListProps): JSX.Element {
	const createModal = useDisclosure();
	return (
		<>
			<Box border='1px solid rgba(255, 255, 255, 0.1)' borderRadius='lg' p='1' alignItems='center'>
				{filters.length === 0 && (
					<Flex p='2' color='gray'>
						{i18next.t('FILTER_LIST_EMPTY')}
					</Flex>
				)}

				{filters.map((filter, index) => (
					<Filter key={index} filter={filter} onRemove={() => removeFilter(index)} />
				))}

				<Button variant='link' colorScheme='blue' onClick={createModal.onOpen} m='2'>
					+ {i18next.t('FILTER_LIST_ADD')}
				</Button>
			</Box>

			<Modal isOpen={createModal.isOpen} onClose={createModal.onClose}>
				<ModalOverlay />
				<ModalContent minW='800px'>
					<ModalHeader>{i18next.t('FILTER_LIST_ADD')}</ModalHeader>
					<ModalBody>
						<FilterEditor addFilter={addFilter} keyOptions={keyOptions} onClose={createModal.onClose} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default FilterList;
