import { Flex, FlexProps, Spinner } from '@chakra-ui/react';

type LoadingProps = FlexProps;

function Loading(props: LoadingProps): JSX.Element {
	return (
		<Flex justifyContent='center' w='100%' {...props}>
			<Spinner size='sm' />
		</Flex>
	);
}

export default Loading;
