import { EspLoader } from '@toit/esptool.js';

import BeaconSerialInterface from './BeaconSerialInterface';
import MicropythonSerialTransformer from './MicropythonSerialTransformer';
import SerialInterface, { DEFAULT_BAUD_RATE } from './SerialInterface';
import { getHost } from '../../utility/host';

const pyReadFile = (filename: string) => `
with open(${JSON.stringify(filename)}, 'r') as file:
	print(file.read())
`;

async function sleep(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

declare type progressCallback = (log: string) => void;

class MicropythonSerialInterface extends SerialInterface {
	private baudRate = DEFAULT_BAUD_RATE;

	async connect(requestPort: boolean = true): Promise<void> {
		this.connecting = true;

		if (requestPort) {
			const beaconSerialInterface = new BeaconSerialInterface(this.expectedBeaconNetworkId);
			await beaconSerialInterface.connect(); // allows to check for beaconId
			this.serialPort = beaconSerialInterface.port;
			await beaconSerialInterface.disconnect(false);
		}

		await super.connect(false);

		const decoder = new TextDecoder('utf-8');
		const enterRawModeSequence = decoder.decode(
			Uint8Array.from([
				0x13,
				0x03,
				0x03, // Interrupt any running program
				0x13,
				0x01, // Enter raw REPL
			]),
		);
		await this.sendCommand(enterRawModeSequence);
	}

	protected async open(): Promise<void> {
		await this.openWithTransformer(new MicropythonSerialTransformer());
	}

	async getFirmwareVersions(): Promise<{ current: string; next: string }> {
		const next = await (await fetch(`${getHost()}/api/v1/firmware/version`)).json();
		const current = await this.sendCommand('from version import FW_VERSION; print(FW_VERSION)');
		if (current.includes('ImportError')) {
			const version = await this.sendCommand(pyReadFile('version'));
			if (version.includes('OSError')) {
				return {
					current: 'unknown',
					next,
				};
			}
			return {
				current: version.split('/')[0],
				next,
			};
		}
		return {
			current,
			next,
		};
	}

	async flashDevice(progressCallback: progressCallback): Promise<void> {
		progressCallback('fetching firmware...');
		const firmware = await this.fetchFirmware();
		progressCallback(' OK\n');

		try {
			progressCallback('connecting to the device bootloader...');
			await this.disconnect(false);
			await this.openPort();

			const espLoader = new EspLoader(this.port, {
				debug: true,
				logger: console,
			});

			await espLoader.connect();

			try {
				await espLoader.loadStub();
				await this.setBaudRate(espLoader, 921600);
				progressCallback(' OK\n');

				progressCallback('erasing device flash...');
				await espLoader.eraseFlash();
				progressCallback(' OK\n');

				progressCallback('writing blinky firmware...\n');
				const progressBarMaxLength = 50;
				progressCallback(`[${' '.repeat(progressBarMaxLength)}] 0%`);
				await espLoader.flashData(firmware, 0x1000, (progress: number, total: number) => {
					const progressBarLength = Math.round((progress / total) * progressBarMaxLength);
					progressCallback(
						`\r[${'='.repeat(progressBarLength)}${' '.repeat(progressBarMaxLength - progressBarLength)}] ${(
							(progress / total) *
							100
						).toFixed(2)}%`,
					);
				});
				progressCallback(`\r[${'='.repeat(progressBarMaxLength)}] 100%\n`);
				await sleep(100);
				progressCallback(
					'firmware written with success, exiting bootloader mode and rebooting in normal mode, please wait...\n',
				);
			} finally {
				await espLoader.disconnect();
				await this.exitBootloaderMode();
			}
		} finally {
			await this.disconnect(false);
		}

		await this.connect(false);

		progressCallback('rebooting...\n');
		await this.reboot();
		await this.disconnect();
		progressCallback('your blinky has been successfully upgraded! you can now leave this page safely');
	}

	private async setBaudRate(espLoader: EspLoader, baudRate: number): Promise<void> {
		await espLoader.setBaudRate(this.baudRate, baudRate);
		this.baudRate = baudRate;
	}

	protected get endOfTransmission(): string {
		return String.fromCharCode(0x04);
	}

	async sendCommand(command: string, retry: number = 0): Promise<string> {
		const result = await super.sendCommand(command, retry);
		if (window.showBeaconLogs) {
			console.info(result);
		}
		return result;
	}

	private async fetchFirmware(): Promise<Uint8Array> {
		const response = await fetch(`${getHost()}/api/v1/firmware`);
		return new Uint8Array(await response.arrayBuffer());
	}

	private async exitBootloaderMode() {
		await this.port.setSignals({
			dataTerminalReady: false,
			requestToSend: true,
		});
		await sleep(100);
		await this.port.setSignals({
			dataTerminalReady: true,
			requestToSend: true,
		});
		await sleep(100);
		await this.port.setSignals({
			dataTerminalReady: false,
			requestToSend: false,
		});
		await sleep(3000); // experimentally, 1 second seems to be enough but let's take some extra time just to be sure
	}

	private async reboot() {
		await this.port.setSignals({
			dataTerminalReady: false,
		});
		await sleep(100);
		await this.port.setSignals({
			dataTerminalReady: true,
		});
	}
}

export default MicropythonSerialInterface;
