import i18next from 'i18next';
import { Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { FiPlus, FiZap } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Empty from '../components/core/Empty';
import Error from '../components/core/Error';
import Loading from '../components/core/Loading';
import PatternList from '../components/pattern/PatternList';
import PatternSpec from '../components/pattern/PatternSpec';
import Prompt from '../components/layout/Prompt';
import api from '../api';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';

function Patterns(): JSX.Element {
	const navigate = useNavigate();
	const { organization } = useSession();
	const creationModal = useDisclosure();
	const { data, loading, error } = useQuery(api.listPatterns, organization.id);

	const createPattern = useCallbackWithLoading(async (name: string) => {
		const pattern = await api.createPattern(organization.id, {
			name,
			raw: new PatternSpec({
				frames: [],
				repeat: 1,
			}),
		});

		navigate(`/patterns/${pattern.id}`);
	});

	if (error) {
		return <Error fullscreen error={error} />;
	}
	if (loading || !data) {
		return <Loading />;
	}

	return (
		<>
			{data.results?.length ? (
				<>
					<Flex justifyContent='space-between' alignItems='flex-end' mb='6'>
						<Heading size='lg'>{i18next.t('PATTERNS_TITLE')}</Heading>
						<Button colorScheme='blue' leftIcon={<FiPlus />} onClick={creationModal.onOpen}>
							{i18next.t('PATTERNS_CREATE')}
						</Button>
					</Flex>

					<PatternList link patterns={data.results!} spacing='4' />
				</>
			) : (
				<Empty
					title={i18next.t('PATTERNS_LIST_EMPTY')}
					action={i18next.t('PATTERNS_CREATE')}
					// @ts-expect-error
					icon={FiZap}
					onCreate={creationModal.onOpen}
				>
					{i18next.t('PATTERNS_CREATE_TIP')}
				</Empty>
			)}

			<Prompt
				title={i18next.t('PATTERNS_CREATE_TITLE')}
				inputLabel={i18next.t('NAME')}
				okButtonText={i18next.t('CREATE')}
				placeholder={i18next.t('PATTERN_RENAME_PLACEHOLDER')}
				loading={createPattern.loading}
				handleSubmit={createPattern}
				disclosure={creationModal}
			/>
		</>
	);
}

export default Patterns;
