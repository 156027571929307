import i18next from 'i18next';
import parseDuration from 'parse-duration';
import axios, { AxiosError } from 'axios';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Code,
	Flex,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { FiActivity, FiAnchor, FiDelete, FiEdit3 } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Error from '../components/core/Error';
import Loading from '../components/core/Loading';
import Reaction from '../components/reaction/Reaction';
import api from '../api';
import useAggregatedQuery from '../hooks/useAggregatedQuery';
import useCallbackWithLoading from '../hooks/useCallbackWithLoading';
import useQuery from '../hooks/useQuery';
import useSession from '../hooks/useSession';
import WebhookForm, { WebhookSpec } from '../components/integrations/webhook/WebhookForm';
import { getIngestURL } from '../utility/host';
import CopiableCode from '../components/core/CopiableCode';

function Webhook(): JSX.Element {
	const toast = useToast();
	const navigate = useNavigate();
	const { organization } = useSession();
	const [deduplicationCountdown, setDeduplicationCountdown] = useState(0);
	const [testingError, setTestingError] = useState<unknown>(null);
	const testingErrorModal = useDisclosure();
	const editionModal = useDisclosure();
	const deleteModal = useDisclosure();
	const deleteModalWeakRef = useRef<HTMLButtonElement>(null);
	const { id } = useParams();
	const {
		queries: [webhook, targets, patterns],
		loading,
		error,
		refetch,
	} = useAggregatedQuery(
		useQuery(api.retrieveWebhook, Number(id), organization.id),
		useQuery(api.listTargets, organization.id),
		useQuery(api.listPatterns, organization.id),
	);

	const test = useCallbackWithLoading(async () => {
		const parts = webhook.data!.defaultReaction.minimumInterval.split(':');
		setDeduplicationCountdown(parseDuration(`${parts[0]}h ${parts[1]}m ${parts[2]}s`)! / 1000);
		// TODO: re-implement me
		// if (reaction.beacons.length + reaction.groups.reduce((total, group) => total + group.beacons.length, 0) < 1) {
		// 	toast({
		// 		title: 'Configuration incomplete',
		// 		description: 'Invoking this webhook will have no effect because it has no linked beacons.',
		// 		status: 'warning',
		// 		duration: 5000,
		// 		isClosable: true,
		// 		position: 'top',
		// 	});
		// 	return;
		// }

		try {
			await axios.get(url);
			toast({
				title: i18next.t('WEBHOOK_TEST_SUCCESS_TITLE'),
				description: i18next.t('WEBHOOK_TEST_SUCCESS_TIP'),
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top',
			});
		} catch (error) {
			setTestingError((error as AxiosError).response);
			testingErrorModal.onOpen();
		}
	});

	useEffect(() => {
		if (deduplicationCountdown > 0) {
			const deduplicationTimerTimeout = setTimeout(() => setDeduplicationCountdown(deduplicationCountdown - 1), 1000);
			return () => clearInterval(deduplicationTimerTimeout);
		}

		return () => {};
	}, [deduplicationCountdown]);

	const editWebhook = useCallbackWithLoading(async ({ name, minimumInterval }: WebhookSpec) => {
		try {
			await api.patchReaction(webhook.data!.defaultReaction.id, organization.id, {
				patternId: webhook.data!.defaultReaction.pattern.id,
				minimumInterval,
			});

			await api.patchWebhook(Number(id), organization.id, {
				name,
			});
		} finally {
			refetch();
			editionModal.onClose();
		}
	});

	const deleteWebhook = useCallbackWithLoading(async () => {
		try {
			await api.destroyWebhook(Number(id), organization.id);
		} finally {
			deleteModal.onClose();
			navigate('/integrations');
		}
	});

	if (loading) {
		return <Loading />;
	}
	if (error) {
		return <Error fullscreen error={error} />;
	}

	const { name, defaultReaction, token } = webhook.data!;
	const url = `${getIngestURL()}/webhook/${token}`;

	return (
		<>
			<Flex justifyContent='space-between' alignItems='flex-end' pb='2'>
				<Flex alignItems='center'>
					<Heading size='lg'>
						<Icon as={FiAnchor} mr='2' />
					</Heading>
					<Heading size='lg' overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis' maxW='40vw'>
						{name}
					</Heading>
				</Flex>
				<div>
					<Tooltip
						hasArrow
						label={
							deduplicationCountdown > 0 &&
							i18next.t('WEBHOOK_TEST_DISABLED_TOOLTIP', { duration: deduplicationCountdown })
						}
					>
						<Button
							leftIcon={<FiActivity />}
							colorScheme='blue'
							variant='outline'
							isLoading={test.loading}
							onClick={test}
							isDisabled={deduplicationCountdown > 0}
						>
							{i18next.t('TEST')} {deduplicationCountdown > 0 && `${deduplicationCountdown}s`}
						</Button>
					</Tooltip>
					<Button leftIcon={<FiEdit3 />} colorScheme='blue' ml='2' onClick={editionModal.onOpen}>
						{i18next.t('EDIT')}
					</Button>
					<Button leftIcon={<FiDelete />} colorScheme='red' ml='2' onClick={deleteModal.onOpen}>
						{i18next.t('DELETE')}
					</Button>
				</div>
			</Flex>

			<Heading size='sm' mt='6' mb='2'>
				{i18next.t('WEBHOOK_INVOCATION_URL')}
			</Heading>
			<CopiableCode bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mb='4' display='flex'>
				{url}
			</CopiableCode>

			<Reaction
				// @ts-expect-error
				reaction={defaultReaction}
				targets={targets.data!.results!}
				patterns={patterns.data!.results!}
				refetch={refetch}
			/>

			<Accordion allowMultiple defaultIndex={[]} mt='8'>
				<AccordionItem>
					<AccordionButton>
						<AccordionIcon />
						(Optional) Customize pattern using request body
					</AccordionButton>
					<AccordionPanel>
						<Heading size='xs' mb='2'>
							Override with an existing pattern
						</Heading>
						<Text color='gray' mt='0' pt='0' mb='2'>
							ID can be found in URL when browsing patterns.
						</Text>
						<Code bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mb='4'>
							<pre>{patternOverrideByIdExample}</pre>
						</Code>
						<Heading size='xs' mb='2'>
							Override with a dynamic pattern
						</Heading>
						<Code bg='whiteAlpha.200' w='100%' p='4' borderRadius='xl' mb='4'>
							<pre>{patternOverrideDynamicExample}</pre>
						</Code>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<Modal isOpen={editionModal.isOpen} onClose={editionModal.onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{i18next.t('WEBHOOK_EDIT')}</ModalHeader>
					<ModalCloseButton />
					<Formik
						onSubmit={editWebhook}
						initialValues={{
							name,
							minimumInterval: defaultReaction.minimumInterval,
						}}
					>
						<Form>
							<ModalBody>
								<WebhookForm />
							</ModalBody>
							<ModalFooter>
								<Button variant='ghost' mr={3} onClick={editionModal.onClose}>
									{i18next.t('CANCEL')}
								</Button>
								<Button colorScheme='blue' type='submit' isLoading={editWebhook.loading}>
									{i18next.t('UPDATE')}
								</Button>
							</ModalFooter>
						</Form>
					</Formik>
				</ModalContent>
			</Modal>

			<Modal isOpen={testingErrorModal.isOpen} onClose={testingErrorModal.onClose}>
				<ModalOverlay />
				<ModalContent minWidth='80vh'>
					<ModalHeader>{i18next.t('WEBHOOK_FAILED_TITLE')}</ModalHeader>
					<ModalBody>
						<Error error={testingError!} />
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='blue' onClick={testingErrorModal.onClose}>
							{i18next.t('CLOSE')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={deleteModal.isOpen} leastDestructiveRef={deleteModalWeakRef} onClose={deleteModal.onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{i18next.t('WEBHOOK_DELETE')}
						</AlertDialogHeader>

						<AlertDialogBody>{i18next.t('WEBHOOK_DELETE_TIP')}</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={deleteModalWeakRef} onClick={deleteModal.onClose}>
								{i18next.t('CANCEL')}
							</Button>
							<Button colorScheme='red' ml={3} onClick={deleteWebhook} isLoading={deleteWebhook.loading}>
								{i18next.t('DELETE')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

const patternOverrideByIdExample = JSON.stringify(
	{
		pattern_id: 42,
	},
	null,
	2,
);

const patternOverrideDynamicExample = `{
  "pattern": {
    "repeat": 10,
    "frames": [
      {
        "duration": 100,
        "colors": [
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255}
        ]
      },
      {
        "duration": 100,
        "colors": [
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0},
          {"r": 0, "g": 0, "b": 255},
          {"r": 255, "g": 0, "b": 0}
        ]
      }
    ]
  }
}`;

export default Webhook;
